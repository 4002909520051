import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import { NotificationManager } from './notificationManager'
import { ToastOptions } from 'react-toastify'

export type NotificationsContextType = {
  createNotification: (
    message: string,
    options?: ToastOptions,
  ) => Promise<number | string> | null
}

export const NotificationsContext = createContext<NotificationsContextType>({
  createNotification: () => null,
})

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isNotificationActive, setIsNotificationActive] = useState(false)

  const createNotification = async (
    message: string,
    options?: ToastOptions,
  ) => {
    const reactToastify = await import('react-toastify')
    const injectStyle = await import('react-toastify/dist/inject-style')
    injectStyle.injectStyle()
    setIsNotificationActive(true)

    return reactToastify.toast(message, options)
  }

  return (
    <NotificationsContext.Provider
      value={{
        createNotification,
      }}
    >
      <NotificationManager isActive={isNotificationActive} />
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = (): NotificationsContextType => {
  return useContext(NotificationsContext)
}
