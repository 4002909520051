/**
 *  If You update SHOPIFY_STOREFRONT_API_VERSION please ensure that You update that value in the following file:
 *  @sans-souci/shopify-sdk/.graphqlconfig
 *  Then remember to run .graphqlconfig for updating schema.gql
 */

export const SHOPIFY_STOREFRONT_API_VERSION = '2022-10'

export const shopifyConfig = {
  accessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: process.env.SHOPIFY_DOMAIN,
  apiVersion: SHOPIFY_STOREFRONT_API_VERSION,
}
