import React, { ComponentPropsWithRef } from 'react'
import { styled } from '@sans-souci/styles'
import { ModalOverlayStyle } from './Modal'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'
import { AnimationStatus } from '../useAnimation'

const Backdrop = styled('div', {
  opacity: 0,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  variants: {
    overlayVariant: {
      transparent: {},
      blur: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(32px)',
      },
      light: {},
      dark: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    status: {
      entering: {
        opacity: 1,
        transition: 'all $$enteringTime',
      },
      entered: {
        opacity: 1,
      },
      exiting: {
        opacity: 0,
        transition: 'all $$exitingTime',
      },
      exited: {
        opacity: 0,
      },
    },
  },
})

const OverlayStyled = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gridGap: '$24',
  overflow: 'auto',
  zIndex: 8888,
  variants: {
    hasPadding: {
      true: {
        padding: '$8',
      },
    },
    status: {
      entering: {},
      entered: {},
      exiting: {},
      exited: {},
    },
  },
})

const ButtonWrapper = styled('div', {
  margin: '0 auto',
})

export type OverlayProps = ComponentPropsWithRef<'div'> & {
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  hasPadding?: boolean
  enteringTime: number
  exitingTime: number
  status: AnimationStatus
}

export const ModalOverlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      overlayCloseButton,
      overlayCloseButtonLabel,
      enteringTime,
      exitingTime,
      children,
      hasPadding,
      overlayVariant,
      status,
      ...restProps
    },
    ref,
  ) => {
    const { t } = useTranslation('modal')

    return (
      <OverlayStyled
        {...restProps}
        style={{}}
        ref={ref}
        status={status}
        hasPadding={hasPadding}
        css={{
          $$enteringTime: `${enteringTime}ms`,
          $$exitingTime: `${exitingTime}ms`,
        }}
      >
        <Backdrop overlayVariant={overlayVariant} status={status} />
        {children}
        {overlayCloseButton && (
          <ButtonWrapper>
            <Button>{overlayCloseButtonLabel ?? t('close')}</Button>
          </ButtonWrapper>
        )}
      </OverlayStyled>
    )
  },
)
ModalOverlay.displayName = 'Overlay'
