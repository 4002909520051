import { FC } from 'react'

export const SmallArrowRight: FC<{ className?: 'string' }> = ({
  className,
}) => (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64644L15.1716 0.464465C14.9763 0.269203 14.6597 0.269203 14.4645 0.464465C14.2692 0.659727 14.2692 0.976309 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53553C14.6597 7.73079 14.9763 7.73079 15.1716 7.53553L18.3536 4.35355ZM4.61398e-08 4.5L18 4.5L18 3.5L-4.61398e-08 3.5L4.61398e-08 4.5Z"
      fill="#1A1919"
    />
  </svg>
)
