import React, { FC } from 'react'

type Props = {
  className?: string
}

export const Close: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="54"
      height="13"
      viewBox="0 0 54 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7208 6.97532L53.2208 12.9753L53.4417 12L28.9851 6.46266L53.2208 0.975315L53 1.95588e-06L26.7208 5.95L0.441651 0L0.220825 0.975315L24.4566 6.46266L0 12L0.220825 12.9753L26.7208 6.97532Z"
        fill="currentColor"
      />
    </svg>
  )
}
