import { styled } from '@sans-souci/styles'
import { FocusEvent, forwardRef, useEffect, useState } from 'react'
import { TextFieldPropsType } from './TextFieldPropsType'

const FieldContainer = styled('div', {
  width: '100%',
  color: 'currentColor',
  display: 'grid',
  gap: '$2',
})

const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  $projectFont: 'mono01',
})

const Input = styled('input', {
  appearance: 'none',
  $projectFont: 'mono01',
  fontSize: '16px',
  textTransform: 'none',
  height: '$buttonHeightL',
  padding: '$0 $0 0 $4',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
  borderRadius: 0,
  color: 'currentColor',
  '&::placeholder': {
    color: 'currentColor',
  },
  '&:focus-visible': {},
  variants: {
    theme: {
      dark: {
        borderColor: '$white_05',
      },
      light: {},
    },
    isInvalid: {
      true: {
        borderColor: '$red500',
        '&:focus-visible': {
          backgroundColor: 'transparent',
        },
      },
    },
    multiline: {
      true: {
        paddingTop: '$6',
        resize: 'none',
        borderBottom: '1px solid $white_05',
        '&:focus-visible': {
          outline: 'none',
        },
      },
    },
    isActive: {
      true: {
        '&::placeholder': {
          opacity: 1,
          transition: 'opacity 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 10ms',
        },
      },
      false: {
        '&::placeholder': {
          opacity: 0,
          transition: 'none',
        },
      },
    },
  },
})

const Label = styled('label', {
  zIndex: 2,
  margin: 0,
  border: 0,
  left: '$4',
  transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    isActive: {
      true: {
        transform: 'translate(0px, -8px) scale(0.80)',
      },
      false: {},
    },
  },
})

const HelpText = styled('div', {
  $projectFont: 'body02',
  display: 'grid',
  padding: '0 $4',
  textTransform: 'unset',
  marginLeft: 'auto',

  marginTop: '-$12',
  zIndex: '1',
  borderRadius: '$r1',
  variants: {
    theme: {
      dark: {
        color: '$red300',
        background: '$red900',
      },
      light: {
        color: '$red500',
        background: '$red100',
      },
    },
  },
})

export const TextField = forwardRef<HTMLInputElement, TextFieldPropsType>(
  (props, ref) => {
    const {
      autoComplete,
      disabled,
      id,
      children,
      invalid,
      helpText,
      label,
      readOnly,
      onBlur,
      onFocus,
      value,
      type = 'text',
      theme = 'light',
      style,
      className,
      placeholder,
      multiline,
      ...rest
    } = props

    const isValueSet = (value?: string): boolean =>
      typeof value === 'string' && value.length > 0

    const helpId = helpText ? `${id}__hint` : undefined

    const [isActive, setIsActive] = useState<boolean>(() => isValueSet(value))

    const handleOnBlur = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setIsActive(isValueSet(event.target.value))
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }
    const handleOnFocus = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setIsActive(true)
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }

    useEffect(() => {
      if (isValueSet(value)) {
        setIsActive(true)
      }
    }, [value])

    const htmlTag = multiline ? 'textarea' : 'input'

    return (
      <FieldContainer className={className}>
        <InputContainer>
          {label && (
            <Label htmlFor={id} isActive={isActive}>
              {label}
            </Label>
          )}

          <Input
            {...rest}
            aria-describedby={helpId}
            aria-errormessage={invalid && helpId ? helpId : undefined}
            aria-invalid={invalid}
            disabled={disabled}
            id={id}
            readOnly={readOnly}
            ref={ref}
            type={type}
            autoComplete={autoComplete}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            isActive={isActive}
            isInvalid={invalid}
            value={value}
            placeholder={placeholder}
            multiline={multiline}
            theme={theme}
            as={htmlTag}
          />
        </InputContainer>
        {helpText && (
          <HelpText id={helpId} theme={theme}>
            {helpText}
          </HelpText>
        )}
      </FieldContainer>
    )
  },
)
