import { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { styled } from '@sans-souci/styles'
import { Button } from '@sans-souci/components'

const TitleContainer = styled('div', {
  variants: {
    hideOnMobile: {
      true: {
        display: 'none',
        '@lg': {
          display: 'block',
        },
      },
    },
  },
})

const Title = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'left',
  gridAutoFlow: 'column',
  position: 'relative',
  $projectFont: 'dev',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  color: '$gray300',
  transition: '200ms',
  overflow: 'hidden',
  variants: {
    isActive: {
      true: {
        color: '$black',
      },
    },
  },
})

interface PanelItemProps {
  title?: string
  slider: HTMLDivElement | null
  onClick: () => void
  isActive?: boolean
  hideOnMobile?: boolean
}

export const MenuItem: FC<PanelItemProps> = ({
  title,
  slider,
  isActive = false,
  onClick,
  hideOnMobile = false,
}) => {
  const [ref] = useInView({
    root: slider,
    threshold: 1,
  })

  return (
    <TitleContainer hideOnMobile={hideOnMobile} ref={ref} onClick={onClick}>
      <Title appearance={'ButtonText'} isActive={isActive}>
        {title}
      </Title>
    </TitleContainer>
  )
}
