import { styled } from '@sans-souci/styles'
import { FC, PropsWithChildren } from 'react'

const Root = styled('div', {
  display: 'flex',
  gap: '$16',
})

export const ActionItemsContainer: FC<
  PropsWithChildren<{
    className?: string
  }>
> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>
}
