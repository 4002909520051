import { AccountCardWrapper } from './AccountCardWrapper'
import { AddressItem } from './AddressItem'
import { Button } from '@sans-souci/components'
import { FC, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { EditAddress } from '../views/EditAddress'
import { useTranslation } from 'react-i18next'
import { createCustomerClient } from '../../../customerService'
import { useCustomerContext } from '../../../customerContext'
import { useRouter } from 'next/router'
import { ShopifyMailingAddressFieldsFragment } from '@sans-souci/shopify-sdk'

const CardActions = styled('div', {
  display: 'flex',
  gap: '$12',
})

export const AddressItemCard: FC<{
  node: ShopifyMailingAddressFieldsFragment
  defaultAddressId: string
  isDeletingPossible: boolean
}> = ({ node, defaultAddressId, isDeletingPossible }) => {
  const { t } = useTranslation('account')
  const { id } = node
  const isDefault = id === defaultAddressId
  const heading = isDefault
    ? t('accountPage.addressListView.defaultAddress')
    : undefined
  const [isEditing, setIsEditing] = useState(false)

  const { accessToken, updateCustomer } = useCustomerContext()
  const { locale } = useRouter()
  const customerClient = createCustomerClient({ locale })
  const customerAccessToken = accessToken || ''
  const [isGettingDeleted, setIsGettingDeleted] = useState(false)

  const handleAddressDelete = async (id: string) => {
    if (!id) return
    if (window.confirm(t('accountPage.addressListView.deleteButtonConfirm'))) {
      setIsGettingDeleted(true)
      await customerClient.deleteAddress({ customerAccessToken, id })
      await updateCustomer()
    }
  }

  return isEditing ? (
    <EditAddress
      title={heading}
      setIsEditVisible={setIsEditing}
      address={node}
      isDefault={isDefault}
    />
  ) : (
    <AccountCardWrapper disabled={isGettingDeleted} title={heading}>
      <AddressItem address={node} title={heading} />
      <CardActions>
        <Button
          appearance={'ButtonPrimaryGrey'}
          onClick={() => setIsEditing(true)}
          disabled={isGettingDeleted}
        >
          {t('accountPage.addressListView.editButtonLabel')}
        </Button>
        {isDeletingPossible && (
          <Button
            appearance={'ButtonOutline'}
            onClick={() => handleAddressDelete(id)}
            disabled={isGettingDeleted}
          >
            {t('accountPage.addressListView.deleteButtonLabel')}
          </Button>
        )}
      </CardActions>
    </AccountCardWrapper>
  )
}
