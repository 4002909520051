import { styled } from '@sans-souci/styles'
import { Button, Loader } from '@sans-souci/components'
import { useTranslation } from 'react-i18next'
import { useCheckout } from '../useCheckout'
import { useCartContext } from '../useCart'

const Root = styled('div', {
  backgroundColor: '$beige',
  display: 'grid',
})

const StyledButton = styled(Button, {
  color: 'black',
  position: 'relative',
})

const LoaderWrap = styled('div', {
  position: 'absolute',
  display: 'grid',
  justifyContent: 'center',
  left: 0,
  right: 0,
})

const Label = styled('span', {
  variants: {
    isHidden: {
      true: {
        visibility: 'hidden',
      },
    },
  },
})

export const CheckoutButton = () => {
  const { isCheckoutLoading, handleCheckout } = useCheckout()
  const { processing } = useCartContext()
  const { t } = useTranslation('cart')

  return (
    <Root>
      <StyledButton
        appearance={'ButtonPrimaryGrey'}
        onClick={handleCheckout}
        disabled={isCheckoutLoading}
      >
        {(isCheckoutLoading || processing) && (
          <LoaderWrap>
            <Loader size="small" />
          </LoaderWrap>
        )}
        <Label isHidden={isCheckoutLoading || processing}>
          {t('checkoutButtonLabel')}
        </Label>
      </StyledButton>
    </Root>
  )
}
