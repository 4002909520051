import { FC, PropsWithChildren } from 'react'
import { styled } from '@sans-souci/styles'
import { Link } from '../Link/Link'
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import { LinkField, PortableTextBlocks } from '@sans-souci/models'

type MarkComponent<T extends TypedObject = any> = (
  props: PropsWithChildren<PortableTextMarkComponentProps<T>>,
) => JSX.Element

export type PortableTextProps = {
  value?: PortableTextBlocks
  additionalComponents?: PortableTextComponents
  withStyles?: boolean
}

const linkMark: MarkComponent<LinkField & { _type: string }> = ({
  value,
  children,
}) => {
  return <Link {...value?.payload}>{children}</Link>
}

const PortableTextWrap = styled('div', {
  variants: {
    withStyles: {
      true: {
        $projectFont: 'body01',

        'p + p': {
          marginTop: '1.2em',
        },
        h1: {
          $projectFont: 'heading02',
          '+ *': {
            marginTop: '1.2em',
          },
        },
        h2: {
          $projectFont: 'heading02',
          '+ *': {
            marginTop: '1.2em',
          },
        },
        h3: {
          $projectFont: 'heading03',
          '+ *': {
            marginTop: '1.2em',
          },
        },
        h4: {
          $projectFont: 'heading03',
          '+ *': {
            marginTop: '1.2em',
          },
        },
        h5: {
          $projectFont: 'heading03',
          '+ *': {
            marginTop: '1.2em',
          },
        },
        h6: {
          $projectFont: 'heading03',
          '+ *': {
            marginTop: '1.2em',
          },
        },
        strong: {
          fontWeight: 700,
        },
        em: {
          fontStyle: 'italic',
        },
        blockquote: {
          fontStyle: 'italic',
          fontWeight: 700,
        },
        'ol, ul': {
          paddingLeft: '$24',

          li: {
            mt: '$3',
          },
        },
        'ol ol li': {
          listStyleType: 'lower-latin',
        },
        'ol ol ol li': {
          listStyleType: 'lower-roman',
        },
        'ol ol ol ol li': {
          listStyleType: 'roman',
        },
      },
    },
  },
})

export const PortableText: FC<PortableTextProps> = ({
  value,
  additionalComponents,
  withStyles = true,
}) => {
  const components: PortableTextComponents = {
    ...additionalComponents,
    marks: {
      link: linkMark,
      ...(additionalComponents?.marks ? additionalComponents.marks : {}),
    },
  }
  return value ? (
    <PortableTextWrap withStyles={withStyles}>
      <SanityPortableText value={value} components={components} />
    </PortableTextWrap>
  ) : null
}
