import { styled } from '@sans-souci/styles'
import { FC, PropsWithChildren } from 'react'

const Wrap = styled('div', {
  variants: {
    type: {
      maxWidthS: {
        marginLeft: '$containerMarginMobileS',
        marginRight: '$containerMarginMobileS',
        '@md': {
          marginLeft: '$containerMarginDesktopS',
          marginRight: '$containerMarginDesktopS',
        },
      },
      maxWidthL: {
        marginLeft: '$containerMarginMobileM',
        marginRight: '$containerMarginMobileM',
        '@md': {
          marginLeft: '$containerMarginDesktopM',
          marginRight: '$containerMarginDesktopM',
        },
      },
      fullWidth: {},
      sideMarginsS: {
        marginLeft: '$containerMarginMobileS',
        marginRight: '$containerMarginMobileS',
        '@md': {
          marginLeft: '$containerMarginDesktopS',
          marginRight: '$containerMarginDesktopS',
        },
      },
      sideMarginsM: {
        marginLeft: '$containerMarginMobileM',
        marginRight: '$containerMarginMobileM',
        '@md': {
          marginLeft: '$containerMarginDesktopM',
          marginRight: '$containerMarginDesktopM',
        },
      },
    },
  },
})

const SecondWrap = styled('div', {
  variants: {
    type: {
      maxWidthS: {
        maxWidth: '$maxWidthS',
        margin: '0 auto',
      },
      maxWidthL: {
        maxWidth: '$maxWidthL',
        margin: '0 auto',
      },
      fullWidth: {},
      sideMarginsS: {},
      sideMarginsM: {},
    },
  },
})

export type ContainerType =
  | 'maxWidthS'
  | 'maxWidthL'
  | 'fullWidth'
  | 'sideMarginsM'
  | 'sideMarginsS'

export const Container: FC<
  PropsWithChildren<{
    as?: keyof HTMLElementTagNameMap
    type?: ContainerType
  }>
> = ({ children, as = 'div', type = 'sideMarginsM' }) => {
  return (
    <Wrap as={as} type={type}>
      <SecondWrap type={type}>{children}</SecondWrap>
    </Wrap>
  )
}
