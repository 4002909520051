import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { autoTextSize } from 'auto-text-size'
import { LinkField, MediaFieldType } from '@sans-souci/models'
import { Media } from './Media/Media'
import { Link } from './Link/Link'

const Root = styled('div', {
  display: 'grid',
  height: '100vh',
  position: 'relative',
  background: '$beige',
  variants: {
    transitionState: {
      entering: {
        opacity: 1,
        zIndex: 1,
        transition: 'opacity 500ms',
      },
      entered: {
        zIndex: 1,
      },
      exited: {
        opacity: 0,
      },
      exiting: {
        opacity: 0,
        transition: 'opacity 0ms 500ms',
      },
    },
  },
})

const MediaContainer = styled('div', {
  position: 'relative',
  '&:after': {
    position: 'absolute',
    content: '',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.2)',
    pointerEvents: 'none',
  },
  variants: {
    mode: {
      fullBleed: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      default: {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        maxHeight: '80%',
        maxWidth: '$maxWidthS',
        placeSelf: 'center',
        $lin: {
          paddingLeft: 'S',
          paddingRight: 'S',
        },
      },
    },
  },
})

const TitleWrap = styled('div', {
  position: 'absolute',
  bottom: '100%',
  width: '100%',
  display: 'grid',
  justifyContent: 'center',
  paddingLeft: '$containerMarginMobile',
  paddingRight: '$containerMarginMobile',
  paddingBottom: '$40',
  $projectFont: 'heading01',
  margin: '0 auto',
  textAlign: 'center',
  h1: {
    // placeSelf: 'center',
    // textAlign: 'center',
    // lineHeight: 1,
    // marginBottom: '-0.22em',
    // paddingTop: '0.07em',
    // paddingBottom: '0.04em',
  },
  variants: {
    ready: {
      true: {
        opacity: 1,
        transition: 'opacity 1000ms 250ms',
        h1: {
          transition: 'transform 2000ms 0ms cubic-bezier(.2,.4,.2,1)',
          transform: 'none',
        },
      },
      false: {
        opacity: 0,
        transition: 'opacity 0ms 1000ms',
        h1: {
          transition: 'transform 0 1000ms',
          transform: 'translateY(8px)',
        },
      },
    },
  },
})

const ContentWrap = styled('div', {
  position: 'absolute',
  display: 'grid',
  placeSelf: 'end center',
  justifyItems: 'center',
  width: '100%',
  marginBottom: '$120',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
  '@lg': {
    marginBottom: '$64',
  },
  transition: 'opacity 500ms 16ms',
  variants: {
    loaded: {
      true: {},
      false: { opacity: 0 },
    },
  },
})

const Description = styled('p', {
  $projectFont: 'body01',
  maxWidth: '$maxWidthL',
  margin: '0 auto',
  marginBottom: '$32',
  textAlign: 'center',
})

type Props = {
  media?: MediaFieldType
  title?: string
  description?: string
  mode?: 'default' | 'fullBleed'
  cta?: LinkField
  priority?: boolean
  visible: boolean
}
export const Cover: FC<Props> = ({
  media,
  title,
  description,
  mode = 'default',
  cta,
  priority = false,
  visible,
}) => {
  const titleWrapRef = useRef<HTMLDivElement>(null)
  const timer = useRef<number | null>(null)
  const [loaded, setLoaded] = useState(false)
  const [transitionState, setTransitionState] = useState<
    'entered' | 'entering' | 'exited' | 'exiting'
  >(visible ? 'entered' : 'exited')

  useEffect(() => {
    if (!titleWrapRef?.current) return
    const updateTextSize = autoTextSize({
      innerEl: titleWrapRef.current.getElementsByTagName('h1')[0],
      maxFontSizePx: 100,
      minFontSizePx: 36,
      mode: 'multiline',
      fontSizePrecisionPx: 1,
      containerEl: titleWrapRef.current,
    })
    updateTextSize()
    setLoaded(true)
    window.addEventListener('resize', updateTextSize)
    return () => {
      updateTextSize.disconnect()
      window.removeEventListener('resize', updateTextSize)
    }
  }, [])

  useEffect(() => {
    if (transitionState === 'exiting' || transitionState === 'entering') {
      timer.current && window.clearTimeout(timer.current)
    }
    if (visible) {
      setTransitionState('entering')
      timer.current = window.setTimeout(() => {
        setTransitionState('entered')
      }, 500)
    } else {
      setTransitionState('exiting')
      timer.current = window.setTimeout(() => {
        setTransitionState('exited')
      }, 500)
    }
  }, [visible])

  return (
    <Root transitionState={transitionState}>
      {media && (
        <MediaContainer mode={mode}>
          <Media
            {...media}
            priority={priority}
            layout={'fill'}
            sizes={mode === 'fullBleed' ? '100vw' : '60vw'}
            objectFit={mode === 'default' ? 'contain' : 'cover'}
          />
        </MediaContainer>
      )}
      <ContentWrap loaded={loaded}>
        {title && (
          <TitleWrap
            ref={titleWrapRef}
            // ready={
            //   transitionState === 'entering' ||
            //   transitionState === 'entered' ||
            //   transitionState === 'exiting'
            // }
          >
            <h1>{title}</h1>
          </TitleWrap>
        )}
        {description && <Description>{description}</Description>}
        {cta && cta.title && (
          <Link {...cta.payload} appearance={'ButtonPrimaryWhite'}>
            {cta.title}
          </Link>
        )}
      </ContentWrap>
    </Root>
  )
}
