import { AppProps } from 'next/app'
import Head from 'next/head'
import { I18nextProvider } from 'react-i18next'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { FC, PropsWithChildren, useEffect } from 'react'

import getI18n from '../translations/i18n'
import { PageBuilderSection, PageResponse } from '@sans-souci/models'
import { Modal, seoImageLoader } from '@sans-souci/components'
import { CartProvider, MiniCart, useCartContext } from '@sans-souci/cart'
import Script from 'next/script'
import { Footer } from '@sans-souci/footer'
import { CustomerProvider } from '@sans-souci/account'
import { NotificationsProvider } from '@sans-souci/notifications'
import { Header } from '@sans-souci/header'
import localFont from '@next/font/local'
import { setAppElement } from 'react-modal'
import { useMediaQuery } from '@sans-souci/styles'

const fontMono = localFont({
  src: '../fonts/AdapterMonoPEVFWeb-All.woff2',
  variable: '--fonts-mono',
})
const fontSans = localFont({
  src: '../fonts/AdapterPEVFWeb-All.woff2',
  variable: '--fonts-sans',
})
const Fonts = () => (
  <style jsx global>
    {`
      :root {
        --fonts-mono: ${fontMono.style.fontFamily};
        --fonts-sans: ${fontSans.style.fontFamily};
      }
    `}
  </style>
)

type ProjectAppProps = AppProps<NonNullable<PageResponse<PageBuilderSection[]>>>

const Layout: FC<PropsWithChildren<PageResponse<PageBuilderSection[]>>> = ({
  siteConfiguration,
  headerColor,
  background,
  children,
  breadcrumbs,
}) => {
  const matchesMd = useMediaQuery('md')
  const { closeMiniCart, miniCartState } = useCartContext()

  return (
    <>
      <Fonts />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.sanity.io/"
          rel="preconnect"
          crossOrigin="anonymous"
        />
        <link
          href="https://image.mux.com"
          rel="preconnect"
          crossOrigin="anonymous"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#E3DED8" />
        <meta name="theme-color" content="#E3DED8" />
      </Head>
      {siteConfiguration?.seo &&
        (() => {
          const { seo } = siteConfiguration
          const seoImage = seoImageLoader(seo.ogImage, seo.ogAltImage)

          return (
            <DefaultSeo
              titleTemplate={seo.titleTemplate}
              title={seo.metaTitle}
              description={seo.metaDescription}
              openGraph={{
                title: seo.ogTitle,
                description: seo.ogDescription,
                images: [...(seoImage ? [seoImage] : [])],
              }}
              twitter={{
                cardType: 'summary_large_image',
              }}
            />
          )
        })()}
      <Header
        header={siteConfiguration?.header}
        color={headerColor}
        breadcrumbs={breadcrumbs}
      />
      {children}

      <Footer
        footer={siteConfiguration?.footer}
        darkTheme={background === 'dark'}
        newsletter={siteConfiguration?.newsletter}
      />
      {/*Global Cart Modal*/}
      <Modal
        preventScroll={true}
        isOpen={miniCartState.isOpen}
        // isOpen={true}
        onRequestClose={closeMiniCart}
        size={matchesMd ? 'intrinsic' : 'stretch-x'}
        position={matchesMd ? 'right' : 'center'}
        transition={'slide-from-right'}
      >
        <MiniCart />
      </Modal>
    </>
  )
}

const MyApp = ({ Component, pageProps }: ProjectAppProps) => {
  const { locale } = useRouter()

  useEffect(() => {
    setAppElement('#modalContainer')
  }, [])

  if (!locale) throw new Error('Locale has not been set')

  return (
    <I18nextProvider i18n={getI18n(locale)}>
      <NotificationsProvider>
        <CustomerProvider>
          <CartProvider>
            <Layout {...pageProps}>
              <Component {...pageProps} />
            </Layout>
            <div id={'modalContainer'} />
          </CartProvider>
          <Script
            strategy="afterInteractive"
            id="gtm"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer',"${process.env.NEXT_PUBLIC_GTM_ID}");
                `,
            }}
          />
        </CustomerProvider>
      </NotificationsProvider>
    </I18nextProvider>
  )
}
export default MyApp
