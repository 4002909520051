import { FC } from 'react'
import { ImageLoader } from 'next/image'

import { shopifyImageLoader } from '../helpers/shopifyImageLoader'

import { MediaProps } from '../models/MediaProps'
import { getMediaSizeByRatio } from '../helpers/getMediaSizeByRatio'
import { Image } from './Image'
import { MediaRatiosTypeExtended, ratios } from '../ratios'
import { ShopifyResolvedImage } from '@sans-souci/shopify-sdk'
import { errorDebugMessage } from '@sans-souci/utils'

type ShopifyImageProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: ShopifyResolvedImage
}

/**
 * Since the crop will fail if requested dimensions exceede original image dimensions,
 * we try to find the biggest
 */
const getDesiredWidthAndHeight = (
  width: number,
  originalWidth: number,
  originalHeight: number,
  ratioType?: MediaRatiosTypeExtended,
): [number, number | undefined] => {
  const desiredWidth = width > originalWidth ? originalWidth : width

  const [, desiredHeight] = getMediaSizeByRatio({
    width: desiredWidth,
    ratioType,
  })

  // if desired height exceedes original height, we try to use
  if (desiredHeight && desiredHeight > originalHeight && ratioType) {
    if (typeof ratioType !== 'string') {
      throw new Error(
        'Responsive hardcrop ratio is not supported by Shopify images',
      )
    }
    const hardCropValue = ratios[ratioType]
    const maxWidth = Math.floor(originalHeight / hardCropValue)

    const [, maxHeight] = getMediaSizeByRatio({
      width: maxWidth,
      ratioType,
      height: originalHeight,
    })

    return [maxWidth, maxHeight]
  }

  return [desiredWidth, desiredHeight]
}

export const ShopifyImage: FC<ShopifyImageProps> = ({
  ratio: ratioType,
  objectFit,
  priority,
  sizes,
  layout,
  mediaPayload,
  className,
}) => {
  const { image } = mediaPayload

  const {
    src,
    width: originalWidth,
    height: originalHeight,
    alt: shopifyAlt,
  } = image

  if (!(originalWidth && originalHeight)) {
    errorDebugMessage('Shopify image has no width or height')
    return null
  }

  const loader: ImageLoader = ({ width: screenWidth, src }) => {
    const [width, height] = getDesiredWidthAndHeight(
      screenWidth,
      originalWidth,
      originalHeight,
      ratioType,
    )

    return shopifyImageLoader({
      width,
      height,
      src,
    })
  }

  const [, height] = getMediaSizeByRatio({
    width: originalWidth,
    ratioType,
    height: originalHeight,
  })

  return (
    <div className={className}>
      <Image
        alt={shopifyAlt ?? ''}
        objectFit={objectFit}
        priority={priority}
        sizes={sizes}
        layout={layout}
        src={src}
        width={originalWidth}
        height={height}
        loader={loader}
      />
    </div>
  )
}
