import React, { FC } from 'react'

type Props = {
  className?: string
}

export const ArrowDown: FC<Props> = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 6.29285L6 6.99995L6.70711 6.29285L12 0.999954L11.2929 0.292847L6 5.58574L0.707107 0.292847L0 0.999954L5.29289 6.29285Z"
        fill="currentColor"
      />
    </svg>
  )
}
