import { useCartContext } from '../useCart'
import { styled } from '@sans-souci/styles'
import { FC } from 'react'
import { Button, ProductVariantPrice } from '@sans-souci/components'
import { GTMAddToCart } from '@sans-souci/services/gtm-service'
import { ShopifyResolvedVariantBasic } from '@sans-souci/shopify-sdk'

type AddToCartButtonProps = {
  variant?: ShopifyResolvedVariantBasic
}

const StyledButton = styled(Button, {
  marginTop: '$16',
})

export const AddToCartButton: FC<AddToCartButtonProps> = ({ variant }) => {
  const { addCartLines } = useCartContext()
  const { openMiniCart } = useCartContext()

  const handleAddToCartClick = async () => {
    if (variant) {
      const addedLines = await addCartLines([
        {
          merchandiseId: variant.id,
          quantity: 1,
        },
      ])?.then((res) => {
        return res
      })
      openMiniCart()

      if (addedLines) {
        GTMAddToCart(addedLines[0], 1)
      }
    }
  }

  return (
    <StyledButton
      appearance={'ButtonPrimaryWhite'}
      onClick={handleAddToCartClick}
      disabled={!variant}
    >
      <span>
        Add to cart
        {variant && (
          <>
            {' '}
            - <ProductVariantPrice price={variant.priceV2} />
          </>
        )}
      </span>
    </StyledButton>
  )
}
