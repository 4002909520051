import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'local-react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'

import {
  validateRequired,
  useFormSubmitState,
  validateEmail,
  FormTextField,
  SubmitButton,
  FormResponseMessage,
  ActionItemsContainer,
} from '@sans-souci/forms'

import { createCustomerClient } from '../customerService'
import { useCustomerContext } from '../customerContext'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { Link } from '@sans-souci/components'
import { ROUTE_ACCOUNT_FORGOT_PASSWORD } from '@sans-souci/configs'
import { LoginRegisterFormWrapper } from '../components/LoginRegisterFormWrapper'
import { Form } from '../components/Form'
import { ShopifyCustomerUserErrorsFieldsFragment } from '@sans-souci/shopify-sdk'
import { styled } from '@sans-souci/styles'

interface RegisterFormProps {
  className?: string
  onSubmitSuccess?: () => void
  onForgotPasswordClick?: () => void
}

type FormValues = {
  email: string
  password: string
}

const defaultValues: FormValues = {
  email: '',
  password: '',
}

const Caption = styled('p', {
  $projectFont: 'body02',
  color: '$grey',
  marginBottom: '$16',
})

export const LoginForm: FC<RegisterFormProps> = ({
  className,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })
  const [isLoadingVisible, setIsLoadingVisible] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })
  const { setToken } = useCustomerContext()

  const { invalid, setSuccess, setError, setFieldError, submitMessage } =
    useFormSubmitState<FormValues>({
      form,
      defaultValues,
      keepValuesOnSuccess: true,
    })

  useEffect(() => {
    if (!errors.password && !errors.email && isSubmitting) {
      setIsLoadingVisible(true)
    } else if (invalid) {
      setIsLoadingVisible(false)
    }
  }, [isSubmitting, invalid, errors])

  const handleResponseErrors = (
    errors: ShopifyCustomerUserErrorsFieldsFragment[],
  ) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const response = await customerClient.createToken(data)
      const errors = response?.customerAccessTokenCreate?.customerUserErrors
      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      const token =
        response?.customerAccessTokenCreate?.customerAccessToken?.accessToken

      if (!token) {
        return setError()
      }

      setToken(token)
      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <LoginRegisterFormWrapper>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={className}
        name="login-form"
      >
        <FormTextField
          control={control}
          rules={{ ...validateRequired(t), ...validateEmail(t) }}
          autoComplete="email"
          type="email"
          name="email"
          id="email-login"
          label={t('loginForm.emailFieldLabel') + '*'}
        />
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="password"
          name="password"
          type="password"
          id="password-login"
          label={t('loginForm.passwordFieldLabel') + '*'}
        />
        <Caption>{t('loginForm.caption')}</Caption>
        <ActionItemsContainer>
          <SubmitButton
            text={t('loginForm.submitButtonLabel')}
            helpText={submitMessage}
            invalid={invalid}
            loading={isLoadingVisible}
          />
          <Link
            appearance={'ButtonTransparent'}
            href={ROUTE_ACCOUNT_FORGOT_PASSWORD}
          >
            {t('loginForm.forgotPasswordButtonLabel')}
          </Link>
        </ActionItemsContainer>
        {submitMessage && (
          <FormResponseMessage invalid={invalid} message={submitMessage} />
        )}
      </Form>
    </LoginRegisterFormWrapper>
  )
}
