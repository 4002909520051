import { DEFAULT_LOCALE_ID, LocaleId } from '@sans-souci/configs'
import { GraphQLClient } from 'graphql-request'
import { shopifyConfig } from '@sans-souci/shopify-sdk'

export function createShopifyClient(language: LocaleId = DEFAULT_LOCALE_ID) {
  const URL = `${shopifyConfig.domain}/api/${shopifyConfig.apiVersion}/graphql.json`

  if (!(URL && shopifyConfig.accessToken)) {
    const message = 'Please setup env variables for the project'
    throw new Error(message)
  }

  return new GraphQLClient(URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': shopifyConfig.accessToken,
      'Accept-Language': language,
    },
  })
}
