import { FC } from 'react'
import { ShopifyData } from '@sans-souci/shopify-sdk'
import { Media } from '../Media/Media'
import { PortableText } from './PortableText'
import { PortableTextBlocks } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'

const Wrap = styled('div', {
  margin: '1.5em 0',
})

export const EditorialPortableText: FC<{
  content?: PortableTextBlocks
  shopifyData?: ShopifyData
}> = ({ content }) => {
  return (
    <PortableText
      value={content}
      additionalComponents={{
        types: {
          media: (props) => (
            <Wrap>
              <Media
                {...props.value}
                sizes={'(min-width: 1000px) 45vw, 90vw'}
              />
            </Wrap>
          ),
        },
      }}
    />
  )
}
