import { FC } from 'react'
import { LinkActions } from '@sans-souci/models'

type ActionProps = {
  onClick: () => any
}

type LinkActionProps = {
  action: LinkActions
  renderButton: (actionProps: ActionProps) => JSX.Element
}

export const LinkAction: FC<LinkActionProps> = ({ action, renderButton }) => {
  switch (action.type) {
    case 'linkPayloadActionAlert': {
      const actionProps = {
        onClick: () => alert(action.payload.alert),
      }
      return <>{renderButton(actionProps)}</>
    }
    case 'linkPayloadActionModal': {
      const actionProps = {
        onClick: () => alert(action.payload.modalContent),
      }
      return <>{renderButton(actionProps)}</>
    }
    case 'linkPayloadActionScrollTo': {
      const actionProps = {
        onClick: () => {
          const targetId = action.payload.target
          if (!targetId) return
          const target = document.getElementById(targetId)
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
          if (targetId === 'footer-newsletter-form') {
            document
              .getElementById('footer-newsletter-form-first-field')
              ?.focus({ preventScroll: true })
          }
        },
      }
      return <>{renderButton(actionProps)}</>
    }
  }
}
