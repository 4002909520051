import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'

export const createSectionContactFormProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
         ${coalesceLocaleField('title', localeId)},
        "id": id.current
      }
  `
}
