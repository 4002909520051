import { styled } from '@sans-souci/styles'
import { FC, PropsWithChildren } from 'react'

const FormContainer = styled('div', {
  display: 'grid',
  gap: '$48',
  gridAutoFlow: 'row',
  height: 'fit-content',
  backgroundColor: '$gray100',
  paddingBlock: '$40',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
})

const TextContainer = styled('div', {
  display: 'grid',
  gap: '$4',
})

const Title = styled('h2', {
  $projectFont: 'heading02',
})

const Description = styled('p', {
  maxWidth: '$maxWidthS',
  $projectFont: 'dev',
})

type FormProps = PropsWithChildren<{
  isRequiredTextVisible?: boolean
  title?: string
  description?: string | undefined
}>

// styled in the same way as accountCardWrapper
// but used only for login/register/activate/forgot/reset actions

export const LoginRegisterFormWrapper: FC<FormProps> = ({
  isRequiredTextVisible = false,
  children,
  title,
  description,
}) => {
  return (
    <FormContainer>
      {(title || description || isRequiredTextVisible) && (
        <TextContainer>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
        </TextContainer>
      )}
      {children}
    </FormContainer>
  )
}
