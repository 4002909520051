import { FC, useState } from 'react'
import { styled, useMediaQuery } from '@sans-souci/styles'
import {
  CurrentItemsType,
  FiltersType,
  OnFilterChange,
  OnSortChange,
} from './models'
import { Modal } from '../Modal/Modal'
import { Close, Picto } from '@sans-souci/icons'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Counter } from './Counter'

const Root = styled('div', {})

const OpenFiltersTab = styled('button', {
  $projectFont: 'mono01',
  display: 'flex',
  gap: '10px',
  background: '$white_02',
  padding: '6px 10px $4 10px',
  alignItems: 'center',
  minHeight: '$32',
})

const Content = styled('div', {
  $projectFont: 'mono01',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  background: '$beige',
  padding: '$24 $32 0px',
  '@md': {
    width: '$416',
  },
})

const TopColumn = styled('div', {
  display: 'flex',
  gap: '$20',
  alignItems: 'center',
  justifyContent: 'end',
  minHeight: '$20',
})

const StyledCloseButton = styled(Button, {
  display: 'flex',
  placeSelf: 'center',
  cursor: 'pointer',
  right: 0,

  '&:hover': {
    opacity: 0.6,
    transition: 'opacity 0.3s',
  },
})

const InputsWrap = styled('div', {
  padding: '$72 0 0',
  marginBottom: '$40',
  height: '100%',

  display: 'grid',
  alignContent: 'start',
  rowGap: '$40',
})

const FilterTitle = styled('p', {
  marginBottom: '$20',
})

const FilterValuesWrap = styled('div', {
  display: 'grid',
  gap: '$8',
})

const FilterLabelWrap = styled('div', {
  backgroundColor: '$white_05',
})

const SaveButtonWrap = styled('div', {
  position: 'sticky',
  bottom: 0,
  paddingBottom: '$32',
  backgroundColor: '$beige',
  marginTop: 'auto',
})

const SaveButton = styled(Button, {
  width: '100%',
  padding: '100px',
})

const Input = styled('input', {
  appearance: 'none',
  position: 'absolute',
  width: 1,
  height: 1,
  opacity: 0,
  borderRadius: 0,
})

const CheckMark = styled('div', {
  marginRight: '$12',
  position: 'relative',
  border: '1px solid $black',
  width: '18px',
  height: '18px',
  transition: 'opacity 150ms ease-in',
  variants: {
    checked: {
      true: {
        backgroundColor: 'currentColor',
      },
    },
  },
})

const RoundedCheckMark = styled('div', {
  marginRight: '$12',
  border: '1px solid $black',
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    checked: {
      true: {
        '&::before': {
          content: "''",
          width: '60%',
          height: '60%',
          borderRadius: '50%',
          backgroundColor: 'currentColor',
        },
      },
    },
  },
})

const Label = styled('label', {
  display: 'grid',
  padding: '10px 10px 8px',

  gridAutoFlow: 'column',
  justifyContent: 'left',
  alignItems: 'center',
  $projectFont: 'mono01',
  cursor: 'pointer',
  [`${Input}:focus-visible + ${CheckMark}`]: {
    boxShadow: '0 0 $sizes$2 $sizes$2 $colors$lightblue',
    borderColor: '$blue',
  },
})

type FilterProps = {
  filters: FiltersType
  onFilterChange: OnFilterChange
  onSortChange: OnSortChange
  currentItems: Array<CurrentItemsType>
  activeFilterCounts?: number
}

export const Filter: FC<FilterProps> = ({
  filters,
  onFilterChange,
  onSortChange,
  currentItems,
  activeFilterCounts,
}) => {
  const matchesMd = useMediaQuery('md')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation('filters')

  const handleSaveClick = () => {
    setIsModalOpen(false)
  }

  return (
    <Root>
      <OpenFiltersTab onClick={() => setIsModalOpen(true)}>
        {t('filters')} <Counter number={activeFilterCounts} /> <Picto />
      </OpenFiltersTab>

      <Modal
        preventScroll={true}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        size={matchesMd ? 'intrinsic' : 'stretch-x'}
        position={matchesMd ? 'right' : 'center'}
        transition={'slide-from-right'}
      >
        <Content>
          <TopColumn>
            <p>{t('filters')}</p> <Counter number={activeFilterCounts} />
            <StyledCloseButton onClick={() => setIsModalOpen(false)}>
              <Close />
            </StyledCloseButton>
          </TopColumn>
          <InputsWrap>
            <>
              {filters.sort && filters.sort.values.length > 1 && (
                <div>
                  <FilterTitle>{t('sort')}</FilterTitle>
                  <FilterValuesWrap>
                    {filters.sort.values.map((value) => (
                      <FilterLabelWrap key={value.slug}>
                        <Label>
                          <Input
                            type="radio"
                            onClick={() => onSortChange(value.slug)}
                            value={String(value.slug)}
                          />
                          <RoundedCheckMark
                            checked={Boolean(
                              value.slug === filters.sort.activeSortSlug,
                            )}
                          />
                          <span>{value.title}</span>
                        </Label>
                      </FilterLabelWrap>
                    ))}
                  </FilterValuesWrap>
                </div>
              )}
            </>
            <>
              {filters?.filters &&
                filters.filters.map((filter) => (
                  <React.Fragment key={filter.slug}>
                    {filter.values.length > 1 && (
                      <div>
                        <FilterTitle>{filter.title}</FilterTitle>
                        <FilterValuesWrap>
                          {filter.values.map((value) => (
                            <FilterLabelWrap key={value.slug}>
                              <Label>
                                <Input
                                  type="checkbox"
                                  onChange={() =>
                                    onFilterChange(filter.slug, value.slug)
                                  }
                                  value={String(value.slug)}
                                />
                                <CheckMark checked={Boolean(value.isActive)} />
                                <span>{value.slug}</span>
                              </Label>
                            </FilterLabelWrap>
                          ))}
                        </FilterValuesWrap>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </>
          </InputsWrap>

          <SaveButtonWrap>
            <SaveButton
              appearance={'ButtonPrimaryBlack'}
              onClick={handleSaveClick}
              disabled={currentItems.length === 0}
            >
              {t('show', { value: currentItems.length })}
            </SaveButton>
          </SaveButtonWrap>
        </Content>
      </Modal>
    </Root>
  )
}
