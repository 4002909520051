import { styled } from '@sans-souci/styles'
import React from 'react'

export const StyledClickable = styled('button', {
  background: 'none',
  color: 'currentColor',
  variants: {
    appearance: {
      ButtonOutline: {
        $projectFont: 'mono01',
        border: '1px solid $lightGrey',
        color: '$black',
        height: 'buttonHeightL',
        display: 'inline-grid',
        padding: '10px 10px $8 10px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        transition: '0.2s border, 0.2s color',

        '@lg': {
          '&:hover': {
            borderColor: '$grey',
            textDecoration: 'underline',
          },
        },
        '&:active': {
          borderColor: '$black',
          textDecoration: 'none',
        },
        '&:focus-visible': {
          outline: '$focusColor solid 3px',
        },
        '&:disabled': {
          color: '$lightGrey',
        },
      },

      ButtonTransparent: {
        $projectFont: 'mono01',
        color: '$grey',
        height: 'buttonHeightL',
        display: 'inline-grid',
        padding: '10px 10px $8 10px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        transition: '0.2s border, 0.2s color',

        '@lg': {
          '&:hover': {
            borderColor: '$grey',
            textDecoration: 'underline',
          },
        },
        '&:active': {
          borderColor: '$black',
          textDecoration: 'none',
        },
        '&:focus-visible': {
          outline: '$focusColor solid 3px',
        },
        '&:disabled': {
          color: '$lightGrey',
        },
      },

      ButtonPrimaryWhite: {
        background: '$white_02',
        $projectFont: 'mono01',
        height: 'buttonHeightL',
        display: 'inline-grid',
        padding: '10px 10px $8 10px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.2s background',
        whiteSpace: 'nowrap',

        '@lg': {
          '&:hover': {
            background: '$white_03',
            textDecoration: 'underline',
          },
        },
        '&:active': {
          background: '$white_05',
          textDecoration: 'none',
        },
        '&:focus-visible': {
          outline: '$focusColor solid 3px',
        },
        '&:disabled': {
          color: '$lightGrey',
        },
      },

      ButtonSolidWhite: {
        background: '$grey200',
        $projectFont: 'mono01',
        height: 'buttonHeightL',
        display: 'inline-grid',
        padding: '10px 10px $8 10px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.2s background',
        whiteSpace: 'nowrap',

        '@lg': {
          '&:hover': {
            background: '$grey300',
            textDecoration: 'underline',
          },
        },
        '&:active': {
          background: '$grey500',
          textDecoration: 'none',
        },
        '&:focus-visible': {
          outline: '$focusColor solid 3px',
        },
        '&:disabled': {
          color: '$lightGrey',
        },
      },

      ButtonPrimaryGrey: {
        $projectFont: 'mono01',
        color: '$black',
        height: 'buttonHeightL',
        background: '$grey_02',
        display: 'inline-grid',
        padding: '10px 10px $8 10px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.2s background',
        whiteSpace: 'nowrap',

        '@lg': {
          '&:hover': {
            background: '$grey_03',
            textDecoration: 'underline',
          },
        },
        '&:active': {
          background: '$grey_05',
          textDecoration: 'none',
        },
        '&:focus-visible': {
          outline: '$focusColor solid 3px',
        },
        '&:disabled': {
          color: '$lightGrey',
        },
      },

      ButtonPrimaryBlack: {
        $projectFont: 'mono01',
        color: '$white',
        height: 'buttonHeightL',
        background: '$black',
        display: 'inline-grid',
        padding: '10px 10px $8 10px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.2s background',
        whiteSpace: 'nowrap',

        '@lg': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '&:active': {
          background: '$black_05',
          textDecoration: 'none',
        },
        '&:focus-visible': {
          outline: '$focusColor solid 3px',
        },
        '&:disabled': {
          color: '$lightGrey',
        },
      },

      ButtonText: {
        $projectFont: 'mono01',
        width: 'fit-content',
        color: 'currentColor',
        padding: '$4',
        pointerEvents: 'all',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  focusStyle: 'default',
  cursor: 'pointer',
  $projectFont: 'body01',
  compoundVariants: [],
  '&:disabled': {
    pointerEvents: 'none',
  },
})

export type Appearance =
  | 'ButtonPrimaryWhite'
  | 'ButtonSolidWhite'
  | 'ButtonPrimaryGrey'
  | 'ButtonOutline'
  | 'ButtonTransparent'
  | 'ButtonText'
  | 'ButtonPrimaryBlack'

export type StyledClickableProps = {
  appearance?: Appearance
  ariaLabel?: string
  tabIndex?: number
  disabled?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
}
