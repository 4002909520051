import { Button, Link, NavTrigger, useAnimation } from '@sans-souci/components'
import { SiteConfiguration } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { FC, useEffect, useRef, useState } from 'react'
import { NavModal } from './NavModal'
import { Logo } from '@sans-souci/icons'
import { useTranslation } from 'react-i18next'
import { SkipToMainContent } from './SkipToMainContent'
import { useRouter } from 'next/router'
import { useCustomerContext } from '@sans-souci/account'
// import { useCartContext } from '@sans-souci/cart'

type Props = {
  header: SiteConfiguration['header']
  color?: 'light' | 'dark'
  breadcrumbs?: {
    title?: string
    href?: string
    loginInfo?: boolean
  }[]
}

const HeaderWrap = styled('header', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: '$header',
  color: '$black',
  height: '$headerHeightMobile',
  transition: 'all 200ms',
  // borderBottom: '1px solid currentColor',
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@md': {
    height: '$headerHeightDesktop',
    gridTemplateColumns: '1fr auto 1fr',
  },
  '&:after': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '200px',
    opacity: 0,
    transition: 'all 500ms',
    pointerEvents: 'none',
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)',
  },
  variants: {
    color: {
      light: {
        color: '$beige',
      },
      dark: {},
    },
    isShifted: {
      true: {
        opacity: 0,
      },
      false: {},
    },
    isFilled: {
      true: {
        '&:after': {
          opacity: 1,
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      isFilled: 'true',
      isShifted: 'true',
      css: {
        '&:after': {
          opacity: 0,
        },
      },
    },
  ],
})
const StyledLogo = styled(Link, {
  $focusStyle: 'default',
  padding: '$8 $32',
  display: 'grid',
  justifyItems: 'start',
  alignItems: 'center',
  zIndex: 1,
  '@md': {
    padding: '$8',
    justifyItems: 'center',
    gridColumn: 2,
    gridRow: 1,
  },
  svg: {
    width: '130px',
    '@md': {
      width: '184px',
    },
  },
  variants: {
    color: {
      light: {
        color: '$beige',
      },
      dark: {},
    },
  },
})
const Breadcrumbs = styled('div', {
  position: 'absolute',
  pointerEvents: 'none',
  left: 0,
  width: '100%',
  top: '$headerHeightMobile',
  marginTop: '$48',
  zIndex: '50',
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  gap: '$16',
  padding: '0 $32',
  '@md': {
    marginTop: '$56',
    top: '$headerHeightDesktop',
  },
  variants: {
    color: {
      light: {
        color: '$beige',
      },
      dark: {},
    },
  },
})

const BreadcrumbItem = styled('span', {
  $projectFont: 'mono01',
})

// const MiniCartButton = styled(Button, {
//   justifySelf: 'end',
//   alignSelf: 'center',
//   height: 'fit-content',
//   $projectFont: 'mono01',
//   pointerEvents: 'all',
//   zIndex: 1,
//
//   '@md': {
//     padding: '$12 $32',
//   },
// })
//
// const Superscript = styled('span', {
//   fontSize: '0.9em',
//   marginLeft: '1px',
//   verticalAlign: 'super',
// })

export const Header: FC<Props> = ({ header, breadcrumbs, color = 'light' }) => {
  const enteringTime = 600
  const exitingTime = 400

  const [isNavOpen, setNavOpen] = useState(false)
  const [isShifted, setIsShifted] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const { customer, removeToken } = useCustomerContext()
  // const { openMiniCart, quantity } = useCartContext()

  const router = useRouter()
  const { t } = useTranslation('header')
  const toggleNav = () => {
    setNavOpen((prevState) => !prevState)
  }
  const closeNav = () => {
    setNavOpen(false)
  }

  const status = useAnimation(isNavOpen, enteringTime, exitingTime)
  const refPrevScrollPos = useRef<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos: number = window.scrollY
      if (currentScrollPos > refPrevScrollPos.current && currentScrollPos > 0) {
        setIsShifted(true)
      } else {
        setIsShifted(false)
      }
      if (currentScrollPos > window.innerHeight * 0.9) {
        setIsFilled(true)
      } else {
        setIsFilled(false)
      }
      refPrevScrollPos.current = window.scrollY
    }
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      closeNav()
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  if (!header) return null

  return (
    <>
      <SkipToMainContent />

      <HeaderWrap
        css={{
          $$enteringTime: `${enteringTime}ms`,
          $$exitingTime: `${exitingTime}ms`,
        }}
        // filled={scrollingUp && scrolledDown}
        color={color}
        isShifted={isShifted}
        isFilled={isFilled && color === 'light'}
      >
        <StyledLogo href={`/`} aria-label={t('logoAriaLabel')} color={color}>
          <Logo />
        </StyledLogo>

        {/*<MiniCartButton*/}
        {/*  onClick={() => {*/}
        {/*    openMiniCart()*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {t('openMiniCartModal')}*/}
        {/*  {quantity !== 0 && <Superscript>{quantity}</Superscript>}*/}
        {/*</MiniCartButton>*/}
        <NavTrigger status={status} onClick={toggleNav} />
      </HeaderWrap>

      {breadcrumbs && (
        <Breadcrumbs color={color}>
          {breadcrumbs.map((breadcrumb, i) => {
            if (breadcrumb.loginInfo === true)
              return (
                <BreadcrumbItem key={i}>
                  {t('goodMorning')} {customer?.firstName} / {t('notYou')}{' '}
                  <Button onClick={removeToken} appearance={'ButtonText'}>
                    {t('logout')}
                  </Button>
                </BreadcrumbItem>
              )

            return (
              <>
                {breadcrumb.href ? (
                  <Link
                    href={breadcrumb.href}
                    appearance={'ButtonText'}
                    key={i}
                  >
                    {breadcrumb.title}
                  </Link>
                ) : (
                  <BreadcrumbItem key={i}>{breadcrumb.title}</BreadcrumbItem>
                )}
                {i < breadcrumbs.length - 1 && <span>/</span>}
              </>
            )
          })}
        </Breadcrumbs>
      )}

      <NavModal
        onCloseRequest={toggleNav}
        header={header}
        isOpen={isNavOpen}
        enterTime={enteringTime}
        exitTime={exitingTime}
        status={status}
      />
    </>
  )
}
