import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionSampleProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField('text', localeId)},
    ctaLink${LINK_PROJECTION},
    ${coalesceLocaleField('ctaLabel', localeId)},
    media${MEDIA_PROJECTION},
    "shopifyIds": {
      "productBasic": [
        product->store.gid,
        ...products[] -> store.gid
      ]
    }
  }`
}
