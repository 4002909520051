import { FC, PropsWithChildren } from 'react'
import { MediaFieldType } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { Media } from '../Media/Media'
import { SectionWrap } from '../SectionWrap/SectionWrap'

const Wrap = styled('div', {
  display: 'grid',
  gridTemplateAreas: `
  'left' 'right'
   `,
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
    'left right'
    `,
  },

  $lin: {
    rowGap: 'S',
    padding: 'S',
  },
  variants: {
    equalRows: {
      true: {
        gridAutoRows: '1fr',
      },
    },
    textColor: {
      beige: {
        color: '$beige',
      },
      black: {},
    },
  },
})

const StickyWrap = styled('div', {
  variants: {
    enabled: {
      true: {
        '@md': {
          top: '$32',
          position: 'sticky',
        },
      },
    },
  },
})

const MediaWrap = styled('div', {
  variants: {
    mediaPosition: {
      left: {
        gridArea: 'left',
      },
      right: {
        gridArea: 'left',
        '@lg': {
          gridArea: 'right',
        },
      },
    },
  },
})

const ElementWrap = styled('div', {
  display: 'grid',
  height: '100%',
  justifyItems: 'center',

  variants: {
    mediaPosition: {
      left: {
        gridArea: 'right',
        '@lg': {
          paddingLeft: '$32',
        },
      },
      right: {
        gridArea: 'right',
        '@lg': {
          gridArea: 'left',
        },
      },
    },
  },
})

export const HalvesElementAndMedia: FC<
  PropsWithChildren<{
    media?: MediaFieldType
    mediaPosition?: 'right' | 'left'
    priority?: boolean
    stickyMedia?: boolean
    equalRows?: boolean
    textColor?: 'beige' | 'black'
    id?: string
  }>
> = ({
  media,
  textColor,
  mediaPosition,
  priority,
  equalRows,
  children,
  stickyMedia,
  id,
}) => {
  return (
    <SectionWrap type={'withBorders'} id={id}>
      <Wrap equalRows={equalRows} textColor={textColor}>
        <MediaWrap mediaPosition={mediaPosition}>
          <StickyWrap enabled={stickyMedia}>
            {media && (
              <Media
                {...media}
                priority={priority}
                sizes={'(min-width: 1000px) 50vw, 100vw'}
                ratio={'portrait2'}
              />
            )}
          </StickyWrap>
        </MediaWrap>
        <ElementWrap mediaPosition={mediaPosition}>{children}</ElementWrap>
      </Wrap>
    </SectionWrap>
  )
}
