import { LocaleId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createMediaProjection } from './createMediaProjection'
import { createPortableTextProjection } from './createPortableTextProjection'

export const createEditorialPortableTextProjection = (localeId: LocaleId) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    ...${PORTABLE_TEXT_PROJECTION},
    _type == "media" => {
      _type,
      _key,
      ...${MEDIA_PROJECTION}
    },
  }`
}
