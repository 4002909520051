import { FC } from 'react'
import { ProjectType } from '@sans-souci/models'
import { GenericCard } from '../GenericCard/GenericCard'

type ProjectCardProps = {
  project: ProjectType
  sizes?: string
  priority?: boolean
}
export const ProjectCard: FC<ProjectCardProps> = ({
  project,
  sizes,
  priority = false,
}) => {
  return (
    <GenericCard
      {...project}
      sizes={sizes}
      priority={priority}
      label={'Discover the project'}
    />
  )
}
