import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionPhilosophySliderProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
      slides[]{
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('text', localeId)},
      color,
      ctaLink${LINK_PROJECTION}
    }
  }
  `
}
