import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createSectionSampleProjection } from '../sections/createSectionSampleProjection'
import { createSectionFullWidthMediaProjection } from '../sections/createSectionFullWidthMediaProjection'
import { createSectionCoverProjection } from '../sections/createSectionCoverProjection'
import { createSectionHalvesSimpleProjection } from '../sections/createSectionHalvesSimpleProjection'
import { createSectionHalvesRichProjection } from '../sections/createSectionHalvesRichProjection'
import { createSectionProductGridProjection } from '../sections/createSectionProductGridProjection'
import { createSectionProductSliderProjection } from '../sections/createSectionProductSliderProjection'
import { createSectionPhilosophySliderProjection } from '../sections/createSectionPhilosophySliderProjection'
import { createSectionFocusProjection } from '../sections/createSectionFocusProjection'
import { createSectionGalleryProjection } from '../sections/createSectionGalleryProjection'
import { createSectionCrossDiscoveryProjection } from '../sections/createSectionCrossDiscoveryProjection'
import { createSectionContactFormProjection } from '../sections/createSectionContactFormProjection'
import { createSectionUtilityContentProjection } from '../sections/createSectionUtilityContentProjection'
import { createSectionCoverSliderProjection } from '../sections/createSectionCoverSliderProjection'
import { createSectionHeroProjection } from '../sections/createSectionHeroProjection'
import { createSectionPromoCardProjection } from '../sections/createSectionPromoCardProjection'
import { createSectionHeroSliderProjection } from '../sections/createSectionHeroSliderProjection'
import { createSectionCookieDeclarationProjection } from '../sections/createSectionCookieDeclarationProjection'

const createSectionsProjection = (localeId: LocaleId) =>
  groq`
 _type == 'sectionCookieDeclaration' =>  ${createSectionCookieDeclarationProjection(
   localeId,
 )},
  _type == 'sectionSample' => ${createSectionSampleProjection(localeId)},
  _type == 'sectionFullWidthMedia' =>  ${createSectionFullWidthMediaProjection(
    localeId,
  )},
  _type == 'sectionHalvesSimple' =>  ${createSectionHalvesSimpleProjection(
    localeId,
  )},
  _type == 'sectionHalvesRich' =>  ${createSectionHalvesRichProjection(
    localeId,
  )},
  _type == 'sectionProductGrid' =>  ${createSectionProductGridProjection(
    localeId,
  )},
  _type == 'sectionCover' =>  ${createSectionCoverProjection(localeId)},
  _type == 'sectionPhilosophySlider' =>  ${createSectionPhilosophySliderProjection(
    localeId,
  )},
  _type == 'sectionProductSlider' =>  ${createSectionProductSliderProjection(
    localeId,
  )},
  _type == 'sectionCoverSlider' =>  ${createSectionCoverSliderProjection(
    localeId,
  )},
  _type == 'sectionFocus' =>  ${createSectionFocusProjection(localeId)},
  _type == 'sectionCrossDiscovery' =>  ${createSectionCrossDiscoveryProjection(
    localeId,
  )},
  _type == 'sectionContactForm' =>  ${createSectionContactFormProjection(
    localeId,
  )},
  _type == 'sectionGallery' =>  ${createSectionGalleryProjection(localeId)},
  _type == 'sectionUtilityContent' =>  ${createSectionUtilityContentProjection(
    localeId,
  )},
  _type == 'sectionHeroSlider' =>  ${createSectionHeroSliderProjection(
    localeId,
  )},
  _type == 'sectionHero' =>  ${createSectionHeroProjection(localeId)},
  _type == 'sectionPromoCard' =>  ${createSectionPromoCardProjection(localeId)},
`

export const createPageBuilderProjection = (localeId: LocaleId) => groq`{
  "pageBuilder": pageBuilder[]{
    "sS": {
      ...,
      _type == 'sharedSection' => {
        "section": *[_type == "sharedSection" && _id == ^._ref]{...}[0].section[0]
      }.section
    }
  }.sS{${createSectionsProjection(localeId)}}
}`
