export const ChevronDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#565C54"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0062 15.3352C11.9129 15.321 11.7822 15.2884 11.683 15.1974L6.22849 10.1974C6.08161 10.0628 6 9.88568 6 9.7C6 9.51432 6.08161 9.33721 6.22849 9.20257C6.52286 8.93274 6.96692 8.93248 7.26157 9.2018L12.2531 13.68L17.1376 9.20257C17.4322 8.93248 17.8769 8.93248 18.1715 9.20257C18.3184 9.33721 18.4 9.51432 18.4 9.7C18.4 9.88568 18.3184 10.0628 18.1715 10.1974L12.7578 15.16C12.7326 15.2137 12.6915 15.2524 12.6532 15.2767C12.5989 15.3111 12.5385 15.3263 12.4952 15.3342C12.4088 15.3501 12.3118 15.35 12.2596 15.35L12.2515 15.35C12.1974 15.35 12.1031 15.35 12.0062 15.3352Z"
    />
  </svg>
)
