import {
  LocaleId,
  RegionId,
  ROUTE_BASE_SANITY_PRODUCTS,
} from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from './createMediaProjection'
import { createLinkProjection } from './createLinkProjection'

export const createSanityRichProductPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
      ...,
      "pageBuilder": [
        {
          "_type": "sectionHero",
          "_key": "sectionHero",
          ${coalesceLocaleField('title', localeId)},
           media${MEDIA_PROJECTION},
           cta${LINK_PROJECTION},
          ${coalesceLocaleField('description', localeId)},
          mode,
        },
        ...${PAGE_BUILDER_PROJECTION}.pageBuilder,
      ],
      "background": "light",
      "headerColor": "light",
      "breadcrumbs": [
        {
          "title": "Products",
          "href": "${ROUTE_BASE_SANITY_PRODUCTS}",
        },
        {
          ${coalesceLocaleField('title', localeId)}
        }
      ],
      "seo": {
        "metaTitle": {${coalesceLocaleField('title', localeId)}}.title,
        "ogTitle": {${coalesceLocaleField('title', localeId)}}.title,
        "metaDescription": {${coalesceLocaleField(
          'description',
          localeId,
        )}}.description,
        "ogDescription": {${coalesceLocaleField(
          'description',
          localeId,
        )}}.description,
        "ogImage": media${MEDIA_PROJECTION}
      }
    }`
}
