import {
  createShopifySdk,
  formatShopifyProductBasic,
  formatShopifyVariantBasic,
  ShopifyCountryCode,
  ShopifyData,
  ShopifyDataSectionIds,
  ShopifyGetNodesByIdQueryVariables,
  ShopifyResolvedProductBasic,
  ShopifyResolvedVariantBasic,
} from '@sans-souci/shopify-sdk'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { LocaleId } from '@sans-souci/configs'
import { createShopifyClient } from './createShopifyClient'
import { PageBuilderSection } from '@sans-souci/models'

const __withoutNulls = (
  shopifyIds: ShopifyDataSectionIds,
  shopifyData: ShopifyGetNodesByIdQueryVariables,
) =>
  Object.entries(shopifyIds).reduce((acc, val) => {
    const [key, value] = val
    const prevValues = shopifyData[key as keyof ShopifyDataSectionIds] || []
    return {
      ...acc,
      [key]: [...prevValues, ...value.filter((id) => !!id)],
    }
  }, {})

const __getShopifyDataByIds = async (
  variables: ShopifyGetNodesByIdQueryVariables,
  shopifyClientLocaleId: LocaleId,
) => {
  const shopifyClient = createShopifyClient(shopifyClientLocaleId)

  return await createShopifySdk(shopifyClient)
    .getNodesById(variables)
    .then((res) => {
      return {
        productBasic: res.data.productBasic.reduce((acc, val) => {
          return val && val.__typename === 'Product'
            ? {
                ...acc,
                [val.id]: formatShopifyProductBasic(val),
              }
            : acc
        }, {} as Record<string, ShopifyResolvedProductBasic>),
        variantBasic: res.data.variantBasic.reduce((acc, val) => {
          return val && val.__typename === 'ProductVariant'
            ? {
                ...acc,
                [val.id]: formatShopifyVariantBasic(val),
              }
            : acc
        }, {} as Record<string, ShopifyResolvedVariantBasic>),
      }
    })
}

export const getShopifyDataFromPageBuilder = async (
  pageBuilder: PageBuilderSection[] | null,
  locale: string,
): Promise<ShopifyData> => {
  if (!(pageBuilder && pageBuilder.length)) return {}

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  const variables = pageBuilder.reduce<ShopifyGetNodesByIdQueryVariables>(
    (acc, section) => {
      if ('shopifyIds' in section && !!section.shopifyIds) {
        return {
          ...acc,
          ...__withoutNulls(section.shopifyIds, acc),
        }
      }

      return acc
    },
    {
      productBasic: [],
      variantBasic: [],
      regionId: regionId as ShopifyCountryCode,
    },
  )

  return await __getShopifyDataByIds(variables, localeId)
}
