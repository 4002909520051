export const Logo = () => {
  return (
    <svg
      width="184"
      height="34"
      viewBox="0 0 184 34"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M54.8611 21.552V0.60179H58.8661V33.4197C57.5328 33.4197 56.197 33.3491 54.8741 33.4433C53.8631 33.5139 53.6188 33.0013 53.3693 32.1644C51.4721 25.8122 49.5229 19.4756 47.5867 13.1364C47.5087 12.8775 47.4177 12.6238 47.2098 12.3989V33.3387H43.2048V0.536411C44.5381 0.536411 45.8739 0.520719 47.2072 0.541641C48.3144 0.557332 48.3144 0.575638 48.647 1.6714C50.6274 8.15705 52.6078 14.6427 54.5882 21.1284C54.6324 21.2722 54.6844 21.4134 54.7311 21.5546C54.7753 21.5546 54.8169 21.5546 54.8611 21.552Z" />
      <path d="M116.586 16.9439C116.586 13.4579 116.493 9.96921 116.612 6.4884C116.734 2.95005 119.18 0.515318 122.668 0.120425C124.149 -0.0469463 125.604 -0.0129489 127.042 0.381944C129.781 1.1325 131.288 2.99974 131.782 5.75353C131.87 6.24519 131.92 6.7473 131.922 7.2468C131.93 13.7403 131.935 20.2338 131.922 26.7273C131.922 28.163 131.608 29.5386 130.867 30.7835C129.745 32.6716 127.972 33.5346 125.893 33.8223C124.362 34.0341 122.826 33.9975 121.324 33.5608C118.528 32.7448 116.703 30.5402 116.615 27.5275C116.508 24.0023 116.591 20.4692 116.591 16.9413C116.591 16.9413 116.591 16.9413 116.589 16.9413L116.586 16.9439ZM126.997 16.9779C126.997 13.9704 127.01 10.963 126.987 7.95552C126.982 7.35402 126.912 6.73161 126.751 6.15627C126.408 4.93759 125.55 4.38579 124.193 4.40148C122.857 4.41717 121.984 5.01082 121.685 6.22688C121.527 6.87544 121.464 7.56062 121.462 8.23011C121.446 14.0698 121.451 19.9095 121.457 25.7492C121.457 26.2069 121.485 26.6645 121.54 27.1196C121.722 28.6285 122.631 29.5203 124.014 29.57C125.677 29.6302 126.587 28.9214 126.881 27.2948C126.966 26.8267 126.992 26.3429 126.992 25.8669C127.003 22.9039 126.997 19.9409 126.997 16.9753V16.9779Z" />
      <path d="M137.541 0.59375H142.479V1.46199C142.479 9.52722 142.474 17.5951 142.49 25.6603C142.49 26.4161 142.565 27.1902 142.739 27.9224C143.012 29.0705 143.781 29.5621 145.068 29.57C146.406 29.5778 147.196 29.0966 147.474 27.9355C147.654 27.1823 147.713 26.3847 147.716 25.608C147.732 17.5637 147.726 9.51937 147.726 1.47507V0.596365H152.633C152.646 0.844808 152.667 1.07756 152.667 1.31293C152.667 9.55337 152.675 17.7938 152.659 26.0343C152.659 26.9025 152.594 27.7812 152.444 28.6338C151.981 31.2097 150.51 32.92 147.968 33.5895C146.162 34.0655 144.322 34.0812 142.503 33.6444C139.636 32.9566 137.926 30.9456 137.658 27.9904C137.585 27.1876 137.546 26.3795 137.546 25.5766C137.539 17.5323 137.541 9.48799 137.541 1.44368C137.541 1.18478 137.541 0.925878 137.541 0.59375Z" />
      <path d="M26.1641 0.557303C28.1783 0.557303 30.1041 0.546842 32.0274 0.578224C32.1859 0.580839 32.4484 0.855434 32.4822 1.03588C33.1995 4.73114 33.8882 8.42901 34.5873 12.1269C35.866 18.9028 37.1447 25.6788 38.4234 32.4547C38.4753 32.7319 38.5195 33.0117 38.5845 33.3857C37.6567 33.3857 36.7574 33.3857 35.8556 33.3857C35.1409 33.3857 34.4262 33.37 33.7115 33.3909C33.3372 33.4014 33.1995 33.2863 33.1397 32.8914C32.8096 30.7182 32.4406 28.5528 32.0767 26.3378H25.9978C25.6184 28.6574 25.2337 30.9902 24.8465 33.3491H19.8071C21.9253 22.4124 24.0382 11.5123 26.1589 0.554688L26.1641 0.557303ZM29.1061 7.99227H28.9684C28.2225 12.6081 27.474 17.2239 26.7229 21.8658H31.3568C30.6005 17.2056 29.8546 12.5976 29.1061 7.99227Z" />
      <path d="M0 24.128H4.93019C4.98477 25.0277 5.00037 25.8959 5.09393 26.7537C5.33303 28.9426 6.85341 30.0174 8.8494 29.4525C9.51733 29.2643 9.97994 28.8511 10.2918 28.2339C11.8486 25.1558 10.6895 21.301 7.70847 19.6665C7.1211 19.3449 6.52854 19.0232 5.91519 18.7512C1.94921 16.999 0.0311873 13.9131 0.0675725 9.59023C0.0779683 8.37678 0.111755 7.13457 0.366451 5.95774C1.16693 2.25725 3.58134 0.225253 7.35501 0.0578815C8.48555 0.00819306 9.66027 0.0997245 10.7622 0.361243C13.6782 1.05165 15.4793 3.21702 15.721 6.24541C15.8146 7.40916 15.7366 8.58861 15.7366 9.80467H10.8818C10.8818 9.34963 10.9312 8.89459 10.8714 8.45524C10.7518 7.55823 10.6843 6.62984 10.3958 5.78252C10.0241 4.68937 9.05992 4.38863 7.98135 4.40432C6.8924 4.42001 5.98537 4.73383 5.49676 5.82698C4.09593 8.96781 5.14071 12.4094 8.06452 14.1616C9.17687 14.8284 10.3438 15.4116 11.4613 16.0733C14.1616 17.6685 15.4923 20.119 15.734 23.2075C15.8899 25.2133 15.9055 27.2192 15.2038 29.1492C14.1824 31.9605 12.1683 33.5636 9.22625 33.8408C7.99175 33.9585 6.70008 33.9141 5.48377 33.6839C2.43001 33.1033 0.452216 30.7941 0.17153 27.6533C0.0701715 26.5078 0.0597757 25.3546 0.00519789 24.1306L0 24.128Z" />
      <path d="M95.3586 24.1226H100.284C100.351 25.1007 100.354 26.0579 100.491 26.9967C100.793 29.0392 102.267 29.9885 104.187 29.4576C104.91 29.2589 105.422 28.8143 105.687 28.1291C106.479 26.0893 106.451 24.0416 105.609 22.0305C105.227 21.1178 104.476 20.4928 103.636 20.0116C102.716 19.4859 101.775 18.9917 100.822 18.5314C97.2922 16.8237 95.4989 13.9862 95.4469 10.0713C95.4287 8.66429 95.4547 7.2181 95.751 5.85558C96.5411 2.2074 98.9997 0.204174 102.747 0.0551084C103.86 0.0106503 105.011 0.0969513 106.09 0.350624C109.107 1.06195 110.885 3.29271 111.108 6.54076C111.181 7.60253 111.119 8.67214 111.119 9.79405H106.201C106.201 9.07226 106.266 8.35047 106.183 7.6496C106.103 6.96965 105.952 6.2714 105.684 5.64637C105.248 4.63168 104.325 4.38847 103.311 4.40416C102.277 4.41985 101.393 4.7049 100.894 5.72483C99.4675 8.65122 100.471 12.3177 103.21 14.028C104.182 14.6348 105.209 15.1552 106.217 15.6991C109.486 17.4592 111.028 20.2574 111.132 23.9317C111.184 25.7231 111.165 27.5015 110.521 29.2144C109.557 31.7721 107.784 33.4039 105.073 33.7413C103.678 33.9139 102.204 33.9165 100.824 33.6681C97.8146 33.1267 95.8861 30.9064 95.5509 27.8388C95.4183 26.6306 95.4183 25.4093 95.356 24.1226H95.3586Z" />
      <path d="M64.2315 24.1305H69.0629C69.1435 25.1818 69.1357 26.2122 69.3124 27.2085C69.6555 29.1202 71.2122 30.012 73.0471 29.4367C74.2322 29.0653 74.6974 28.0689 74.817 26.9941C74.9521 25.7754 74.9339 24.5175 74.7988 23.2988C74.648 21.9259 73.8216 20.8929 72.6599 20.1763C71.888 19.7003 71.0667 19.3002 70.2688 18.8635C69.6243 18.5104 68.9616 18.1835 68.3378 17.7939C66.2015 16.4601 64.8552 14.5746 64.442 12.0483C64.1223 10.0765 64.0444 8.0994 64.4498 6.13539C65.2321 2.33553 67.6543 0.209389 71.5007 0.0629384C72.7378 0.0158651 74.0217 0.149239 75.2198 0.455216C77.4627 1.02794 78.9051 2.54213 79.5652 4.79381C80.0461 6.43353 79.9421 8.10201 79.9317 9.81234H75.0665C75.0145 8.88395 75.0275 7.94772 74.8975 7.0324C74.6091 4.97687 73.5721 4.20539 71.5345 4.44599C70.6197 4.55321 69.9258 5.00302 69.6113 5.88434C68.9356 7.7725 68.8472 9.71819 69.6503 11.5567C70.0037 12.3648 70.7418 13.0656 71.4461 13.6357C72.2128 14.2555 73.1121 14.7341 74.0113 15.1525C78.2528 17.1139 80.0928 20.4875 79.9499 25.1007C79.8979 26.7718 79.7264 28.4037 79.0013 29.9283C77.9851 32.0649 76.3062 33.3411 73.9905 33.757C72.5039 34.0237 71.0121 34.0263 69.5385 33.689C66.3444 32.9593 64.4316 30.5952 64.2367 27.1301C64.1821 26.1546 64.2289 25.1713 64.2289 24.1305H64.2315Z" />
      <path d="M173.635 9.8228H168.759C168.759 9.35991 168.803 8.90748 168.749 8.47075C168.64 7.59205 168.59 6.68458 168.312 5.85818C167.941 4.75457 167.06 4.33876 165.755 4.41198C164.562 4.47736 163.811 5.01347 163.494 6.11447C163.346 6.62966 163.244 7.18146 163.242 7.71757C163.226 13.9077 163.226 20.0979 163.242 26.2854C163.242 26.8006 163.341 27.3288 163.481 27.8231C163.798 28.9293 164.58 29.5125 165.719 29.5674C167.057 29.6354 167.935 29.2039 168.325 28.0846C168.585 27.3419 168.65 26.5234 168.744 25.731C168.803 25.2184 168.754 24.6953 168.754 24.1331H173.588C174.072 28.1814 173.154 31.8374 169.497 33.3019C167.143 34.246 164.7 34.2198 162.337 33.2627C160.016 32.3238 158.852 30.4278 158.447 28.0245C158.325 27.3001 158.291 26.5521 158.288 25.8146C158.275 19.9305 158.26 14.0463 158.301 8.16216C158.309 7.04286 158.428 5.88695 158.73 4.81472C159.411 2.39568 161.069 0.897177 163.489 0.300915C165.131 -0.104439 166.794 -0.0965931 168.442 0.2983C171.402 1.00701 173.38 3.35283 173.617 6.49105C173.697 7.57112 173.63 8.66166 173.63 9.82018L173.635 9.8228Z" />
      <path d="M179.248 0.588379H184.002V33.3409H179.248V0.588379Z" />
    </svg>
  )
}
