/**
 * Convention for color naming is tailwind-like with a _ suffix describing opacity
 */
export const colors = {
  // whites
  white: '#FFFFFF',
  white_01: 'rgba(255, 255, 255, 0.1)',
  white_02: 'rgba(255, 255, 255, 0.2)',
  white_03: 'rgba(255, 255, 255, 0.3)',
  white_05: 'rgba(255, 255, 255, 0.5)',

  // from design
  black: '#1A1919',
  black_05: 'rgba(26, 25, 25, 0.50)',
  yellow: '#FBFBEB',
  pink: '#DDC6D4',
  beige: '#E3DED8',
  beige_08: 'rgba(227, 222, 216, 0.8);',
  grey: '#656565',
  grey200: '#E9E5E0',
  grey300: '#EBE8E4',
  grey500: '#F1EFEC',
  grey_02: 'rgba(202, 202, 202, 0.2);',
  grey_03: 'rgba(202, 202, 202, 0.3);',
  grey_05: 'rgba(202, 202, 202, 0.5);',
  lightGrey: '#9D9B97',
  blue: '#2581C4', // border focus, gradient blue

  // forms, errors and successes
  lightgray: 'lightgray', // borders in forms
  lightblue: 'lightblue', // focus outline
  red100: '#DED5D1',
  red300: '#F96C6C',
  red500: '#B20707', // form error border, font color, help text color
  red900: '#343232',
  mistyRose: 'mistyRose', // error forms background
  green: 'green', // form success help text

  //project Specific
  //TODO: ASK Bruno about focus
  focusColor: 'rgba(0,113,227,0.5)',
}

export type Color = keyof typeof colors
