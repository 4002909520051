import { ChangeEvent, FC, useState } from 'react'
import { useForm } from 'local-react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  validateRequired,
  useFormSubmitState,
  FormCheckboxField,
  SubmitButton,
  FormTextField,
  FormSelectField,
  FormResponseMessage,
} from '@sans-souci/forms'
import { useRouter } from 'next/router'
import { createCustomerClient } from '../customerService'
import { removeEmptyProperties } from '../helpers/removeEmptyProperties'
import { ClientError } from 'graphql-request'
import { useCustomerContext } from '../customerContext'
import { Button } from '@sans-souci/components'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { shopifyCountries, UNITED_STATES } from '../helpers/shopifyCountries'
import { Form } from '../components/Form'
import { styled } from '@sans-souci/styles'
import { ShopifyCustomerUserErrorsFieldsFragment } from '@sans-souci/shopify-sdk'

type FormValues = {
  lastName?: string | null
  firstName?: string | null
  address1?: string | null
  address2?: string | null
  province?: string | null
  country?: string | null
  company?: string | null
  phone?: string | null
  zip?: string | null
  city?: string | null
  isDefault?: boolean
  id: string
}

interface UpdateFormProps {
  className?: string
  defaultValues?: FormValues
  onCancelClick?: () => void
  onSubmitSuccess?: () => void
  isDefault?: boolean
}

const initialDefaultValues: FormValues = {
  lastName: '',
  firstName: '',
  address1: '',
  address2: '',
  province: '',
  country: '',
  company: '',
  phone: '',
  zip: '',
  city: '',
  isDefault: false,
  id: '',
}

const HorizontalFieldsContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$32',
  '@lg': {
    gap: '$16',
    gridAutoFlow: 'column',
  },
})

export const UpdateAddressForm: FC<UpdateFormProps> = ({
  className,
  defaultValues = initialDefaultValues,
  onSubmitSuccess,
  onCancelClick,
  isDefault,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({
    form,
    defaultValues,
    keepValuesOnSuccess: true,
  })
  const { accessToken } = useCustomerContext()
  const customerAccessToken = accessToken || ''
  const [selectedCountryValue, setSelectedCountryValue] =
    useState(UNITED_STATES)

  const handleResponseErrors = (
    errors: ShopifyCustomerUserErrorsFieldsFragment[],
  ) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const { isDefault, id, ...restOfAddress } = data

      const response = await customerClient.updateAddress({
        address: removeEmptyProperties({ ...restOfAddress }),
        customerAccessToken,
        id,
      })
      const errors = response?.customerAddressUpdate?.customerUserErrors || []

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      if (!response?.customerAddressUpdate?.customerAddress) {
        return setError()
      }

      // set address as default if requested
      if (!initialDefaultValues.isDefault && isDefault) {
        const addressId = response?.customerAddressUpdate?.customerAddress?.id

        const defaultAddressResponse =
          await customerClient.updateDefaultAddress({
            addressId,
            customerAccessToken,
          })

        const defaultFieldErrors =
          defaultAddressResponse?.customerDefaultAddressUpdate
            ?.customerUserErrors || []

        if (defaultFieldErrors?.length) {
          return handleResponseErrors(defaultFieldErrors)
        }
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  const handleSelectedCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountryValue(e.target.value)
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="add-address-form"
    >
      <FormSelectField
        control={control}
        rules={validateRequired(t)}
        autoComplete="country"
        name="country"
        value={selectedCountryValue}
        onChange={handleSelectedCountryChange}
      >
        {shopifyCountries.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </FormSelectField>
      <HorizontalFieldsContainer>
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="name"
          name="firstName"
          label={t('updateAddressForm.firstNameFieldLabel')}
        />
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="family-name"
          name="lastName"
          label={t('updateAddressForm.lastNameFieldLabel')}
        />
      </HorizontalFieldsContainer>
      <FormTextField
        control={control}
        rules={validateRequired(t)}
        autoComplete="address-line1"
        name="address1"
        label={t('updateAddressForm.address1FieldLabel')}
      />
      <FormTextField
        control={control}
        autoComplete="address-line2"
        name="address2"
        label={t('updateAddressForm.address2FieldLabel')}
      />
      <HorizontalFieldsContainer>
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="city"
          name="city"
          label={t('updateAddressForm.cityFieldLabel')}
        />
        {selectedCountryValue === UNITED_STATES && (
          <FormTextField
            control={control}
            rules={validateRequired(t)}
            autoComplete="address-level1"
            name="province"
            label={t('updateAddressForm.provinceFieldLabel')}
          />
        )}
        <FormTextField
          control={control}
          rules={validateRequired(t)}
          autoComplete="postal-code"
          name="zip"
          label={t('updateAddressForm.zipFieldLabel')}
        />
      </HorizontalFieldsContainer>
      <FormTextField
        control={control}
        autoComplete="phone"
        name="phone"
        label={t('updateAddressForm.phoneFieldLabel')}
      />
      <FormCheckboxField
        disabled={isDefault}
        control={control}
        name="isDefault"
        label={t(
          isDefault
            ? 'updateAddressForm.isCurrentDefaultAddressFieldLabel'
            : 'updateAddressForm.setDefaultAddressFieldLabel',
        )}
      />
      <SubmitButton
        text={t('updateAddressForm.submitButtonLabel')}
        helpText={submitMessage}
        invalid={invalid}
        success={success}
        loading={isSubmitting}
      />
      {onCancelClick && (
        <Button appearance={'ButtonOutline'} onClick={onCancelClick}>
          {t('updateAddressForm.cancelButtonLabel')}
        </Button>
      )}
      <FormResponseMessage invalid={invalid} message={submitMessage} />
    </Form>
  )
}
