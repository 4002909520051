import { useEffect, useState } from 'react'

// TODO use shopify-sdk

export type Account = {
  email: string
  id: string
  firstName: string
}

export const useAccount = () => {
  const [account, setAccount] = useState<Account | null | undefined>(undefined)

  const refreshAccountData = () => {
    const cookieRaw: string | undefined = document?.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith('customer-data='))
      ?.split('=')[1]

    if (!cookieRaw) return setAccount(null)

    const cookieParsed: Account = JSON.parse(
      cookieRaw.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent),
    )

    return setAccount(cookieParsed)
  }

  useEffect(() => {
    refreshAccountData()
    window.addEventListener('focus', refreshAccountData)
    return () => window.removeEventListener('focus', refreshAccountData)
  }, [])

  return account
}
