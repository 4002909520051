import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { SiteConfiguration } from '@sans-souci/models'
import { createSEOProjection } from './projections/createSEOProjection'
import {
  createLinkProjection,
  createLinkRawProjection,
} from './projections/createLinkProjection'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { DEFAULT_REGION_ID } from '@sans-souci/configs'
import { createPortableTextProjection } from './projections/createPortableTextProjection'

export const getSiteConfiguration = async (
  localeRegionString: string,
  preview = false,
): Promise<SiteConfiguration> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const LINK_RAW_PROJECTION = createLinkRawProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)

  const seoQuery = groq`
    *[_type == "defaultSeo"]{
      defaultSeo${createSEOProjection(localeId)}
    }[0].defaultSeo
  `
  const searchQuery = groq`
    *[_type == "search"] {
      ${coalesceLocaleField('title', localeId)},
      links[]{
        ${coalesceLocaleField('title', localeId)},
        link${LINK_PROJECTION},
      },
    }[0]
  `
  const footerQuery = groq`
    *[_type == "footerSettings"] {
      "footerNavigation": coalesce(footerNavigation_${regionId}, footerNavigation_${DEFAULT_REGION_ID}) -> {
        linkGroups[] {
          ${coalesceLocaleField('title', localeId)},
          links[] {
            ${coalesceLocaleField('title', localeId)},
            ...${LINK_PROJECTION},
          }
        }
      }
    }[0].footerNavigation
  `
  const headerQuery = groq`
  *[_type == "headerSettings"] {
    "nav": coalesce(headerNavigation_${regionId}, headerNavigation_${DEFAULT_REGION_ID}) -> {
      topLinks[] {
        ${coalesceLocaleField('title', localeId)},
        ...${LINK_PROJECTION}
      },
       links[] {
        ${coalesceLocaleField('title', localeId)},
        ...${LINK_PROJECTION}
      },
      tabs[] {
        "id": _key,
        ${coalesceLocaleField('title', localeId)},
        link${LINK_RAW_PROJECTION},
        linkGroups[] {
          ${coalesceLocaleField('title', localeId)},
          links[] {
            ${coalesceLocaleField('title', localeId)},
            ...${LINK_PROJECTION},
          }
        }
      }
    }
  }[0].nav
  `

  const devSamplesQuery = groq`
    *[_type == "devSamples"]{
      richTextTest,
      ${coalesceLocaleField(
        'richTextTest',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
    }[0]
  `

  const newsletterQuery = groq`
  *[_type == "newsletter"]{
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField('disclaimer', localeId)}[]${PORTABLE_TEXT_PROJECTION},
  }[0]`

  const siteConfigurationQuery = groq`{
      "header": ${headerQuery},
      "footer": ${footerQuery},
      "seo": ${seoQuery},
      "search": ${searchQuery},
      "newsletter":${newsletterQuery},
      "devSamples":${devSamplesQuery}
    }
  `

  return await getClient(preview).fetch(siteConfigurationQuery)
}
