import { DEFAULT_REGION_ID, LocaleId, RegionId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSEOProjection } from './createSEOProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const createDefaultPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  return groq`coalesce(page_${regionId}, page_${DEFAULT_REGION_ID})->{
      ...,
      ${coalesceLocaleField('title', localeId)},
      "disallowRobots":^.disallowRobots,
      ...${PAGE_BUILDER_PROJECTION},
      seo${createSEOProjection(localeId)},
      background,
      headerColor
    }`
}
