import { styled } from '@sans-souci/styles'
import {
  Button,
  Container,
  FullPageLoader,
  SectionWrap,
} from '@sans-souci/components'
import { FC, useRef, useState } from 'react'
import { usePageContext } from './pageContext'
import { useTranslation } from 'react-i18next'
import { useCustomerContext } from '../../customerContext'
import { useAuthRedirect } from '../../useAuthRedirect'
import { InView } from 'react-intersection-observer'
import { MenuItem } from './components/MenuItem'
import { AddressesList } from './views/AddressesList'
import { OrdersList } from './views/OrdersList'
import { ViewOrder } from './views/ViewOrder'

const Root = styled(Container, {
  color: '$black',
  display: 'grid',
  gap: '$24',
})

const Title = styled('h1', {
  $projectFont: 'heading01',
})

const Description = styled('p', {
  $projectFont: 'dev',
})

const RootWrap = styled('div', {
  display: 'grid',
  maxWidth: '$legacy_maxWidthL',
  margin: '0 auto',
  gap: '$40',
  '@lg': {
    gap: '$12',
    gridTemplateColumns: '1fr 4fr',
  },
  gridAutoColumns: '100%',
})

const MenuContainerWrap = styled('div', {
  '@lg': {
    marginLeft: '$containerMarginDesktop',
    marginRight: '$containerMarginDesktop',
  },
})

const HeaderContentContainer = styled('div', {
  display: 'grid',
  grid: '$16',
})

const MenuContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'minmax(min-content, max-content)',
  padding: '$16 $containerMarginMobile',
  borderTop: '1px solid $black',
  borderBottom: '1px solid $black',
  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@md': {
    padding: '$16 $containerMarginDesktop',
  },
  '@lg': {
    border: 'none',
    gridAutoFlow: 'row',
    gridAutoRows: 'minmax(min-content, max-content)',
  },
})

const LogoutButtonContainerBottom = styled('div', {
  display: 'grid',
  '@lg': {
    display: 'none',
  },
})

export const HomePageContent: FC = () => {
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const { setActiveView, activeView } = usePageContext()
  const { t } = useTranslation('account')
  const { isLoggedIn, isVerified, removeToken, customer } = useCustomerContext()
  useAuthRedirect()

  const shouldDisplayAccount = isLoggedIn && isVerified
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const userName = customer ? `${customer?.firstName}` : ''
  const menuContainerItemGaps = 16

  if (!shouldDisplayAccount) {
    return <FullPageLoader />
  }

  const getCurrentSlideNumber = () => {
    if (!sliderRef.current) {
      return 0
    }
    return Math.round(
      sliderRef.current.scrollLeft / sliderRef.current.children[0].clientWidth,
    )
  }

  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef.current) {
      let numberOfSlidesWidth = -menuContainerItemGaps
      for (let i = 0; i < numberOfSlides; i++) {
        numberOfSlidesWidth += sliderRef.current
          ? sliderRef.current.children[i].clientWidth + menuContainerItemGaps
          : 0
      }
      sliderRef.current?.scrollBy({
        left: numberOfSlidesWidth,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const scrollTo = (slideNumber: number) => {
    if (sliderRef.current) {
      const currentIndex = getCurrentSlideNumber()
      scrollBySlides(slideNumber - currentIndex)
    }
  }

  function onClick() {
    removeToken()
    setIsLoggingOut(true)
  }

  return (
    <>
      <SectionWrap>
        <Root>
          <HeaderContentContainer>
            <Title>{t('accountPage.general.header')}</Title>
            <Description>
              {t('accountPage.general.description')} {userName}
            </Description>
          </HeaderContentContainer>
        </Root>
      </SectionWrap>
      <SectionWrap>
        <RootWrap>
          <MenuContainerWrap>
            <InView
              triggerOnce={true}
              threshold={0.5}
              onChange={(inView) => {
                if (inView) {
                  scrollBySlides(1)
                }
              }}
            >
              <MenuContainer
                ref={sliderRef}
                css={{ gap: `$${menuContainerItemGaps}` }}
              >
                <MenuItem
                  slider={sliderRef.current}
                  title={t('accountPage.general.ordersButtonLabel')}
                  onClick={() => {
                    setActiveView('orders-list')
                    scrollTo(0)
                  }}
                  isActive={activeView.startsWith('orders')}
                />
                <MenuItem
                  slider={sliderRef.current}
                  title={t('accountPage.general.addressButtonLabel')}
                  onClick={() => {
                    setActiveView('address-list')
                    scrollTo(1)
                  }}
                  isActive={activeView.startsWith('address')}
                />
                <MenuItem
                  slider={sliderRef.current}
                  title={t('common.logoutButtonLabel')}
                  onClick={onClick}
                  hideOnMobile={true}
                />
              </MenuContainer>
            </InView>
          </MenuContainerWrap>
          <Root>
            {(() => {
              switch (activeView) {
                case 'address-list':
                  return <AddressesList />
                case 'orders-list':
                  return <OrdersList />
                case 'orders-view':
                  return <ViewOrder />
                default:
                  return null
              }
            })()}
            <LogoutButtonContainerBottom>
              <Button
                onClick={onClick}
                disabled={isLoggingOut}
                appearance={'ButtonOutline'}
              >
                {t('common.logoutButtonLabel')}
              </Button>
            </LogoutButtonContainerBottom>
          </Root>
        </RootWrap>
      </SectionWrap>
    </>
  )
}
