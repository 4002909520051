import { styled } from '@sans-souci/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const StyledLink = styled('a', {
  top: 20,
  position: 'absolute',
  zIndex: 9999,
  transition: 'transform 300ms cubic-bezier(.4,.0,.6,1)',
  backgroundColor: '$black',
  color: '$white',
  display: 'grid',
  lineHeight: 1,
  borderRadius: '$r1',
  height: '$buttonHeightL',
  padding: '0 $20',
  placeContent: 'center',
  focusStyle: 'default',
  $projectFont: 'mono01',
  left: '$containerMarginMobile',
  transform: 'translateY(-200%)',
  '&:focus': {
    transform: 'none',
  },
  '@md': {
    left: '$containerMarginDesktop',
  },
})
export const SkipToMainContent: FC = () => {
  const { t } = useTranslation('header')
  return <StyledLink href="#main">{t('skipToContent')}</StyledLink>
}
