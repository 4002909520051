import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createPortableTextProjection } from '../projections/createPortableTextProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionCoverProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    media${MEDIA_PROJECTION},
    ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
    cta${LINK_PROJECTION}
  }
  `
}
