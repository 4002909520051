import { FC } from 'react'
import { ImageLoader } from 'next/image'

import { SanityImageMediaPayload } from '@sans-souci/models'
import { MediaLayoutType, MediaObjectFitType } from '../models/MediaProps'

import { getMediaSizeByRatio } from '../helpers/getMediaSizeByRatio'
import { Image } from './Image'
import { imageUrlBuilder } from '@sans-souci/services/sanity-service'
import { MediaRatiosTypeExtended } from '../ratios'

type SanityImageProps = {
  mediaPayload: SanityImageMediaPayload
  sizes?: string
  ratio?: MediaRatiosTypeExtended
  objectFit?: MediaObjectFitType
  priority?: boolean
  layout?: MediaLayoutType
  className?: string
}

export const SanityImage: FC<SanityImageProps> = ({
  ratio,
  objectFit,
  priority,
  sizes,
  layout,
  mediaPayload,
  className,
}) => {
  const { image } = mediaPayload

  if (!image?.asset) {
    return null
  }

  const buildedImage = imageUrlBuilder.image(image)

  const loader: ImageLoader = ({ width: loaderWidth, quality }) => {
    const [width, height] = getMediaSizeByRatio({
      width: loaderWidth,
      ratioType: ratio,
    })

    if (height && width) {
      return (
        buildedImage
          .width(width)
          .height(height)
          .quality(quality || 75)
          .auto('format')
          .url() || ''
      )
    }

    if (width) {
      return (
        buildedImage
          .width(width)
          .quality(quality || 75)
          .auto('format')
          .url() || ''
      )
    }

    return (
      buildedImage
        .quality(quality || 75)
        .auto('format')
        .url() || ''
    )
  }

  const [width, height] = getMediaSizeByRatio({
    width: image.width,
    crop: image.crop,
    ratioType: ratio,
    height: image.height,
  })

  const url = (() => {
    try {
      return buildedImage.url()
    } catch {
      return undefined
    }
  })()

  if (!url) {
    return null
  }

  return (
    <div className={className}>
      <Image
        alt={image.alt ?? ''}
        objectFit={objectFit}
        priority={priority}
        src={url}
        sizes={sizes}
        layout={layout}
        width={width}
        height={height}
        loader={loader}
        unoptimized={image.unoptimized}
      />
    </div>
  )
}
