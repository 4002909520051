import React from 'react'

export const SearchIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.05 18.04L15.04 14.03C16.16 12.65 16.77 11.01 16.77 9.38C16.77 5.31 13.45 2 9.38 2C5.31 2 2 5.31 2 9.38C2 13.45 5.31 16.76 9.38 16.76C11.13 16.76 12.73 16.17 14.03 15.04L18 19.01C18.09 19.1 18.29 19.17 18.45 19.17C18.84 19.17 19.16 18.85 19.16 18.46C19.16 18.3 19.11 18.12 19.03 18.04H19.05ZM9.39 15.33C6.16 15.33 3.44 12.61 3.44 9.38C3.44 6.15 6.16 3.43 9.38 3.43C12.6 3.43 15.33 6.16 15.33 9.38C15.33 12.6 12.61 15.33 9.38 15.33H9.39Z"
        fill="currentColor"
      />
    </svg>
  )
}
