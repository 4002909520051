import { LocaleId, RegionId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'

export const createProductPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  return groq`{
  "pageBuilder": [
      {
        "_type": "sectionProductConversionArea",
        "_key": "sectionProductConversionArea",
      },
      ...${PAGE_BUILDER_PROJECTION},
    ]
  }`
}
