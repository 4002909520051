import { styled } from '@sans-souci/styles'
import { FC } from 'react'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { MediaFieldType } from '@sans-souci/models'
const ContentWrap = styled('div', {
  display: 'grid',
  gridRowGap: '$20',
  justifyItems: 'start',
  $lin: {
    paddingTop: 'S',
    paddingLeft: 'S',
    paddingRight: 'S',
  },
})

const Subtitle = styled('div', {
  $projectFont: 'body02',
})

const Title = styled('h3', {
  $projectFont: 'heading03',
})

const FullLink = styled(Link, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})
const Description = styled('p', {
  $projectFont: 'body01',
  '@md': {
    maxWidth: '$maxWidthXXS',
  },
})

const ImageLinkWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  background: '$gray100',
})

const MediaWrap = styled('div', {
  display: 'grid',
})

const DefaultMediaWrap = styled('div', {
  display: 'grid',
  gridArea: '1/1',
})

const HoverMediaWrap = styled('div', {
  display: 'none',
  opacity: 0,
  '@lg': {
    display: 'grid',
    gridArea: '1/1',
    transition: 'opacity 300ms',
    pointerEvents: 'none',
  },
})
const Root = styled('div', {
  position: 'relative',
  [`&:hover ${HoverMediaWrap}`]: {
    opacity: 1,
  },
})

type GenericCardProps = {
  title?: string
  media?: MediaFieldType
  hoverMedia?: MediaFieldType
  path?: string
  description?: string
  subtitle?: string
  sizes?: string
  priority?: boolean
  label?: string
}

const CTA = styled(Link, {
  zIndex: 1,
})
export const GenericCard: FC<GenericCardProps> = ({
  media,
  title,
  path,
  subtitle,
  description,
  sizes,
  hoverMedia,
  priority = false,
  label,
}) => {
  return (
    <Root>
      <ImageLinkWrap>
        <MediaWrap>
          {media && (
            <DefaultMediaWrap>
              <Media
                {...media}
                priority={priority}
                sizes={sizes}
                ratio={'portrait1'}
              />
            </DefaultMediaWrap>
          )}
          {hoverMedia && (
            <HoverMediaWrap>
              <Media {...hoverMedia} sizes={sizes} ratio={'portrait1'} />
            </HoverMediaWrap>
          )}
        </MediaWrap>
      </ImageLinkWrap>
      <ContentWrap>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
        {path && (
          <CTA href={path} appearance={'ButtonText'} tabIndex={-1}>
            {label}
          </CTA>
        )}
      </ContentWrap>

      <FullLink href={path} />
    </Root>
  )
}
