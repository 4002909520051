import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import {
  createLinkProjection,
  createLinkRawProjection,
} from '../projections/createLinkProjection'

export const createSectionProductGridProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  const LINK_RAW_PROJECTION = createLinkRawProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('description', localeId)},
        cta${LINK_PROJECTION},
        products[]->{
            ${coalesceLocaleField('label', localeId)},
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
            media${MEDIA_PROJECTION},
            link${LINK_RAW_PROJECTION}
          },
      }
  `
}
