import { FC } from 'react'
import { useForm } from 'local-react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'
import {
  validateRequired,
  useFormSubmitState,
  SubmitButton,
  FormTextField,
  validateMatchesPassword,
  FormResponseMessage,
  ActionItemsContainer,
} from '@sans-souci/forms'

import { createCustomerClient } from '../customerService'
import { useCustomerContext } from '../customerContext'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { Form } from '../components/Form'
import { ShopifyCustomerUserErrorsFieldsFragment } from '@sans-souci/shopify-sdk'
import { LoginRegisterFormWrapper } from '../components/LoginRegisterFormWrapper'
import { ROUTE_BASE_ACCOUNT } from '@sans-souci/configs'

interface ResetPasswordFormProps {
  className?: string
  shouldLoginOnSuccess?: boolean
}

type FormValues = {
  password?: string
  confirmPassword?: string
}

const defaultValues: FormValues = {
  password: '',
  confirmPassword: '',
}

const toStringParam = (param?: string | string[]) =>
  Array.isArray(param) ? param[0] : param

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  className,
  shouldLoginOnSuccess = true,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
    watch,
  } = form

  const { locale, query } = useRouter()
  const router = useRouter()
  const resetUrl = toStringParam(query['resetUrl'])

  const onSubmitSuccess = () => {
    router.push(ROUTE_BASE_ACCOUNT)
  }

  const customerClient = createCustomerClient({ locale })
  const { setToken } = useCustomerContext()

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({ form, defaultValues })

  const handleResponseErrors = (
    errors: ShopifyCustomerUserErrorsFieldsFragment[],
  ) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()
      const { password = '' } = data
      const response = await customerClient.reset({
        resetUrl,
        password,
      })

      const errors = response?.customerResetByUrl?.customerUserErrors

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      const customer = response?.customerResetByUrl?.customer
      if (!customer) {
        return setError()
      }

      if (shouldLoginOnSuccess) {
        const email = response?.customerResetByUrl?.customer?.email

        if (!email) throw new Error()

        const loginResponse = await customerClient.createToken({
          email,
          password,
        })

        const loginErrors =
          loginResponse?.customerAccessTokenCreate?.customerUserErrors || []

        if (loginErrors.length) {
          return handleResponseErrors(loginErrors)
        }

        const token =
          loginResponse?.customerAccessTokenCreate?.customerAccessToken
            ?.accessToken
        if (!token) {
          return setError()
        }

        setToken(token)
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <LoginRegisterFormWrapper>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={className}
        name="reset-password-form"
      >
        <FormTextField
          control={control}
          rules={{
            deps: ['password', 'confirmPassword'],
            ...validateRequired(t),
          }}
          autoComplete="new-password"
          type="password"
          name="password"
          label={t('resetPasswordForm.passwordFieldLabel')}
        />
        <FormTextField
          control={control}
          rules={{
            deps: ['password', 'confirmPassword'],
            ...validateRequired(t),
            ...validateMatchesPassword(t, watch('password')),
          }}
          autoComplete="new-password"
          type="password"
          name="confirmPassword"
          label={t('resetPasswordForm.confirmPasswordFieldLabel')}
        />
        <ActionItemsContainer>
          <SubmitButton
            text={t('resetPasswordForm.submitButtonLabel')}
            helpText={submitMessage}
            invalid={invalid}
            success={success}
            loading={isSubmitting}
          />
        </ActionItemsContainer>
        {submitMessage && (
          <FormResponseMessage
            invalid={invalid}
            success={success}
            message={submitMessage}
          />
        )}
      </Form>
    </LoginRegisterFormWrapper>
  )
}
