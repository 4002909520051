import { FC } from 'react'
import { SanityCollectionType } from '@sans-souci/models'
import { GenericCard } from '../GenericCard/GenericCard'

type SanityCollectionCardProps = SanityCollectionType & {
  priority?: boolean
  sizes?: string
}
export const SanityCollectionCard: FC<SanityCollectionCardProps> = (props) => {
  return <GenericCard {...props} label={'Discover the collection'} />
}
