import { FC } from 'react'
import { PriceRange } from './PriceRange'
import { PricePrimitive } from './PricePrimitve'
import { styled } from '@sans-souci/styles'
import { ShopifyProductPriceRangeFieldsFragment } from '@sans-souci/shopify-sdk'

const Root = styled('div', {
  color: '$black',
  s: {
    color: '$gray300',
  },
  '.prefix': {},
  '.primitive': { alignSelf: 'start' },
})

export const ProductPrice: FC<{
  priceRange?: ShopifyProductPriceRangeFieldsFragment
  compareAtPriceRange?: ShopifyProductPriceRangeFieldsFragment
}> = ({ priceRange, compareAtPriceRange }) => {
  if (!priceRange) return null

  const shouldShowCompareAtPriceRange =
    compareAtPriceRange &&
    priceRange.minVariantPrice.amount === priceRange.maxVariantPrice.amount &&
    compareAtPriceRange.minVariantPrice.amount ===
      compareAtPriceRange.maxVariantPrice.amount &&
    parseFloat(compareAtPriceRange.minVariantPrice.amount) !== 0

  return (
    <Root>
      <PriceRange range={priceRange} />
      {shouldShowCompareAtPriceRange && (
        <>
          {' '}
          <s>
            <PricePrimitive price={compareAtPriceRange.minVariantPrice} />
          </s>
        </>
      )}
    </Root>
  )
}
