import { SiteConfiguration } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { FC } from 'react'
import { Link } from '@sans-souci/components'
import { NewsletterForm } from '@sans-souci/newsletter'

type Props = {
  footer?: SiteConfiguration['footer']
  newsletter?: SiteConfiguration['newsletter']
  darkTheme?: boolean
}

const Root = styled('footer', {
  display: 'block',

  variants: {
    darkTheme: {
      true: {
        background: '$black',
        color: '$beige',
      },
      false: {},
    },
  },
})

const Wrap = styled('div', {
  display: 'grid',
  gridGap: '$72',

  paddingTop: '$96',
  paddingBottom: '$72',

  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },

  '@md': {
    gridGap: '$2',
    gridTemplateColumns: 'repeat(12, 1fr)',

    paddingBottom: '$32',
  },
})

const NewsletterWrap = styled('div', {
  '@md': {
    gridColumn: '1 / span 6',
  },
})

const NavGroups = styled('div', {
  display: 'grid',
  alignItems: 'start',
  gridGap: '$32',
  alignSelf: 'end',

  '@md': {
    gridColumn: '11 / span 2',
  },
})

const NavGroup = styled('ol', {
  display: 'grid',
  gridGap: '$8',
})

const NavItem = styled('li', {
  display: 'grid',
  $projectFont: 'dev',
  textTransform: 'uppercase',
})

export const Footer: FC<Props> = ({
  footer,
  newsletter,
  darkTheme = false,
}) => {
  const linkGroups = footer?.linkGroups || []

  if (!footer) {
    return null
  }
  return (
    <Root darkTheme={darkTheme}>
      <Wrap>
        {newsletter && (
          <NewsletterWrap>
            <NewsletterForm
              heading={newsletter.title}
              disclaimer={newsletter.disclaimer}
              darkTheme={darkTheme}
            />
          </NewsletterWrap>
        )}
        {linkGroups && (
          <NavGroups>
            {linkGroups.map((linkGroup) => {
              return (
                linkGroup?.title && (
                  <NavGroup key={linkGroup.title}>
                    {linkGroup?.links?.map((link) => {
                      return (
                        link.title && (
                          <NavItem key={link.title}>
                            <Link
                              key={link.title}
                              {...link?.payload}
                              appearance={'ButtonText'}
                            >
                              {link.title}
                            </Link>
                          </NavItem>
                        )
                      )
                    })}
                  </NavGroup>
                )
              )
            })}
          </NavGroups>
        )}
      </Wrap>
    </Root>
  )
}
