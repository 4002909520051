import { FC, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { Button } from '@sans-souci/components'
import { useCustomerContext } from '../../../customerContext'
import { useTranslation } from 'react-i18next'
import { AccountCardWrapper } from '../components/AccountCardWrapper'
import { AddAddress } from './AddAddress'
import { AddressItemCard } from '../components/AddressItemCard'

const Root = styled('div', {
  display: 'grid',
  gap: '$32',
})

const NoAddressContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  $projectFont: 'dev',
})

export const AddressesList: FC = () => {
  const { customer } = useCustomerContext()
  const { t } = useTranslation('account')

  const defaultAddressId = customer?.defaultAddress?.id || ''
  const addresses = customer?.addresses

  const defaultAddress =
    addresses?.nodes?.filter((node) => node?.id === defaultAddressId)[0] || null

  const otherAddresses =
    addresses?.nodes?.filter((node) => node?.id !== defaultAddressId) || []

  const addressCollection = [
    ...(defaultAddress ? [defaultAddress] : []),
    ...otherAddresses,
  ]

  const [isAddAddressVisible, setIsAddAddressVisible] = useState(false)

  return (
    <Root>
      {addressCollection.length === 0 ? (
        <AccountCardWrapper>
          <NoAddressContainer>
            {t('accountPage.addressListView.noAddressesText')}
          </NoAddressContainer>
        </AccountCardWrapper>
      ) : (
        addressCollection.map((node, index) => (
          <AddressItemCard
            key={`addressCard-${index}`}
            node={node}
            defaultAddressId={defaultAddressId}
            isDeletingPossible={addressCollection.length !== 1}
          />
        ))
      )}
      {isAddAddressVisible ? (
        <AddAddress onClick={setIsAddAddressVisible} />
      ) : (
        <Button
          appearance={'ButtonPrimaryWhite'}
          onClick={() => setIsAddAddressVisible(true)}
        >
          {t('accountPage.addressListView.addButtonLabel')}
        </Button>
      )}
    </Root>
  )
}
