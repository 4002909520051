import React, { useEffect, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { useCartContext } from '../useCart'
import { GTMViewCart } from '@sans-souci/services/gtm-service'
import { CartLine } from '../components/CartLine'
import { useTranslation } from 'react-i18next'
import { CheckoutButton } from '../components/CheckoutButton'
import { ProductVariantPrice } from '@sans-souci/components'

const Root = styled('div', {
  display: 'grid',
  paddingTop: '$240',

  background: '$black',
  color: '$beige',

  '@md': {
    minHeight: '100vh',
  },
})

const Loading = styled('div', {
  $projectFont: 'body02',
  color: '$beige',
})

const CartLayout = styled('div', {
  display: 'grid',
  gap: '$20',
  borderTop: '1px solid $beige',

  '@md': {
    gridTemplateColumns: '1fr 1fr',
    gap: '0',
  },
})

const CartLinesWrapContainer = styled('div', {})

const CartLinesWrap = styled('div', {
  display: 'grid',
})

const SummaryStickyParent = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateRows: '1fr auto',

  '@md': {
    borderLeft: '1px solid $beige',
  },
})

const Summary = styled('div', {
  paddingTop: '$40',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
    paddingBottom: 'S',
  },
  '@md': {
    position: 'sticky',
    height: '60vh',
    bottom: '0',
    paddingTop: '$64',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
})

const Content = styled('div', {
  display: 'grid',
  gap: '$40',
  $projectFont: 'body01',
})

const SummaryHeading = styled('h2', {
  fontWeight: 'bold',
  textTransform: 'uppercase',
})

const SummaryInfoWrap = styled('div', {
  display: 'grid',
  textTransform: 'uppercase',
  borderTop: '1px solid $beige',
})
const SummaryInfo = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '$12 0',
  borderBottom: '1px solid $beige',

  '& > div': {
    justifySelf: 'end',
  },
})

const LineItemsList = styled('ul', {
  display: 'grid',
  listStyleType: 'none',
})

const InfoText = styled('p', {
  maxWidth: '$416',
})

const EmptyCartMsg = styled('h2', {
  $projectFont: 'heading02',
  padding: '$40 0',
  textAlign: 'center',
  gridColumn: '1/3',
  borderBottom: '1px solid $beige',
})

const CheckoutButtonWrap = styled('div', {
  alignSelf: 'end',
  marginTop: '$40',
})

export const FullCart = () => {
  const { cart, updateCartLines } = useCartContext()
  const { t } = useTranslation('cart')
  const [beforeFirstCartLoad, setBeforeFirstCartLoad] = useState(true)
  const cartReady = Boolean(cart)
  const isCartEmpty = cart?.totalQuantity === 0

  useEffect(() => {
    if (!cart) {
      return
    }
    if (beforeFirstCartLoad) {
      setBeforeFirstCartLoad(false)
    }
    GTMViewCart(
      cart.cost.subtotalAmount.amount,
      cart.cost.subtotalAmount.currencyCode,
      cart.lines,
    )
  }, [cart])

  if (!cart) return null

  return (
    <Root>
      <CartLayout>
        {isCartEmpty && <EmptyCartMsg>{t('emptyCartMessage')}</EmptyCartMsg>}

        <CartLinesWrapContainer>
          <CartLinesWrap>
            {!cartReady && <Loading>{t('loadingMessage')}</Loading>}
            {cartReady && !isCartEmpty && (
              <LineItemsList>
                {cart?.lines?.map((line) => (
                  <li key={line.id}>
                    <CartLine line={line} updateCartLines={updateCartLines} />
                  </li>
                ))}
              </LineItemsList>
            )}
          </CartLinesWrap>
        </CartLinesWrapContainer>

        {!isCartEmpty && (
          <SummaryStickyParent>
            <div></div>
            <Summary>
              <Content>
                <SummaryHeading>{t('orderSummary')}</SummaryHeading>
                <SummaryInfoWrap>
                  <SummaryInfo>
                    <span>{t('subtotal')}</span>
                    <div>
                      <ProductVariantPrice price={cart?.cost.totalAmount} />
                    </div>
                  </SummaryInfo>
                  <SummaryInfo>
                    <span>{t('shipping')}</span>

                    <div>{t('shippingInfo')}</div>
                  </SummaryInfo>
                </SummaryInfoWrap>

                <InfoText>{t('infoText')}</InfoText>
              </Content>
              <CheckoutButtonWrap>
                <CheckoutButton />
              </CheckoutButtonWrap>
            </Summary>
          </SummaryStickyParent>
        )}
      </CartLayout>
    </Root>
  )
}
