import { FC } from 'react'
import dynamic from 'next/dynamic'

export const NotificationManager: FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  if (isActive) {
    const DynamicToastContainer = dynamic(
      () => import('react-toastify').then((mod) => mod.ToastContainer),
      { ssr: false },
    )
    return <DynamicToastContainer />
  }
  return null
}
