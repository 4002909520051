import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionHeroSliderProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
         slides[] {
            media${MEDIA_PROJECTION},
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
            cta${LINK_PROJECTION},
            mode
         },
      }
  `
}
