import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { useCustomerContext } from '../../../customerContext'
import { usePageContext } from '../pageContext'
import { formatDate } from '../../../helpers/formatDate'
import { Link, ProductVariantPrice } from '@sans-souci/components'
import { AddressItem } from '../components/AddressItem'
import { useTranslation } from 'react-i18next'
import { BackButton } from '../../../components/BackButton'
import { AccountCardWrapper } from '../components/AccountCardWrapper'
import { AccountCartLine } from '../components/AccountCartLine'
const Root = styled('div', {
  $projectFont: 'dev',
  color: '$black',
  display: 'grid',
  gap: '$32',
})

const Header = styled('div', {
  display: 'grid',
  gap: '$8',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@lg': {
    gridTemplateColumns: 'auto auto',
  },
})

const Heading = styled('h2', {
  $projectFont: 'heading02',
})

const SubHeading = styled('h3', {
  $projectFont: 'heading03',
})

const OrderDetails = styled('div', {
  display: 'grid',
  gap: '$16',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    gap: '$32',
  },
})

const OrderDetailsHeading = styled('h3', {
  $projectFont: 'dev',
  borderBottom: '1px solid $gray300',
  height: 'fit-content',
})

const AddressContainer = styled('div', {
  display: 'grid',
  gap: '$16',
})

const LineItemsList = styled('ul', {
  display: 'grid',
  gridRowGap: '$36',
  listStyleType: 'none',
  li: {
    listStyleType: 'none',
  },
})

const FooterWrap = styled('div', {
  borderTop: '1px solid $gray300',
  paddingTop: '$20',
  display: 'grid',
  alignContent: 'start',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

const PricesWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  $projectFont: 'heading03',
  '@lg': {
    gridColumn: '2',
  },
})

const PriceWrap = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    hasTopBorder: {
      true: {
        paddingTop: '$8',
        borderTop: '1px solid $gray300',
      },
    },
  },
})

const PriceText = styled('h3', {})

const StyledLink = styled(Link, {
  textDecoration: 'underline',
  textTransform: 'capitalize',
})

export const ViewOrder: FC = () => {
  const { customer } = useCustomerContext()
  const { id, setActiveView } = usePageContext()
  const { t } = useTranslation('account')

  const order = customer?.orders?.nodes?.find((node) => node.id === id)

  if (!order) {
    return null
  }

  const {
    name,
    processedAt,
    shippingAddress,
    statusUrl,
    lineItems,
    currentTotalPrice,
    subtotalPriceV2,
    totalTaxV2,
  } = order

  const date = processedAt ? formatDate(processedAt) : null

  return (
    <Root>
      <BackButton onClick={() => setActiveView('orders-list')}>
        {t('accountPage.general.backToOrdersListButtonLabel')}
      </BackButton>
      <AccountCardWrapper>
        <Header>
          <Heading>
            {t('accountPage.orderView.heading', { value: name })}
          </Heading>
          <SubHeading>{date}</SubHeading>
        </Header>
        <OrderDetails>
          <AddressContainer>
            <OrderDetailsHeading>
              {t('accountPage.orderView.shippingAddress')}
            </OrderDetailsHeading>
            {shippingAddress && (
              <AddressItem
                address={shippingAddress}
                title={t('accountPage.orderView.shippingAddress')}
              />
            )}
          </AddressContainer>
          <AddressContainer>
            <OrderDetailsHeading>
              {t('accountPage.orderView.billingAddress')}
            </OrderDetailsHeading>
            {/*{billingAddress && (*/}
            {/*  <AddressItem*/}
            {/*    address={billingAddress}*/}
            {/*    title={t('accountPage.orderView.billingAddress')}*/}
            {/*  />*/}
            {/*)}*/}
          </AddressContainer>
          <StyledLink appearance={'ButtonText'} href={statusUrl}>
            {t('accountPage.orderView.trackOrderButtonLabel')}
          </StyledLink>
        </OrderDetails>
        <LineItemsList>
          {lineItems.nodes.map(
            (line, i) =>
              line && (
                <li key={line.variant?.id ?? 'empty' + i}>
                  {<AccountCartLine line={line} isEditable={false} />}
                </li>
              ),
          )}
        </LineItemsList>
        <FooterWrap>
          <PricesWrap>
            <PriceWrap>
              <PriceText>
                {t('accountPage.orderView.subtotalPriceLabel')}
              </PriceText>
              {subtotalPriceV2 && (
                <ProductVariantPrice price={subtotalPriceV2} />
              )}
            </PriceWrap>
            <PriceWrap>
              <PriceText>
                {t('accountPage.orderView.taxesPriceLabel')}
              </PriceText>
              {totalTaxV2 && <ProductVariantPrice price={totalTaxV2} />}
            </PriceWrap>
            <PriceWrap hasTopBorder={true}>
              <PriceText>
                {t('accountPage.orderView.totalPriceLabel')}
              </PriceText>
              {currentTotalPrice && (
                <ProductVariantPrice price={currentTotalPrice} />
              )}
            </PriceWrap>
          </PricesWrap>
        </FooterWrap>
      </AccountCardWrapper>
    </Root>
  )
}
