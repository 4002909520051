import { FC } from 'react'
import { Media } from '../Media/Media'
import { SanityProductType } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { Link } from '../Link/Link'
import { useTranslation } from 'react-i18next'

const ContentWrap = styled('div', {
  display: 'grid',
  gridRowGap: '$20',
  justifyItems: 'start',

  variants: {
    variant: {
      gridCard: {
        $lin: {
          padding: 'S',
        },
        paddingTop: '$20',
      },
      sliderCard: {
        $lin: {
          paddingTop: 'S',
          paddingBottom: 'S',
        },
        paddingRight: '$8',

        '@lg': {
          $lin: {
            padding: 'S',
          },
        },
      },
    },
  },
})

const Label = styled('div', {
  $projectFont: 'body02',
})

const Title = styled('h3', {
  $projectFont: 'heading03',
})

const StyledLink = styled(Link, {
  '&:hover': {
    textDecoration: 'underline',
  },
})

const Description = styled('p', {
  $projectFont: 'body01',
  '@md': {
    maxWidth: '$maxWidthXXS',
  },
})

type SanityProductCardProps = SanityProductType & {
  priority?: boolean
  variant?: 'gridCard' | 'sliderCard'
  sizes?: string
}

export const SanityProductCard: FC<SanityProductCardProps> = ({
  media,
  label,
  title,
  description,
  priority,
  variant = 'gridCard',
  sizes,
  link,
}) => {
  const { t } = useTranslation('sanityProductCard')
  return (
    <div>
      {media && (
        <Media
          {...media}
          priority={priority}
          sizes={sizes}
          ratio={'portrait2'}
        />
      )}
      <ContentWrap variant={variant}>
        {label && <Label>{label}</Label>}
        {title && (
          <Title as={variant === 'gridCard' ? 'h2' : 'h3'}>
            {link ? <StyledLink {...link.payload}>{title}</StyledLink> : title}
          </Title>
        )}
        {description && <Description>{description}</Description>}
        {link && (
          <Link {...link.payload} appearance={'ButtonText'}>
            {t('linkLabel')}
          </Link>
        )}
      </ContentWrap>
    </div>
  )
}
