import { useCustomerContext } from '@sans-souci/account'
import { useRouter } from 'next/router'
import { useCartContext } from './useCart'
import { getMultipass } from '@sans-souci/services/api-service'
import { useState } from 'react'
import { captureException } from '@sentry/nextjs'

type ReturnType = {
  handleCheckout: () => void
  isCheckoutLoading: boolean
}

export const useCheckout = (): ReturnType => {
  const { locale } = useRouter()
  const { isLoggedIn, isVerified } = useCustomerContext()
  const { cart } = useCartContext()
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)
  const checkoutUrl = cart?.checkoutUrl
  const isLoggedInUser = isLoggedIn && isVerified

  const handleCheckout = async () => {
    try {
      setIsCheckoutLoading(true)
      if (isLoggedInUser && locale && checkoutUrl) {
        const { url } = await getMultipass({ locale, returnTo: checkoutUrl })
        window.location.href = url
        return
      }

      if (!isLoggedInUser && checkoutUrl) {
        window.location.href = checkoutUrl
        return
      }
      setIsCheckoutLoading(false)

      throw new Error('[handleCheckout] missing cart checkoutUrl')
    } catch (e) {
      captureException(e)
    }
  }

  return {
    handleCheckout,
    isCheckoutLoading,
  }
}
