import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createPortableTextProjection } from './projections/createPortableTextProjection'
import { DevSamplesData } from '@sans-souci/models'
import { createMediaProjection } from './projections/createMediaProjection'
import { createLinkProjection } from './projections/createLinkProjection'

export const getDevSamples = async (
  localeRegionString: string,
): Promise<DevSamplesData> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  const devSamplesQuery = groq`
    *[_type == "devSamples"]{
      richTextTest,
      ${coalesceLocaleField(
        'richTextTest',
        localeId,
      )}[]${PORTABLE_TEXT_PROJECTION},
      imageSampleField${MEDIA_PROJECTION},
      videoSampleField${MEDIA_PROJECTION},
      ctaLinkTest${LINK_PROJECTION}
    }[0]
  `

  const devSamplesData = await getClient(false).fetch(devSamplesQuery)
  return devSamplesData
}
