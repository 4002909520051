import { createShopifyClient } from './createShopifyClient'
import {
  ShopifyCountryCode,
  ShopifyMutationCartLinesAddArgs,
  createShopifySdk,
  ShopifyMutationCartLinesUpdateArgs,
  formatShopifyCart,
  ShopifyResolvedCart,
} from '@sans-souci/shopify-sdk'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
type CartClientRes = Promise<ShopifyResolvedCart>

export type CartClient = (props: { cartId: string | null; lang?: string }) => {
  create: (email?: string) => CartClientRes
  updateNote: (note: string) => CartClientRes
  addLines: (lines: ShopifyMutationCartLinesAddArgs['lines']) => CartClientRes
  updateLines: (
    lines: ShopifyMutationCartLinesUpdateArgs['lines'],
  ) => CartClientRes
  removeLines: (lineIds: string[]) => CartClientRes
  fetch: (cartId: string) => CartClientRes
  // updateBuyer: (buyerIdentity: {
  //   countryCode: string
  //   customerAccessToken?: string
  //   email?: string
  // }) => CartClientRes
}

export const createCartClient: CartClient = ({ lang, cartId }) => {
  const [countryCode, language] = getLocaleRegionIdFromPath(lang) as [
    ShopifyCountryCode,
    string,
  ]
  const client = createShopifyClient(language)
  const sdk = createShopifySdk(client)

  return {
    create: (email) =>
      sdk
        .cartCreate({ cartInput: { buyerIdentity: { countryCode, email } } })
        .then((res) => formatShopifyCart(res.data.cartCreate?.cart)),
    updateNote: (note) => {
      if (!cartId) {
        throw new Error('[createCartClient] updateNote called without cartId')
      }
      return sdk
        .cartNoteUpdate({
          cartId,
          note,
          countryCode,
        })
        .then((res) => formatShopifyCart(res.data.cartNoteUpdate?.cart))
    },
    addLines: (lines) => {
      if (!cartId) {
        throw new Error('[createCartClient] addLines called without cartId')
      }
      return sdk
        .cartLinesAdd({ cartId, lines, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesAdd?.cart))
    },
    updateLines: (lines) => {
      if (!cartId) {
        throw new Error('[createCartClient] updateLines called without cartId')
      }
      return sdk
        .cartLinesUpdate({ cartId, lines, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesUpdate?.cart))
    },
    removeLines: (lineIds) => {
      if (!cartId) {
        throw new Error('[createCartClient] removeLines called without cartId')
      }
      return sdk
        .cartLinesRemove({ cartId, lineIds, countryCode })
        .then((res) => formatShopifyCart(res.data.cartLinesRemove?.cart))
    },
    fetch: (specificCartId) =>
      sdk
        .getCart({
          cartId: specificCartId,
          countryCode,
        })
        .then((res) => formatShopifyCart(res.data.cart)),
    // updateBuyer: (buyerIdentity) => {
    //   if(!cartId) return undefined
    //   return sdk
    //     .cartBuyerIdentityUpdate({
    //       cartId,
    //       buyerIdentity,
    //       countryCode,
    //     })
    //     .then((res) =>
    //       formatShopifyCart(res.data.cartBuyerIdentityUpdate?.cart),
    //     )
    // },
  }
}
