export const focus = {
  default: {
    '&:focus-visible': {
      outline: '$colors$blue solid 3px;',
      outlineOffset: '1px',
    },
  },
}

export type FocusType = keyof typeof focus

export const getFocusStyles = (focusType: FocusType) => focus[focusType]
