export type SubscribtionData = {
  email: string
  firstName: string
  lastName: string
}

export const newsletterSubscribe = async (profile: SubscribtionData) => {
  return await fetch('/api/klaviyo/form/newsletterForm', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profile),
  })
}

export type SubmissionsData = {
  email: string
  firstName: string
  lastName: string
  company: string
}

export const submissionsSubscribe = async (profile: SubmissionsData) => {
  return await fetch('/api/klaviyo/form/submissionsForm', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profile),
  })
}

type ContactFormModel = {
  email: string
  firstName: string
  lastName: string
  message: string
  formId: string
}

export type ContactFormEvent = {
  type: 'contactForm'
  payload: ContactFormModel
}

export type KlaviyoEvent = ContactFormEvent

export async function sendEvent(event: KlaviyoEvent) {
  return await fetch(`/api/klaviyo/event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(event),
  })
}
