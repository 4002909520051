import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { ShopifyMoneyFieldsFragment } from '../dist/generatedSchemaSdk'

export const getI18nPrice = (
  price: ShopifyMoneyFieldsFragment,
  locale?: string,
): string => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  return new Intl.NumberFormat(`${localeId}-${regionId}`, {
    style: 'currency',
    currency: price.currencyCode,
  }).format(Number(price.amount))
}
