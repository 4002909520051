export const ratios = {
  square: 1,
  landscape1: 0.56,
  portrait1: 1.17,
  portrait2: 1.24,
  portrait3: 1.44,
}
export type ImageSizes =
  | '420'
  | '768'
  | '1024'
  | '1400'
  | '1600'
  | '1920'
  | '2560'

export type MediaRatiosType = keyof typeof ratios

export type MediaRatiosTypeExtended =
  | MediaRatiosType
  | Record<ImageSizes, keyof typeof ratios>
