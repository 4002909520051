import { ShopifyImageFieldsFragment } from '../dist/generatedSchemaSdk'

export const formatShopifyImage = (
  input: ShopifyImageFieldsFragment,
): {
  type: 'shopify-image'
  image: {
    height: number | null
    width: number | null
    src: string
    alt: string | null
  }
} => {
  return {
    type: 'shopify-image',
    image: {
      height: input.height ?? null,
      width: input.width ?? null,
      src: input.url,
      alt: input.altText ?? null,
    },
  }
}

export const formatShopifyImages = (images: ShopifyImageFieldsFragment[]) =>
  images.map(formatShopifyImage)

export type ShopifyResolvedImage = ReturnType<typeof formatShopifyImage>
export type ShopifyResolvedImages = ShopifyResolvedImage[]
