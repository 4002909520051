import { ShopifyCartFieldsFragment } from '../dist/generatedSchemaSdk'
import { formatShopifyCartLine } from './formatShopifyCartLine'

export const BUNDLE_ID_KEY = '_bundleId'
export const BUNDLE_ID_PREFIX_MAIN = 'main_'
export const BUNDLE_ID_PREFIX_ADDITIONAL = 'additional_'

const calculateLinesQuantity = (
  lines: ShopifyCartFieldsFragment['lines']['nodes'],
): number => {
  return (
    lines?.reduce((acc, line) => {
      if (
        line.attributes
          ?.find((a) => a.key === BUNDLE_ID_KEY)
          ?.value?.startsWith(BUNDLE_ID_PREFIX_ADDITIONAL)
      )
        return acc
      return acc + line.quantity
    }, 0) ?? 0
  )
}

export const formatShopifyCart = (
  cart: ShopifyCartFieldsFragment | undefined | null,
) =>
  cart
    ? {
        ...cart,
        lines: cart.lines.nodes.map(formatShopifyCartLine),
        totalQuantity: calculateLinesQuantity(cart.lines.nodes),
      }
    : null

export type ShopifyResolvedCart = ReturnType<typeof formatShopifyCart>
