import { LocaleId, RegionId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSEOProjection } from './createSEOProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from './createMediaProjection'

export const createSanityRichProductListingPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
      ...,
      "pageBuilder": [
        {
          "_type": "sectionProductListing",
          "_key": "sectionProductListing",
          "products": *[ !(_id in path("drafts.**")) && defined(path.current) && _type == "sanityRichProduct"] |order(orderRank) {
            _id,
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
            ${coalesceLocaleField('subtitle', localeId)},
            "path": path.current,
             releaseDate,
            "params": {
             "type": type -> {
                ${coalesceLocaleField('title', localeId)},
              }.title,
             "family": family -> {
                ${coalesceLocaleField('title', localeId)},
              }.title,
            },
            media${MEDIA_PROJECTION},
            hoverMedia${MEDIA_PROJECTION},
          }
        },
        ...${PAGE_BUILDER_PROJECTION}.pageBuilder,
      ],
      "background": "light",
      "headerColor": "dark",
      "breadcrumbs": [
        {
          "title": "Products"
        }
      ],
      seo${createSEOProjection(localeId)},
    }`
}
