import { formatShopifyImage } from './formatShopifyImage'
import { ShopifyVariantBasicFieldsFragment } from '../dist/generatedSchemaSdk'

export const formatShopifyVariantBasic = (
  variant: ShopifyVariantBasicFieldsFragment,
) => ({
  ...variant,
  image: variant.image ? formatShopifyImage(variant.image) : null,
})

export type ShopifyResolvedVariantBasic = ReturnType<
  typeof formatShopifyVariantBasic
>
