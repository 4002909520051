import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createPortableTextProjection } from '../projections/createPortableTextProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionCoverSliderProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
    _key,
    _type,
    slides[] {
      media${MEDIA_PROJECTION},
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
      cta${LINK_PROJECTION},
      mode
    },
  }
  `
}
