import { FC } from 'react'
import { useForm } from 'local-react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClientError } from 'graphql-request'
import { useRouter } from 'next/router'
import {
  validateRequired,
  useFormSubmitState,
  validateEmail,
  SubmitButton,
  FormTextField,
  FormResponseMessage,
  ActionItemsContainer,
} from '@sans-souci/forms'

import { createCustomerClient } from '../customerService'
import { formatResponseErrors } from '../helpers/formatResponseErrors'
import { Form } from '../components/Form'
import { ShopifyCustomerUserErrorsFieldsFragment } from '@sans-souci/shopify-sdk'
import { LoginRegisterFormWrapper } from '../components/LoginRegisterFormWrapper'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { ROUTE_ACCOUNT_LOGIN } from '@sans-souci/configs'
import { Link } from '@sans-souci/components'
import { styled } from '@sans-souci/styles'

interface RecoverPasswordFormProps {
  className?: string
  onSubmitSuccess?: () => void
}

type FormValues = {
  email: string
}

const defaultValues: FormValues = {
  email: '',
}

const StyledLink = styled(Link, {
  display: 'flex',
  justifyContent: 'center',
  gap: '$4',
})

export const RecoverPasswordForm: FC<RecoverPasswordFormProps> = ({
  className,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
  } = form

  const { locale } = useRouter()

  const customerClient = createCustomerClient({ locale })

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({ form, defaultValues })

  const handleResponseErrors = (
    errors: ShopifyCustomerUserErrorsFieldsFragment[],
  ) => {
    const { fieldErrors, generalError } =
      formatResponseErrors<FormValues>(errors)
    fieldErrors.forEach((e) => setFieldError(e.fieldName, e.message))
    return setError(generalError)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      clearErrors()

      const response = await customerClient.recover(data)
      const errors = response?.customerRecover?.customerUserErrors

      if (errors?.length) {
        return handleResponseErrors(errors)
      }

      setSuccess()
      return onSubmitSuccess && onSubmitSuccess()
    } catch (e: unknown) {
      if (e instanceof ClientError) {
        return setError(e.response.errors?.[0].message)
      }
      return setError()
    }
  }

  return (
    <LoginRegisterFormWrapper>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={className}
        name="recover-password-form"
      >
        <FormTextField
          control={control}
          rules={{ ...validateRequired(t), ...validateEmail(t) }}
          autoComplete="email"
          type="email"
          name="email"
          label={t('recoverPasswordForm.emailFieldLabel')}
        />
        <ActionItemsContainer>
          <SubmitButton
            text={t('recoverPasswordForm.submitButtonLabel')}
            helpText={submitMessage}
            invalid={invalid}
            success={success}
            loading={isSubmitting}
          />
        </ActionItemsContainer>
        {submitMessage && (
          <FormResponseMessage
            invalid={invalid}
            success={success}
            message={submitMessage}
          />
        )}
      </Form>
      <StyledLink appearance={'ButtonText'} href={ROUTE_ACCOUNT_LOGIN}>
        <MdKeyboardArrowLeft />
        {t('recoverPasswordForm.cancelButtonLabel')}
      </StyledLink>
    </LoginRegisterFormWrapper>
  )
}
