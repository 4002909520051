import { useState } from 'react'
import {
  CurrentItemsType,
  FilterItem,
  FiltersType,
  FilterType,
  OnFilterChange,
  OnSortChange,
  SortType,
} from './models'
import { sortItems } from './helpers/sortItems'

type UseFilters = (args: {
  items: Array<CurrentItemsType>
  attributesToFilterBy: FilterType[]
  sortingKeys?: SortType[]
  defaultSortKey?: SortType
}) => {
  currentItems: Array<CurrentItemsType>
  onFilterChange: OnFilterChange
  filters?: FiltersType
  onSortChange: OnSortChange
  activeFilterCounts: number
}

export const useFilters: UseFilters = ({
  items,
  attributesToFilterBy,
  sortingKeys,
  defaultSortKey,
}) => {
  const initialFiltersState: FiltersType = (() => {
    const initialItems = attributesToFilterBy.reduce<FiltersType['filters']>(
      (filtersAcc, currFilterType) => {
        const values: FilterItem['values'] = items.reduce<FilterItem['values']>(
          (valuesAcc, currItem) => {
            const itemParamValues = currItem.params?.[currFilterType]
            if (itemParamValues === null) return valuesAcc

            let itemParamValuesArray: string[] = []
            if (typeof itemParamValues === 'string') {
              itemParamValuesArray = [itemParamValues]
            } else if (Array.isArray(itemParamValues)) {
              itemParamValuesArray = [...itemParamValues]
            }

            const uniqueFilteredValues = itemParamValuesArray.filter(
              (value) =>
                !valuesAcc.some(
                  (existingValue) => existingValue.slug === value,
                ),
            )

            return uniqueFilteredValues.length
              ? [
                  ...valuesAcc,
                  ...uniqueFilteredValues.map((value) => ({
                    slug: value,
                    title: value,
                    isActive: false,
                  })),
                ]
              : valuesAcc
          },
          [],
        )

        return [
          ...filtersAcc,
          {
            _type: 'filter',
            slug: currFilterType,
            title: currFilterType,
            values: values,
          },
        ]
      },
      [],
    )

    const initialSortValues = (() => {
      if (!sortingKeys) return []
      return sortingKeys.map((key) => ({
        slug: key,
        title: key,
      }))
    })()

    const initialActiveSortSlug = defaultSortKey ?? 'featured'

    return {
      filters: initialItems,
      sort: {
        activeSortSlug: initialActiveSortSlug,
        values: initialSortValues,
      },
    }
  })()

  const [filters, setFilters] = useState<FiltersType>(initialFiltersState)

  const activeFilters = filters.filters
    .map((filter) => ({
      filterId: filter.slug,
      valueIds: filter.values
        .filter((value) => value.isActive)
        .map((value) => value.slug),
    }))
    .filter((filter) => filter.valueIds.length > 0)

  const activeFilterCounts = activeFilters
    .map((filter) => filter.valueIds.length)
    .reduce((acc, currentValue) => acc + currentValue, 0)

  const currentItems = (() => {
    if (!filters) return []

    const filteredItems =
      items.filter((item) =>
        activeFilters.every((arg) => {
          const param = item.params?.[arg.filterId]

          let params: string[] = []
          if (typeof param === 'string') {
            params = [param]
          } else if (Array.isArray(param)) {
            params = [...param]
          }

          if (!params) return false
          return params.some((param) => arg.valueIds.includes(param))
        }),
      ) ?? []

    const sortedItems = sortItems(filteredItems, filters.sort.activeSortSlug)

    return sortedItems ?? []
  })()

  const onSortChange = (sortSlug: SortType) => {
    setFilters((prev) => ({
      ...prev,
      sort: {
        ...prev.sort,
        activeSortSlug: sortSlug,
      },
    }))
  }

  const onFilterChange: OnFilterChange = (
    filterSlug,
    filterValueSlug,
    resetFilters = false,
  ) => {
    const _filters = resetFilters ? initialFiltersState : filters

    const newFilters = _filters.filters.map((filter) => {
      if (filter.slug !== filterSlug) return filter
      return {
        ...filter,
        values: filter.values.map((value) => {
          if (value.slug !== filterValueSlug) return value
          return {
            ...value,
            isActive: !value.isActive,
          }
        }),
      }
    })

    setFilters((prev) => ({
      ...prev,
      filters: newFilters,
    }))
  }

  return {
    currentItems,
    onFilterChange,
    filters,
    onSortChange,
    activeFilterCounts,
  }
}
