import {
  Button,
  Link,
  Media,
  ProductVariantPrice,
} from '@sans-souci/components'
import { ROUTE_BASE_PRODUCTS } from '@sans-souci/configs'
import React, { FC, useState, useMemo } from 'react'
import { styled } from '@sans-souci/styles'
import { ShopifyResolvedCartLine } from '@sans-souci/shopify-sdk'
import {
  GTMAddToCart,
  GTMRemoveFromCart,
} from '@sans-souci/services/gtm-service'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'

const Root = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'min-content auto',
  gap: '$20',
  $projectFont: 'body02',
  borderBottom: '1px solid $beige',

  $lin: {
    padding: 'S',
  },
})

const MediaWrap = styled('div', {
  height: '$168',
  width: '$120',

  '&:focus-within': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
})

const ContentWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridAutoRows: 'min-content',
  gap: '$12',
  $projectFont: 'mono01',
})

const TitleLink = styled(Link, {
  $projectFont: 'heading03',
})

const QuantityWrap = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$8',
  $projectFont: 'body02',
})

const StyledButton = styled(Button, {
  variants: {
    appearance: {
      ButtonText: {
        padding: '$4 $8',
      },
    },
  },
})

const QuantityLabel = styled('p', {
  textTransform: 'uppercase',
})

export const CartLine: FC<{
  line: ShopifyResolvedCartLine
  updateCartLines: any
}> = ({ line, updateCartLines }) => {
  const { t } = useTranslation('cart')
  const {
    id,
    merchandise: {
      product: { title: productTitle, handle },
      image,
      // title: variantTitle,
    },
    cost: { totalAmount },
  } = line
  const [quantity, setQuantity] = useState(line.quantity)

  const gtmQuantityChange = (
    line: ShopifyResolvedCartLine,
    newQuantity: number,
  ) => {
    // Removing from cart
    if (quantity - newQuantity >= 0) {
      GTMRemoveFromCart(line, quantity - newQuantity)
      return
    }
    // Adding to cart
    GTMAddToCart(line, newQuantity - quantity)
  }

  const debouncedUpdate = useMemo(
    () =>
      debounce((line: ShopifyResolvedCartLine, newQuantity: number) => {
        updateCartLines([{ id, quantity: Number(newQuantity) }])
        setQuantity(newQuantity)
        gtmQuantityChange(line, newQuantity)
      }, 600),
    [],
  )

  const handleQuantityChange = (
    line: ShopifyResolvedCartLine,
    newQuantity: number,
  ) => {
    setQuantity(newQuantity)
    debouncedUpdate(line, newQuantity)
  }

  return (
    <Root>
      <MediaWrap>
        <Link href={`${ROUTE_BASE_PRODUCTS}/${handle}`}>
          {image && (
            <Media
              mediaPayload={image}
              sizes={'168px'}
              layout={'fill'}
              objectFit={'contain'}
              ratio={'portrait3'}
            />
          )}
        </Link>
      </MediaWrap>
      <ContentWrap>
        <TitleLink href={`${ROUTE_BASE_PRODUCTS}/${handle}`}>
          {productTitle}
        </TitleLink>
        <QuantityWrap>
          <QuantityLabel>{t('quantityLabel')}</QuantityLabel>
          <StyledButton
            appearance={'ButtonText'}
            onClick={() =>
              handleQuantityChange(line, quantity > 0 ? quantity - 1 : 0)
            }
          >
            -
          </StyledButton>
          {quantity}
          <StyledButton
            appearance={'ButtonText'}
            onClick={() => handleQuantityChange(line, quantity + 1)}
          >
            +
          </StyledButton>
        </QuantityWrap>
        <ProductVariantPrice price={totalAmount} />
      </ContentWrap>
    </Root>
  )
}
