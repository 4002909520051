import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Loader } from '@sans-souci/components'
import { styled } from '@sans-souci/styles'
import { Success } from '@sans-souci/icons'

const Container = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$12',
  height: '$32',
})

const StyledButton = styled(Button, {
  transition: 'all 100ms ease-in-out',
  '&:disabled': {
    opacity: '0.75',
  },

  variants: {
    success: {
      true: {
        display: 'grid',
        gridAutoFlow: 'column',
        gap: '$8',
        alignItems: 'flex-start',
        background: '$black',
        color: '$beige',

        '&:disabled': {
          background: '$black',
          color: '$beige',
          opacity: 'unset',
        },

        '@lg': {
          '&:hover': {
            background: '$black',
          },
        },
      },
      false: {},
    },
  },
})

type SubmitButtonProps = {
  text?: string
  successText?: string
  helpText?: string
  invalid?: boolean
  success?: boolean
  loading?: boolean
  disabled?: boolean
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  text,
  successText,
  loading,
  disabled,
  success,
}) => {
  const { t } = useTranslation('forms')

  const buttonText = text || t('defaultSubmitButtonLabel')
  const buttonSuccessText = successText || text

  return (
    <Container>
      <StyledButton
        type="submit"
        disabled={loading || disabled || success}
        appearance={'ButtonPrimaryWhite'}
        success={success}
      >
        {loading && <Loader size={'small'} />}

        {!loading && !success && buttonText}
        {!loading && success && (
          <>
            {buttonSuccessText}
            <Success />
          </>
        )}
      </StyledButton>
    </Container>
  )
}
