import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createEditorialPortableTextProjection } from '../projections/createEditorialPortableTextProjection'

export const createSectionUtilityContentProjection = (localeId: LocaleId) => {
  const EDITORIAL_PORTABLE_TEXT_PROJECTION =
    createEditorialPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        media${MEDIA_PROJECTION},
        items[]{
            _key,
            _type,
            ${coalesceLocaleField(
              'description',
              localeId,
            )}[]${EDITORIAL_PORTABLE_TEXT_PROJECTION},
         ${coalesceLocaleField('title', localeId)},
        },
      }
  `
}
