import { FC, PropsWithChildren } from 'react'
import { StyledClickable } from '@sans-souci/components'
import { MdKeyboardArrowLeft } from 'react-icons/md'

type Props = PropsWithChildren<{ onClick?: () => void }>

export const BackButton: FC<Props> = ({ onClick, children }) => (
  <StyledClickable appearance={'ButtonText'} onClick={onClick}>
    <MdKeyboardArrowLeft />
    <span>{children}</span>
  </StyledClickable>
)
