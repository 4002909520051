import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { formatDate } from '../../../helpers/formatDate'
import { useRouter } from 'next/router'
import { usePageContext } from '../pageContext'
import { Button } from '@sans-souci/components'
import {
  getI18nPrice,
  ShopifyOrderFieldsFragment,
} from '@sans-souci/shopify-sdk'

const Text = styled('span', {
  $projectFont: 'dev',
  '@md': {
    $projectFont: 'dev',
  },
})

const OrderItemContainer = styled(Button, {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  justifyItems: 'stretch',
  padding: '$24 0',
  borderTop: '1px solid $gray300',
  borderBottom: '1px solid $gray300',
  borderLeft: '1px solid transparent',
  borderRight: '1px solid transparent',
  [`& + &`]: {
    '&:not(&:focus-within)': {
      borderTop: '1px solid transparent',
    },
  },
  '&:first-child': {
    '&:not(&:focus-within)': {
      borderTop: '1px solid transparent',
    },
  },
  '&:last-of-type': {
    marginBottom: '$32',
  },
  span: {
    textAlign: 'left',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
    '&:last-of-type': {
      textAlign: 'right',
    },
  },
  '&:before': {
    content: '',
    position: 'absolute',
    backgroundColor: 'transparent',
    height: '1px',
    bottom: 0,
    transition: '200ms',
  },
  '&:hover': {
    '&:before': {
      backgroundColor: '$black',
    },
    cursor: 'pointer',
  },
  '&:active': {
    span: {
      opacity: '0.8',
    },
  },
})

export const OrderItem: FC<{
  order: ShopifyOrderFieldsFragment
  title?: string
}> = ({ order }) => {
  const { id, name, processedAt, fulfillmentStatus, currentTotalPrice } = order
  const { locale } = useRouter()
  const date = processedAt ? formatDate(processedAt) : null
  const { setActiveView } = usePageContext()

  return (
    <OrderItemContainer onClick={() => setActiveView('orders-view', id)}>
      {name ? <Text>{name}</Text> : null}
      {date && <Text>{date}</Text>}
      {fulfillmentStatus ? (
        <Text css={{ textTransform: 'lowercase' }}>{fulfillmentStatus}</Text>
      ) : null}
      {currentTotalPrice ? (
        <Text>{getI18nPrice(currentTotalPrice, locale)}</Text>
      ) : null}
    </OrderItemContainer>
  )
}
