import { LocaleId, RegionId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'

export const createCollectionPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  return groq`{
    "pageBuilder": [
      ...${PAGE_BUILDER_PROJECTION},
    ]
  }`
}
