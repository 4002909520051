import { useEffect, useRef } from 'react'
import { styled } from '@sans-souci/styles'

const Wrap = styled('div', {
  minHeight: '100vh',
  display: 'grid',
  placeItems: 'center',
})

const COOKIEBOT_DOMAIN_GROUP_ID = '65241367-4db4-45c8-a476-6681dad0e6fe'

export const CookieDeclaration = () => {
  const cookieDeclarationContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /*
     * Cookiebot
     * This allows the cookie declaration to be loaded inside the page
     * Next Script does not allow script tags to load in the page's content
     * JSX loaded by regular script tag are not inserted into the DOM
     */
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://consent.cookiebot.com/${COOKIEBOT_DOMAIN_GROUP_ID}/cd.js`
    const targetElement = cookieDeclarationContainer.current

    if (targetElement) {
      targetElement.appendChild(script)
    }
  }, [])

  return (
    <Wrap>
      <div ref={cookieDeclarationContainer} id="cookiebot-declaration">
        {/*Cookiebot will insert declaration here*/}
      </div>
    </Wrap>
  )
}
