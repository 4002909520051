import { FC, useState, Dispatch, SetStateAction } from 'react'
import {
  PACKAGING_ATTRIBUTE_KEY,
  ROUTE_BASE_PRODUCTS,
} from '@sans-souci/configs'
import { styled } from '@sans-souci/styles'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Link,
  Media,
  ProductVariantPrice,
} from '@sans-souci/components'
import {
  formatShopifyVariantBasic,
  ShopifyOrderLineFieldsFragment,
} from '@sans-souci/shopify-sdk'

const Root = styled('article', {
  display: 'grid',
  gap: '$16',
  gridTemplateColumns: '116px 1fr',
  color: '$black',
  '@sm': {
    gridTemplateColumns: '174px 1fr',
    gap: '$28',
  },
  variants: {
    isGreyOut: {
      true: {
        opacity: 0.7,
        pointerEvents: 'none',
      },
    },
  },
})

const MediaContainerWrap = styled('div', {
  overflow: 'hidden',
  paddingTop: '100%',
  background: '$gray300',
  position: 'relative',
})

const MediaContainer = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const DetailsWrap = styled('div', {
  maxWidth: '$maxWidthS',
  display: 'grid',
  gridTemplateRows: 'auto auto auto 1fr',
  gap: '$2',
  $projectFont: 'dev',
})

const Title = styled('h2', {
  textTransform: 'capitalize',
})

const PriceWrap = styled('div', {
  display: 'grid',
  gap: '$4',
  gridTemplateColumns: 'auto 1fr',
  '@md': {
    gridTemplateColumns: 'auto 1fr auto',
  },
})

const OptionsAndAttributesWrap = styled('ul', {
  display: 'grid',
  rowGap: '$4',
  placeItems: 'start',
  color: '$gray300',
})

const ProductDetail = styled('li', {})

const ShowAllButton = styled(Button, {
  color: '$black',
  textDecoration: 'underline',
})

type CartLineProps = {
  className?: string
  line: ShopifyOrderLineFieldsFragment
  isEditable?: boolean
  handleRemoveCartLine?: () => void
  handleUpdateCartLine?: (newQuantity: number) => void
  setCartModalOpen?: Dispatch<SetStateAction<boolean>>
}

export const AccountCartLine: FC<CartLineProps> = ({ line }) => {
  const { t } = useTranslation('cart')

  const [attributesExpanded, setAttributesExpanded] = useState(false)

  const variant = line.variant ? formatShopifyVariantBasic(line.variant) : null

  if (!variant) return null

  const computedAttributes: [string, string][] = (() => {
    // I'm doing map just to avoid duplicates
    const resultFromSelectedOptions = variant.selectedOptions.reduce(
      (acc, val) => {
        if (val.name.startsWith('_')) return acc
        if (val.name === 'Title') return acc
        return {
          ...acc,
          [val.name]: val.value,
        }
      },
      {},
    )
    const resultFromSelectedAttributes = line.customAttributes?.reduce(
      (acc, val) => {
        if (val.key === PACKAGING_ATTRIBUTE_KEY) return acc
        if (val.key.startsWith('_')) return acc
        return {
          ...acc,
          [val.key]: val.value,
        }
      },
      {},
    )

    return Object.entries({
      ...resultFromSelectedAttributes,
      ...resultFromSelectedOptions,
    })
  })()

  return (
    <Root key={line.variant?.id}>
      <MediaContainerWrap>
        <MediaContainer>
          <Link href={`${ROUTE_BASE_PRODUCTS}/${variant.product.handle}`}>
            {variant.image && (
              <Media
                mediaPayload={variant.image}
                sizes={'(min-width: 430px) 174px, 116px'}
                layout={'fill'}
                objectFit={'contain'}
              />
            )}
          </Link>
        </MediaContainer>
      </MediaContainerWrap>
      <DetailsWrap>
        <Link
          appearance={'ButtonText'}
          href={`${ROUTE_BASE_PRODUCTS}/${variant.product.handle}`}
        >
          <Title>{variant.product.title}</Title>
        </Link>

        <PriceWrap>
          <ProductVariantPrice price={variant.priceV2} /> {`× ${line.quantity}`}
          <ProductVariantPrice price={line.originalTotalPrice} />
        </PriceWrap>

        <OptionsAndAttributesWrap>
          {computedAttributes.map((a, i) => {
            if (!attributesExpanded && i > 1) {
              return null
            } else {
              return <ProductDetail key={a[0]}>{a[1]}</ProductDetail>
            }
          })}
          {!attributesExpanded && computedAttributes.length > 2 && (
            <ShowAllButton onClick={() => setAttributesExpanded(true)}>
              {t('showAll')}
            </ShowAllButton>
          )}
        </OptionsAndAttributesWrap>
      </DetailsWrap>
    </Root>
  )
}
