import { getSdk, SdkFunctionWrapper } from './dist/generatedSchemaSdk'
import { fetchServiceWithRetry } from '@sans-souci/utils'
import { GraphQLClient } from 'graphql-request'

const middleware: SdkFunctionWrapper = async (action) => {
  return await fetchServiceWithRetry(() => action(), 5)
}

export const createShopifySdk = (shopifyClient: GraphQLClient) => {
  return getSdk(shopifyClient, middleware)
}
