import { LocaleId } from '@sans-souci/configs'
import {
  ShopifyProductSortKeys,
  createShopifySdk,
  ShopifyGetSearchResultsQueryVariables,
  formatShopifyProductBasic,
} from '@sans-souci/shopify-sdk'
import { createShopifyClient } from './createShopifyClient'

export const DEFAULT_SEARCH_SORT_KEY = 'relevance'
export const SEARCH_SORT_KEY_MAP: Record<
  string,
  { sortKey: ShopifyProductSortKeys; reverse: boolean }
> = {
  relevance: { sortKey: ShopifyProductSortKeys.Relevance, reverse: true },
  bestSellingAsc: {
    sortKey: ShopifyProductSortKeys.BestSelling,
    reverse: false,
  },
  bestSellingDesc: {
    sortKey: ShopifyProductSortKeys.BestSelling,
    reverse: true,
  },
  createdAsc: { sortKey: ShopifyProductSortKeys.CreatedAt, reverse: false },
  createdDesc: { sortKey: ShopifyProductSortKeys.CreatedAt, reverse: true },
  priceAsc: { sortKey: ShopifyProductSortKeys.Price, reverse: false },
  priceDesc: { sortKey: ShopifyProductSortKeys.Price, reverse: true },
  titleAsc: { sortKey: ShopifyProductSortKeys.Title, reverse: false },
  titleDesc: { sortKey: ShopifyProductSortKeys.Title, reverse: true },
}
export const SEARCH_PRODUCTS_PAGE_SIZE = 16

export { ShopifyProductSortKeys }
export const getShopifySearchResults = async (
  variables: ShopifyGetSearchResultsQueryVariables,
  shopifyClientLocaleId: LocaleId,
) => {
  const shopifyClient = createShopifyClient(shopifyClientLocaleId)
  return await createShopifySdk(shopifyClient)
    .getSearchResults({
      ...variables,
      count: SEARCH_PRODUCTS_PAGE_SIZE,
      query: `(title:"${variables.query}")`,
    })
    .then((res) => {
      return res.data
        ? {
            products: res.data.products.nodes.map(formatShopifyProductBasic),
            pageInfo: res.data.products.pageInfo,
          }
        : null
    })
}
