import { FC } from 'react'

export const ArrowRightShort: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.1716 0.464461L18.3536 3.64644C18.5488 3.8417 18.5488 4.15829 18.3536 4.35355L15.1716 7.53553C14.9763 7.73079 14.6597 7.73079 14.4645 7.53553C14.2692 7.34027 14.2692 7.02368 14.4645 6.82842L16.7929 4.5L3.85912e-07 4.5L2.93632e-07 3.5L16.7929 3.5L14.4645 1.17157C14.2692 0.976306 14.2692 0.659724 14.4645 0.464461C14.6597 0.269199 14.9763 0.269199 15.1716 0.464461Z"
      fill="currentColor"
    />
  </svg>
)
