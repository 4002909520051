import React from 'react'

export const Success = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM5.23082 6.91993L3.88569 5.57479C3.68304 5.37214 3.35458 5.37214 3.15193 5.57479C2.94936 5.77736 2.94936 6.1059 3.15193 6.30846L4.86403 8.02056C5.0666 8.22313 5.39514 8.22313 5.5977 8.02056L8.53256 5.08571C8.73512 4.88306 8.73512 4.5546 8.53256 4.35195C8.32991 4.14938 8.00145 4.14938 7.7988 4.35195L5.23082 6.91993Z"
        fill="currentColor"
      />
    </svg>
  )
}
