import { FC } from 'react'
import { PricePrimitive } from './PricePrimitve'
import { useTranslation } from 'react-i18next'
import { ShopifyProductPriceRangeFieldsFragment } from '@sans-souci/shopify-sdk'

type PriceRangeProps = {
  range: ShopifyProductPriceRangeFieldsFragment
}

export const PriceRange: FC<PriceRangeProps> = ({ range }) => {
  const { t } = useTranslation('productPrice')
  return range.maxVariantPrice.amount === range.minVariantPrice.amount ? (
    <PricePrimitive price={range.minVariantPrice} />
  ) : (
    <>
      <span className={'prefix'}>{`${t('from')} `}</span>
      <PricePrimitive price={range.minVariantPrice} />
    </>
  )
}
