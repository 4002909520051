import { CurrentItemsType, SortType } from '../models'

const compareDates = (dateA?: string, dateB?: string): number => {
  if (!dateA || !dateB) return 0

  const parsedDateA = Date.parse(dateA)
  const parsedDateB = Date.parse(dateB)

  if (isNaN(parsedDateA) || isNaN(parsedDateB)) return 0

  return parsedDateB - parsedDateA
}

export const sortItems = (
  items: CurrentItemsType[],
  activeSortSlug: SortType,
): CurrentItemsType[] => {
  return items.sort((a, b) => {
    switch (activeSortSlug) {
      case 'featured':
        return 0
      case 'newest':
        return compareDates(a.releaseDate, b.releaseDate)
      case 'oldest':
        return compareDates(b.releaseDate, a.releaseDate)
      case 'az':
        return (a.title ?? '').localeCompare(b.title ?? '')
      case 'za':
        return (b.title ?? '').localeCompare(a.title ?? '')
      default:
        return 0
    }
  })
}
