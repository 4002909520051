import React from 'react'

export const Picto = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4063_101)">
        <path d="M0 0.5H12V1.5H0V0.5Z" fill="currentColor" />
        <path d="M2 3.5H10V4.5H2V3.5Z" fill="currentColor" />
        <path d="M4 6.5H8V7.5H4V6.5Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_4063_101">
          <rect
            width="12"
            height="11"
            fill="currentColor"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
