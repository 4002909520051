import { ShopifyAttribute } from '@sans-souci/shopify-sdk'

export const equalAttributes: (
  a: ShopifyAttribute[] | undefined | null,
  b: ShopifyAttribute[] | undefined | null,
) => boolean = (a, b) => {
  if (!a && b?.length === 0) {
    return true
  }
  if (!b && a?.length === 0) {
    return true
  }
  if (!a && !b) return true
  return !!(
    a &&
    b &&
    a.length === b.length &&
    a.every((a1) => b.find((a2) => a2.key === a1.key)?.value === a1.value)
  )
}
