import { createShopifyClient } from '@sans-souci/services/shopify-service'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import {
  createShopifySdk,
  ShopifyCountryCode,
  ShopifyCustomerAccessTokenCreateMutationVariables,
  ShopifyCustomerAccessTokenDeleteMutationVariables,
  ShopifyCustomerAccessTokenRenewMutationVariables,
  ShopifyCustomerActivateByUrlMutationVariables,
  ShopifyCustomerAddressCreateMutationVariables,
  ShopifyCustomerAddressDeleteMutationVariables,
  ShopifyCustomerAddressUpdateMutationVariables,
  ShopifyCustomerCreateMutationVariables,
  ShopifyCustomerDefaultAddressUpdateMutationVariables,
  ShopifyCustomerRecoverMutationVariables,
  ShopifyCustomerResetByUrlMutationVariables,
  ShopifyCustomerUpdateMutationVariables,
  ShopifyGetCustomerQueryVariables,
} from '@sans-souci/shopify-sdk'

type CustomerClientProps = {
  locale?: string
}
export const createCustomerClient = ({ locale }: CustomerClientProps) => {
  const [countryCode, language] = getLocaleRegionIdFromPath(locale) as [
    ShopifyCountryCode,
    string,
  ]
  const client = createShopifyClient(language)
  const sdk = createShopifySdk(client)

  return {
    // customer
    create: (input: ShopifyCustomerCreateMutationVariables) =>
      sdk.customerCreate(input).then((res) => res.data),
    update: (input: ShopifyCustomerUpdateMutationVariables) =>
      sdk.customerUpdate(input).then((res) => res.data),
    activate: (input: ShopifyCustomerActivateByUrlMutationVariables) =>
      sdk.customerActivateByUrl(input).then((res) => res.data),
    recover: (input: ShopifyCustomerRecoverMutationVariables) =>
      sdk.customerRecover(input).then((res) => res.data),
    reset: (input: ShopifyCustomerResetByUrlMutationVariables) =>
      sdk.customerResetByUrl(input).then((res) => res.data),
    get: (input: ShopifyGetCustomerQueryVariables) =>
      sdk.getCustomer({ ...input, countryCode }).then((res) => res.data),

    // token
    createToken: (input: ShopifyCustomerAccessTokenCreateMutationVariables) =>
      sdk.customerAccessTokenCreate(input).then((res) => res.data),
    deleteToken: (input: ShopifyCustomerAccessTokenDeleteMutationVariables) =>
      sdk.customerAccessTokenDelete(input).then((res) => res.data),
    renewToken: (input: ShopifyCustomerAccessTokenRenewMutationVariables) =>
      sdk.customerAccessTokenRenew(input).then((res) => res.data),

    // address
    createAddress: (input: ShopifyCustomerAddressCreateMutationVariables) =>
      sdk.customerAddressCreate(input).then((res) => res.data),
    deleteAddress: (input: ShopifyCustomerAddressDeleteMutationVariables) =>
      sdk.customerAddressDelete(input).then((res) => res.data),
    updateAddress: (input: ShopifyCustomerAddressUpdateMutationVariables) =>
      sdk.customerAddressUpdate(input).then((res) => res.data),
    updateDefaultAddress: (
      input: ShopifyCustomerDefaultAddressUpdateMutationVariables,
    ) => sdk.customerDefaultAddressUpdate(input).then((res) => res.data),
  }
}
