import { useEffect, useRef, useState } from 'react'

export type AnimationStatus = 'entering' | 'entered' | 'exiting' | 'exited'
export const useAnimation = (
  isOpen: boolean,
  enteringTime: number,
  exitingTime: number,
) => {
  const [status, setStatus] = useState<AnimationStatus>('exited')

  const timer = useRef<number | null>(null)

  useEffect(() => {
    timer.current && window.clearTimeout(timer.current)
    if (isOpen) {
      setStatus('entering')
      timer.current = window.setTimeout(() => {
        setStatus('entered')
      }, enteringTime)
    } else {
      setStatus('exiting')
      timer.current = window.setTimeout(() => {
        setStatus('exited')
      }, exitingTime)
    }
  }, [isOpen])
  return status
}
