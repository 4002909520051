import {
  useState,
  createContext,
  FC,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

import { createCustomerClient } from './customerService'
import { accountAnalytics } from './helpers/accountAnalytics'
import { captureException } from '@sentry/nextjs'
import {
  shopifyConfig,
  ShopifyCustomerFieldsFragment,
} from '@sans-souci/shopify-sdk'

// includes redirect to account page
const SHOPIFY_ACCOUNT_LOGOUT_URL = new URL(
  '/account/logout?return_url=%2Faccount',
  shopifyConfig.domain,
).href

export type CustomerContextType = {
  isLoggedIn: boolean
  isPartner: boolean
  isVerified: boolean
  customer: ShopifyCustomerFieldsFragment | null
  accessToken: string | null
  setToken: (token: string) => void
  removeToken: () => void
  updateCustomer: (customer?: ShopifyCustomerFieldsFragment) => void
}

export const CustomerContext = createContext<CustomerContextType>({
  isVerified: false,
  isLoggedIn: false,
  isPartner: false,
  customer: null,
  accessToken: null,
  setToken: () => null,
  removeToken: () => null,
  updateCustomer: () => null,
})

export const COOKIES_ACCESS_TOKEN_ID = `SANS_SOUCI_CUSTOMER_ACCESS_TOKEN`

const createCustomerAccessTokenCookieService = () => {
  return {
    get: () => Cookies.get(COOKIES_ACCESS_TOKEN_ID),
    set: (token: string) =>
      Cookies.set(COOKIES_ACCESS_TOKEN_ID, token, { expires: 5 }),
    remove: () => Cookies.remove(COOKIES_ACCESS_TOKEN_ID),
  }
}

export const COOKIES_SANS_SOUCI_PARTNER = `SANS_SOUCI_PARTNER`

const createCustomerPartnerCookieService = () => {
  return {
    get: () => Cookies.get(COOKIES_SANS_SOUCI_PARTNER),
    set: (value: boolean) =>
      Cookies.set(COOKIES_SANS_SOUCI_PARTNER, String(value), { expires: 5 }),
    remove: () => Cookies.remove(COOKIES_SANS_SOUCI_PARTNER),
  }
}

export const CustomerProvider: FC<PropsWithChildren<{ debug?: boolean }>> = ({
  children,
}) => {
  const { locale } = useRouter()
  const [customer, setCustomer] =
    useState<ShopifyCustomerFieldsFragment | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [isPartner, setIsPartner] = useState<boolean>(false)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  const cookiesToken = createCustomerAccessTokenCookieService()
  const cookiesPartner = createCustomerPartnerCookieService()
  const customerClient = createCustomerClient({ locale })

  const fetchCustomer = async (customerAccessToken: string) => {
    const response = await customerClient.get({ customerAccessToken })
    if (response?.customer) {
      setCustomer(response?.customer)
    }
  }

  const setToken = (customerAccessToken: string) => {
    setAccessToken(customerAccessToken)
    cookiesToken.set(customerAccessToken)
  }

  const removeToken = async () => {
    if (accessToken) {
      await customerClient.deleteToken({ customerAccessToken: accessToken })
      setAccessToken(null)
      cookiesToken.remove()
      cookiesPartner.remove()
    }
    // IMPORTANT:  we need to additionally logout user from the checkout
    window.location.href = SHOPIFY_ACCOUNT_LOGOUT_URL
  }

  const updateCustomer = async (
    updatedCustomer?: ShopifyCustomerFieldsFragment,
  ) => {
    try {
      if (updatedCustomer) {
        setCustomer(updatedCustomer)
        return
      }
      if (accessToken) {
        await fetchCustomer(accessToken)
      }
    } catch (e) {
      captureException(e)
    }
  }

  const updateLoginState = async (token?: string | null) => {
    try {
      if (token) {
        setToken(token)
        setIsLoggedIn(true)
        await fetchCustomer(token)
        setIsVerified(true)
        accountAnalytics.logIn()
        return
      }
      setIsLoggedIn(false)
      setCustomer(null)
      setIsVerified(true)
      accountAnalytics.logOut()
    } catch (e) {
      captureException(e)
    }
  }

  useEffect(() => {
    if (customer) {
      const userIsPartner = customer.metafields?.reduce((acc, current) => {
        return (
          acc || (current?.value === 'true' && current?.key === 'is_partner')
        )
      }, false)
      setIsPartner(userIsPartner ?? false)
      if (userIsPartner) {
        cookiesPartner.set(true)
      } else {
        cookiesPartner.remove()
      }
    } else if (cookiesPartner.get()) {
      cookiesPartner.remove()
    }
  }, [customer])

  // recover login state form cookies on mount
  useEffect(() => {
    const customerAccessToken = cookiesToken.get()
    updateLoginState(customerAccessToken)
  }, [])

  // update login state on token change
  useEffect(() => {
    if (isVerified) {
      updateLoginState(accessToken)
    }
  }, [accessToken, isVerified])

  return (
    <CustomerContext.Provider
      value={{
        customer,
        accessToken,
        setToken,
        removeToken,
        updateCustomer,
        isLoggedIn,
        isPartner,
        isVerified,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomerContext = (): CustomerContextType => {
  return useContext(CustomerContext)
}
