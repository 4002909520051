import { addBreadcrumb, setTag } from '@sentry/nextjs'

export const accountAnalytics = {
  logIn: () => {
    setTag('logged_in', true)
    addBreadcrumb({
      category: 'auth',
      message: 'User logged in',
      level: 'info',
    })
  },
  logOut: () => {
    addBreadcrumb({
      category: 'auth',
      message: 'User logged off',
      level: 'info',
    })
    setTag('logged_in', false)
  },
}
