import { styled } from '@sans-souci/styles'
import React, { FC, PropsWithChildren } from 'react'

const Wrapper = styled('div', {
  display: 'grid',
  gap: '$48',
  gridAutoFlow: 'row',
  padding: '$20',
  height: 'fit-content',
  width: '100%',
  color: '$black',
  backgroundColor: '$gray100',
  variants: {
    disabled: {
      true: {
        opacity: 0.85,
        pointerEvents: 'none',
      },
    },
  },
  '@md': {
    padding: '$32',
  },
})

const Title = styled('h2', {
  $projectFont: 'heading02',
})

const Description = styled('div', {
  maxWidth: '$maxWidthS',
  $projectFont: 'dev',
})

type ViewCardWrapperProps = PropsWithChildren<{
  title?: string
  description?: string
  disabled?: boolean
}>

export const AccountCardWrapper: FC<ViewCardWrapperProps> = ({
  children,
  title,
  description,
  disabled,
}) => {
  return (
    <Wrapper disabled={disabled}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {children}
    </Wrapper>
  )
}
