import { formatShopifyImage } from './formatShopifyImage'
import { ShopifyProductBasicFieldsFragment } from '../dist/generatedSchemaSdk'

export const formatShopifyProductBasic = (
  product: ShopifyProductBasicFieldsFragment,
) => ({
  ...product,
  featuredImage: product.featuredImage
    ? formatShopifyImage(product.featuredImage)
    : null,
})

export type ShopifyResolvedProductBasic = ReturnType<
  typeof formatShopifyProductBasic
>
