import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react'

type Props = { isActive: boolean; duration?: number }

export function useHeightAnimation<T extends HTMLElement>({
  isActive,
  duration = 300,
}: Props): [RefObject<T>, CSSProperties] {
  const elRef = useRef<T>(null)
  const [height, setHeight] = useState(isActive ? 'auto' : '0px')
  const [isVisible, setVisibility] = useState(isActive)

  useEffect(() => {
    if (isActive) {
      // get the height of the element's inner content, regardless of its actual size
      setHeight(elRef?.current?.scrollHeight + 'px')
    } else {
      setHeight(0 + 'px')
    }
  }, [isActive])

  // set item visibility after/before animation
  useEffect(() => {
    if (!isVisible && isActive) {
      return setVisibility(isActive)
    }
    setTimeout(() => {
      setVisibility(isActive)
    }, duration)

    return () => clearTimeout(duration)
  }, [isActive, duration, isVisible])

  const visibility: 'visible' | 'hidden' = isVisible ? 'visible' : 'hidden'
  const opacity = isActive ? 1 : 0

  const css = {
    height,
    overflow: 'hidden',
    transition: `height ${duration}ms ease-out, opacity ${duration}ms ease-out`,
    opacity,
    visibility,
  }

  return [elRef, css]
}
