import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UseFormReturn, Path, FieldValues } from 'local-react-hook-form'

type UseFormSubmitStateProps<T extends FieldValues> = {
  form: UseFormReturn<T>
  defaultValues: T
  keepValuesOnSuccess?: boolean
}

export function useFormSubmitState<T extends FieldValues>({
  form,
  defaultValues,
  keepValuesOnSuccess = false,
}: UseFormSubmitStateProps<T>) {
  const { formState, reset, setError: setFormFieldError } = form
  const { isSubmitSuccessful, isSubmitted, isDirty } = formState

  const { t } = useTranslation('forms')

  const [submitMessage, setSubmitMessage] = useState('')

  const invalid = isSubmitted && !isSubmitSuccessful
  const success = isSubmitted && isSubmitSuccessful
  const isSubmitTextVisible = isSubmitted && !isDirty

  const setError = (message?: string) => {
    setSubmitMessage(message || t('common.submitErrorMessage'))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reset({}, { keepIsSubmitted: true, keepValues: true, keepErrors: true })
    return Promise.reject()
  }

  const setFieldError = (fieldName: Path<T>, message: string) => {
    setFormFieldError(fieldName, { message, type: 'custom' })
  }

  const setSuccess = (message?: string) => {
    setSubmitMessage(message || t('common.submitSuccessMessage'))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reset(defaultValues, {
      keepIsSubmitted: true,
      keepValues: keepValuesOnSuccess,
    })
  }

  const visibleSubmitMessage = isSubmitTextVisible ? submitMessage : ''

  return {
    invalid,
    success,
    submitMessage: visibleSubmitMessage,
    setError,
    setSuccess,
    setFieldError,
  }
}
