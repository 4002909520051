import { FC } from 'react'
import { PricePrimitive } from './PricePrimitve'
import { styled } from '@stitches/react'
import { ShopifyMoneyFieldsFragment } from '@sans-souci/shopify-sdk'

const ComparedPrice = styled('s', {
  color: '$gray300',
  marginLeft: '$8',
})

export const ProductVariantPrice: FC<{
  price?: ShopifyMoneyFieldsFragment
  compareAtPrice?: ShopifyMoneyFieldsFragment
}> = ({ price, compareAtPrice }) => {
  if (!price) return null

  const shouldDisplayCompareAtPrice =
    compareAtPrice &&
    parseFloat(compareAtPrice.amount) > parseFloat(price.amount)

  return (
    <>
      <PricePrimitive price={price} />
      {shouldDisplayCompareAtPrice && (
        <>
          &nbsp;
          <ComparedPrice>
            <PricePrimitive price={compareAtPrice} />
          </ComparedPrice>
        </>
      )}
    </>
  )
}
