import { styled } from '@sans-souci/styles'
import { MdErrorOutline } from 'react-icons/md'
import { FC } from 'react'

const Root = styled('span', {
  display: 'grid',
  alignItems: 'center',
  $projectFont: 'body02',
  textTransform: 'uppercase',
  color: '$grey',
  variants: {
    invalid: {
      true: {
        gap: '$4',
        gridTemplateColumns: 'auto 1fr',
        color: '$red500',
      },
    },
    success: {
      true: {
        color: '$green',
      },
    },
    theme: {
      light: {},
      dark: {},
    },
  },

  compoundVariants: [
    {
      theme: 'dark',
      success: true,
      css: {
        color: '$beige',
      },
    },
  ],
})

export const FormResponseMessage: FC<{
  invalid?: boolean
  success?: boolean
  message?: string
  theme?: 'light' | 'dark'
}> = ({ invalid, success, message, theme = 'light' }) => {
  if (message && (invalid || success))
    return (
      <Root
        invalid={invalid}
        success={success}
        theme={theme}
        role={invalid ? 'alert' : 'status'}
      >
        {invalid && <MdErrorOutline />}
        {message}
      </Root>
    )
  else return <p> </p>
}
