import { ValidateFnType } from '../models/ValidateFnType'

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail: ValidateFnType = (t) => ({
  pattern: {
    value: EMAIL_REGEXP,
    message: t('validate.invalidEmailError'),
  },
})
