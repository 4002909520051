import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { Link } from '@sans-souci/components'
import { useCustomerContext } from '../../../customerContext'
import { OrderItem } from '../components/OrderItem'
import { useTranslation } from 'react-i18next'
import { AccountCardWrapper } from '../components/AccountCardWrapper'

const Root = styled('div', {
  display: 'grid',
  gap: '$32',
})

const OrdersContainer = styled('div', {
  display: 'grid',
  ol: {
    display: 'grid',
    listStyleType: 'none',
  },
})

const NoOrdersContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$36',
  $projectFont: 'dev',
})

const OrderHeader = styled('ol', {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  justifyItems: 'stretch',
  borderBottom: '1px solid $gray300',
  $projectFont: 'dev',
  color: '$gray300',
  listStyleType: 'none',
  li: {
    listStyleType: 'none',
    '&:last-of-type': {
      textAlign: 'right',
    },
  },
})

export const OrdersList: FC = () => {
  const { customer } = useCustomerContext()
  const orders = customer?.orders?.nodes || []
  const { t } = useTranslation('account')

  return (
    <Root>
      <AccountCardWrapper title={t('accountPage.ordersListView.header')}>
        <OrdersContainer>
          <OrderHeader>
            <li>{t('accountPage.ordersListView.orderMenuItem')}</li>
            <li>{t('accountPage.ordersListView.dateMenuItem')}</li>
            <li>{t('accountPage.ordersListView.statusMenuItem')}</li>
            <li>{t('accountPage.ordersListView.totalMenuItem')}</li>
          </OrderHeader>
          <ol>
            {orders.map((node, index) => {
              if (!node) return null
              return <OrderItem key={`orderItem-${index}`} order={node} />
            })}
            {orders.length === 0 ? (
              <li>
                <NoOrdersContainer>
                  {t('accountPage.ordersListView.noOrdersText')}
                </NoOrdersContainer>
              </li>
            ) : null}
          </ol>
        </OrdersContainer>
      </AccountCardWrapper>
      <Link appearance={'ButtonPrimaryWhite'} href={'/'}>
        {t('accountPage.ordersListView.startShoppingButtonLabel')}
      </Link>
    </Root>
  )
}
