import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createPortableTextProjection } from '../projections/createPortableTextProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionGalleryProjection = (localeId: LocaleId) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
        mediaGrid[] {
          _key,
          media${MEDIA_PROJECTION},
        },
      }
  `
}
