import { formatShopifyMedia } from './formatShopifyMedia'
import { formatShopifyImage, formatShopifyImages } from './formatShopifyImage'
import { ShopifyProductExtendedFieldsFragment } from '../dist/generatedSchemaSdk'
import { formatShopifyVariantBasic } from './formatShopifyVariantBasic'

export const formatShopifyProductExtended = (
  product: ShopifyProductExtendedFieldsFragment,
) => ({
  ...product,
  featuredImage: product.featuredImage
    ? formatShopifyImage(product.featuredImage)
    : null,
  media: product.media?.nodes ? formatShopifyMedia(product.media.nodes) : null,
  rawMedia: product.media?.nodes,
  images: product.images?.nodes
    ? formatShopifyImages(product.images.nodes)
    : null,
  variants: product.variants.nodes.map(formatShopifyVariantBasic),
})

export type ShopifyResolvedProductExtended = ReturnType<
  typeof formatShopifyProductExtended
>
