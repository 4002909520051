export const Play = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.408 5.5458C12.7182 5.74208 12.7182 6.19455 12.408 6.39083L3.76735 11.8583C3.43443 12.069 3 11.8298 3 11.4358L3 0.500807C3 0.10684 3.43443 -0.132369 3.76735 0.0782889L12.408 5.5458Z" />
    </svg>
  )
}
