import React, { ComponentPropsWithRef, FC } from 'react'
import ReactModal from 'react-modal'
import { ModalOverlay } from './ModalOverlay'
import { Content } from './Content'
import { AnimationStatus, useAnimation } from '../useAnimation'

export type ModalOverlayStyle = 'transparent' | 'blur' | 'light' | 'dark'

export type ModalContentPosition =
  | 'center'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
export type ModalContentVariant =
  | 'default'
  | 'white-rounded'
  | 'rounded'
  | 'gallery'
export type ModalContentSize =
  | 'intrinsic'
  | 'stretch'
  | 'stretch-x'
  | 'stretch-y'

export type ModalTransition =
  | 'fade'
  | 'slide-from-right'
  | 'slide-from-top'
  | 'slide-from-left'
  | 'slide-from-bottom'

export type ContentProps = ComponentPropsWithRef<'div'> & {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  position?: ModalContentPosition
  variant?: ModalContentVariant
  size?: ModalContentSize
  transition: ModalTransition
  status: AnimationStatus
}
export type ModalProps = ReactModal.Props & {
  // Content
  variant?: ModalContentVariant
  position?: ModalContentPosition
  size?: ModalContentSize
  // Overlay
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  // Animations
  transition?: ModalTransition

  // General props - from 'react-modal'
  //
  // isOpen: boolean
  // onBeforeOpen: () => void
  // onAfterOpen: () => void
  // onBeforeClose: () => void
  // onAfterClose: () => void
  // preventScroll: boolean
  // onRequestClose: () => void
}

export const Modal: FC<ModalProps> = ({
  variant = 'default',
  position = 'center',
  size = 'intrinsic',
  overlayCloseButton,
  overlayCloseButtonLabel,
  overlayVariant = 'dark',
  transition = 'fade',
  isOpen,
  onRequestClose,
  ...restReactModalProps
}) => {
  const enteringTime = 400
  const exitingTime = 200
  const status = useAnimation(isOpen, enteringTime, exitingTime)

  const overlayVisualProps = {
    overlayCloseButton,
    overlayCloseButtonLabel,
    overlayVariant,
    exitingTime,
    enteringTime,
    isOpen,
    status,
  }
  const contentVisualProps = {
    variant,
    position,
    size,
    transition,
    onRequestClose,
    status,
  }

  return (
    <ReactModal
      {...restReactModalProps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayElement={(propsWithRef, contentElement) => (
        <ModalOverlay
          {...propsWithRef}
          {...overlayVisualProps}
          hasPadding={position === 'center' && size === 'intrinsic'}
        >
          {contentElement}
        </ModalOverlay>
      )}
      contentElement={(propsWithRef, children) => (
        <Content {...propsWithRef} {...contentVisualProps}>
          {children}
        </Content>
      )}
      closeTimeoutMS={exitingTime}
    />
  )
}

export { ModalOverlay }
