import { FC } from 'react'
import { Media } from '../Media/Media'
import { LibraryItemType } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { Link } from '../Link/Link'
import { useTranslation } from 'react-i18next'
import { formatBytes } from './FormatBytes'

const ContentWrap = styled('div', {
  display: 'grid',
  gridRowGap: '$20',
  justifyItems: 'start',
  paddingTop: '$32',
  paddingBottom: '$4',
})

const Title = styled('h3', {
  $projectFont: 'heading03',
})

const Parameter = styled('p', {
  $projectFont: 'mono01',
})

type LibraryCardProps = LibraryItemType & {
  priority?: boolean
  sizes?: string
}

export const LibraryCard: FC<LibraryCardProps> = ({
  title,
  icon,
  priority,
  sizes,
  file,
}) => {
  const { t } = useTranslation('libraryCard')

  const fileSize = file?.size ? formatBytes(file.size) : null
  const fileExtension = file?.extension ?? null

  return (
    <div>
      {icon && (
        <Media {...icon} priority={priority} sizes={sizes} ratio={'square'} />
      )}
      <ContentWrap>
        {title && <Title as={'h2'}>{title}</Title>}
        {fileSize && (
          <Parameter>
            {t('fileSize')}: {fileSize}
          </Parameter>
        )}
        {fileExtension && <Parameter>{fileExtension}</Parameter>}
        {file && (
          <Link
            appearance="ButtonPrimaryWhite"
            href={file.url + '/' + file.originalFilename}
            target="_blank"
            download={true}
          >
            {t('download')}
          </Link>
        )}
      </ContentWrap>
    </div>
  )
}
