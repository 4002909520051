import React, { FC } from 'react'
import { useForm } from 'local-react-hook-form'
import { useTranslation } from 'react-i18next'
import { captureException, withScope } from '@sentry/nextjs'

import {
  useFormSubmitState,
  FormTextField,
  SubmitButton,
  FormResponseMessage,
} from '@sans-souci/forms'
import { styled } from '@sans-souci/styles'
import { PortableTextBlocks } from '@sans-souci/models'
import { PortableText } from '@sans-souci/components'
import { newsletterSubscribe } from '@sans-souci/services/api-service'

const Form = styled('form', {
  display: 'grid',
  gridRowGap: '$24',
  gridColumnGap: '$24',
  gridTemplateColumns: '1fr',

  '@md': {
    gridTemplateColumns: '1fr 1fr',
  },
})

const Heading = styled('h2', {
  height: '$32',
  $projectFont: 'heading03',
  '@md': {
    gridColumn: '1/3',
  },
})

const SubmitButtonWrap = styled('div', {
  width: 'fit-content',
})

const Disclaimer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  $projectFont: 'body02',
  color: '$grey',

  a: {
    $projectFont: 'body02',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '@md': {
    gridColumn: '1/3',
  },
})

const FormResponseMessageWrap = styled('div', {
  minHeight: '$20',
  marginTop: '$20',
})

const StyledFormTextField = styled(FormTextField, {
  '@md': {
    gridColumn: '1/3',
  },
})

interface NewsletterFormProps {
  heading?: string
  disclaimer?: PortableTextBlocks
  className?: string
  onSubmitSuccess?: () => void
  successMessageChange?: (message: string) => void
  darkTheme?: boolean
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
}

export const NewsletterForm: FC<NewsletterFormProps> = ({
  heading,
  disclaimer,
  className,
  onSubmitSuccess,
  successMessageChange,
  darkTheme = false,
}) => {
  const { t } = useTranslation('forms')
  const form = useForm<FormValues>({
    defaultValues,
  })

  const theme = darkTheme ? 'dark' : 'light'

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    clearErrors,
  } = form

  const {
    invalid,
    success,
    setSuccess,
    setError,
    setFieldError,
    submitMessage,
  } = useFormSubmitState<FormValues>({
    form,
    defaultValues,
    keepValuesOnSuccess: true,
  })

  const onSubmit = async (data: FormValues) => {
    const { email = '', firstName = '', lastName = '' } = data

    clearErrors()

    const subscribeRes = await newsletterSubscribe({
      email,
      firstName,
      lastName,
    }).catch((error: Error) => {
      withScope((scope) => {
        scope.setTransactionName(
          'NewsletterForm: onSubmit - newsletterSubscribe',
        )
        captureException(error)
      })

      setError(t('newsletterForm.error.general'))

      return undefined
    })

    if (!subscribeRes?.ok) {
      subscribeRes?.json().then((data: any) => setError(data.detail))
      return
    }

    const subscribeJson = await subscribeRes
      .json()
      .catch((error: Error): undefined => {
        withScope((scope) => {
          scope.setTransactionName(
            'NewsletterForm: onSubmit - json response parse',
          )
          captureException(error)
        })

        setError(t('newsletterForm.error.general'))

        return undefined
      })

    if (!subscribeJson) {
      return
    }

    if (subscribeJson.error === 'EMAIL_EXISTS') {
      setFieldError('email', t('newsletterForm.error.alreadyExist'))
      return
    }

    if (!subscribeJson.length) {
      setSuccess(t('newsletterForm.successCheckEmail'))
      successMessageChange &&
        successMessageChange(t('newsletterForm.successCheckEmail'))

      return
    }

    setSuccess(t('newsletterForm.success'))
    successMessageChange && successMessageChange(t('newsletterForm.success'))
    return onSubmitSuccess && onSubmitSuccess()
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      name="newsletter-form"
      //ID required for LinkAction
      id={'footer-newsletter-form'}
    >
      {heading && <Heading>{heading}</Heading>}
      <FormTextField
        control={control}
        required
        autoComplete="name"
        name="firstName"
        disabled={isSubmitting}
        theme={theme}
        label={t('newsletterForm.firstNameFieldLabel')}
        //ID required for LinkAction
        id={'footer-newsletter-form-first-field'}
      />

      <FormTextField
        control={control}
        required
        autoComplete="family-name"
        name="lastName"
        disabled={isSubmitting}
        label={t('newsletterForm.lastNameFieldLabel')}
        theme={theme}
      />

      <StyledFormTextField
        control={control}
        required
        autoComplete="email"
        type="email"
        name="email"
        id="email-newsletter"
        disabled={isSubmitting}
        label={t('newsletterForm.emailFieldLabel')}
        theme={theme}
      />

      {disclaimer && (
        <Disclaimer>
          <PortableText value={disclaimer} withStyles={false} />

          <FormResponseMessageWrap>
            {submitMessage && (
              <FormResponseMessage
                invalid={invalid}
                success={success}
                message={submitMessage}
              />
            )}
          </FormResponseMessageWrap>
        </Disclaimer>
      )}

      <SubmitButtonWrap>
        <SubmitButton
          text={t('newsletterForm.submitButtonLabel')}
          successText={t('newsletterForm.submitButtonSuccessLabel')}
          helpText={submitMessage}
          success={success && !isDirty}
          loading={isSubmitting}
        />
      </SubmitButtonWrap>
    </Form>
  )
}
