import { FC } from 'react'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { ShopifyMoneyFieldsFragment } from '@sans-souci/shopify-sdk'

export const PricePrimitive: FC<{
  price?: ShopifyMoneyFieldsFragment
}> = ({ price }) => {
  const { locale } = useRouter()

  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)

  const internationalizedPrice =
    price &&
    new Intl.NumberFormat(`${localeId}-${regionId}`, {
      style: 'currency',
      currency: price.currencyCode,
    }).format(Number(price.amount))
  //.replace(/\.00$/, '') // remove trailing .00

  return <span className={'primitive'}>{internationalizedPrice}</span>
}
