import { forwardRef } from 'react'
import { styled } from '@sans-souci/styles'
import { CheckboxFieldPropsType } from './CheckboxFieldPropsType'
import { MdErrorOutline } from 'react-icons/md'

const Input = styled('input', {
  appearance: 'none',
  position: 'absolute',
  width: 1,
  height: 1,
  opacity: 0,
  borderRadius: 0,
})

const CheckMark = styled('div', {
  margin: '$6',
  position: 'relative',
  border: '1px solid $black',
  width: '$12',
  height: '$12',
  transition: 'opacity 150ms ease-in',
  variants: {
    disabled: {
      true: {
        backgroundColor: '$lightgray',
        border: '1px solid $lightgray',
      },
    },
    isInvalid: {
      true: {
        backgroundColor: '$red500',
      },
    },
    checked: {
      true: {
        backgroundColor: 'currentColor',
      },
    },
  },
})

const Label = styled('label', {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'left',
  $projectFont: 'dev',
  cursor: 'pointer',
  [`${Input}:focus-visible + ${CheckMark}`]: {
    boxShadow: '0 0 $sizes$2 $sizes$2 $colors$lightblue',
    borderColor: '$blue',
  },
  variants: {
    disabled: {
      true: {
        cursor: 'default',
        color: '$lightgray',
      },
    },
    isInvalid: {
      true: {
        color: '$red500',
        [`${Input}:focus-visible + ${CheckMark}`]: {
          boxShadow: '0 1px 0 0 $red500',
        },
      },
    },
  },
})

const HelpText = styled('div', {
  display: 'grid',
  gap: '$2',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  $projectFont: 'dev',
  color: '$red500',
})

export const CheckboxField = forwardRef<
  HTMLInputElement,
  CheckboxFieldPropsType
>((props, ref) => {
  const {
    label,
    onChange,
    disabled,
    isChecked,
    value,
    className,
    helpText,
    id,
    invalid,
    ...rest
  } = props

  const helpId = helpText ? `${id}__hint` : undefined

  return (
    <div className={className}>
      <Label isInvalid={invalid} disabled={disabled}>
        <Input
          {...rest}
          aria-describedby={helpId}
          aria-errormessage={invalid && helpId ? helpId : undefined}
          aria-invalid={invalid}
          type="checkbox"
          onChange={onChange}
          defaultValue={String(disabled)}
          value={value}
          disabled={disabled}
          ref={ref}
        />
        <CheckMark
          disabled={disabled}
          isInvalid={invalid}
          checked={Boolean(value)}
        />
        <span>{label}</span>
      </Label>
      {helpText ? (
        <HelpText id={helpId}>
          <MdErrorOutline />
          {helpText}
        </HelpText>
      ) : null}
    </div>
  )
})
