import { ShopifyCartLineFieldsFragment } from '../dist/generatedSchemaSdk'
import { formatShopifyVariantBasic } from './formatShopifyVariantBasic'

export const formatShopifyCartLine = (
  cartLine: ShopifyCartLineFieldsFragment,
) => ({
  ...cartLine,
  merchandise: formatShopifyVariantBasic(cartLine.merchandise),
})

export type ShopifyResolvedCartLine = ReturnType<typeof formatShopifyCartLine>
