import {
  LocaleId,
  ROUTE_BASE_PRODUCTS,
  ROUTE_BASE_COLLECTIONS,
} from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

const LINK_PAYLOAD_PROJECTION = (localeId: LocaleId) => groq`
  "payload":{
  ...payload[0]{
    _type == 'linkPayloadCollection' => {
      "href": "${ROUTE_BASE_COLLECTIONS}/" + collection->store.slug.current
    },
      _type == 'linkPayloadProduct' => {
      "href": "${ROUTE_BASE_PRODUCTS}/" + product->store.slug.current
    },
     _type == 'linkPayloadSanityCollection' => {
      "href": reference->path.current
    },
    _type == 'linkPayloadRoute' => {
      "href": select(
        defined(query) => route -> path.current + query,
        route -> path.current
      )
    },
    _type == 'linkPayloadProject' => {
      "href": select(
        defined(query) => reference -> path.current + query,
        reference -> path.current
      )
    },
    _type == 'linkPayloadSanityRichProducts' => {
      "href": select(
        defined(query) => reference -> path.current + query,
        reference -> path.current
      )
    },
    _type == 'linkPayloadExternal' => {
      "href": url,
      "target": "_blank"
    },
    _type == 'linkPayloadPhone' => {
      "href": "tel:" + phone
    },
    _type == 'linkPayloadEmail' => {
      "href": "mailto:" + email,
      "target": "_blank"
    },
    _type == 'linkPayloadFile' => {
      "href": file.asset->url + "?dl=",
      "download": true
    },
    _type == 'linkPayloadActionAlert' => {
      "action": {
        "type": _type,
        "payload": {
          alert
        }
      }
    },
      _type == 'linkPayloadActionScrollTo' => {
      "action": {
        "type": _type,
        "payload": {
         target,
        }
      }
    }
  },
  ${coalesceLocaleField('accessibilityLabel', localeId)}
  }
`

export const createLinkProjection = (localeId: LocaleId) => {
  return groq`{
    ${coalesceLocaleField('title', localeId)},
    ${LINK_PAYLOAD_PROJECTION(localeId)}
  }`
}

export const createLinkRawProjection = (localeId: LocaleId) => {
  return groq`{
    ${LINK_PAYLOAD_PROJECTION(localeId)}
  }`
}
