import React, { useEffect, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { Button, ProductVariantPrice } from '@sans-souci/components'
import { useCartContext } from '../useCart'
import { GTMViewCart } from '@sans-souci/services/gtm-service'
import { CartLine } from '../components/CartLine'
import { useTranslation } from 'react-i18next'
import { Close } from '@sans-souci/icons'
import { CheckoutButton } from '../components/CheckoutButton'

const Root = styled('div', {
  height: '100vh',
  width: '100%',
  position: 'relative',
  background: '$black',
  color: '$beige',
  '@md': {
    width: '460px',
  },
})

const TopColumn = styled('div', {
  display: 'grid',
  padding: '$20 $12',
  borderBottom: '1px solid $beige',
})

const StyledCloseButton = styled(Button, {
  display: 'grid',
  placeSelf: 'end',
  cursor: 'pointer',
  padding: '$8',
  right: 0,

  '&:hover': {
    opacity: 0.6,
    transition: 'opacity 0.3s',
  },
})

const Loading = styled('div', {
  $projectFont: 'body02',
  color: '$blue',
})

const CartLayout = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const CartLinesWrap = styled('div', {
  display: 'grid',
  overflowY: 'scroll',
})

const Summary = styled('div', {
  position: 'relative',
  paddingBottom: '$20',
  paddingTop: '$32',
  $projectFont: 'body01',
  marginTop: 'auto',

  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
})

const SummaryInfo = styled('div', {
  $projectFont: 'body01',
  marginTop: '$32',
  marginBottom: '$8',
  display: 'grid',
  textTransform: 'uppercase',
  gridTemplateColumns: '1fr 1fr',

  '& > div': {
    justifySelf: 'end',
  },
})

const LineItemsList = styled('ul', {
  display: 'grid',
  listStyleType: 'none',
})

const EmptyCartMsg = styled('h2', {
  $projectFont: 'heading03',
  padding: '$40 0',
  textAlign: 'center',
})

export const MiniCart = () => {
  const { cart, updateCartLines, closeMiniCart } = useCartContext()
  const { t } = useTranslation('cart')
  const [beforeFirstCartLoad, setBeforeFirstCartLoad] = useState(true)
  const cartReady = Boolean(cart)
  const isCartEmpty = cart?.totalQuantity === 0

  useEffect(() => {
    if (!cart) {
      return
    }
    if (beforeFirstCartLoad) {
      setBeforeFirstCartLoad(false)
    }
    GTMViewCart(
      cart.cost.subtotalAmount.amount,
      cart.cost.subtotalAmount.currencyCode,
      cart.lines,
    )
  }, [cart])

  if (!cart) return null

  return (
    <Root>
      <CartLayout>
        <TopColumn>
          <StyledCloseButton onClick={closeMiniCart}>
            <Close />
          </StyledCloseButton>
        </TopColumn>
        {isCartEmpty && <EmptyCartMsg>{t('emptyCartMessage')}</EmptyCartMsg>}
        <CartLinesWrap>
          {!cartReady && isCartEmpty && <Loading>Loading...</Loading>}
          {cartReady && !isCartEmpty && (
            <LineItemsList>
              {cart?.lines?.map((line) => (
                <li key={line.id}>
                  <CartLine line={line} updateCartLines={updateCartLines} />
                </li>
              ))}
            </LineItemsList>
          )}
        </CartLinesWrap>
        {!isCartEmpty ? (
          <Summary>
            <p>{t('infoText')}</p>

            <SummaryInfo>
              <span>{t('subtotal')}</span>
              <div>
                <ProductVariantPrice price={cart?.cost.totalAmount} />
              </div>
            </SummaryInfo>

            <CheckoutButton />
          </Summary>
        ) : null}
      </CartLayout>
    </Root>
  )
}
