export const sizes = {
  0: '0px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  16: '16px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  72: '72px',
  96: '96px',
  120: '120px',
  144: '144px',
  168: '168px',
  192: '192px',
  240: '240px',
  288: '288px',
  378: '378px',
  416: '416px',
  buttonHeightM: '24px',
  buttonHeightS: '22px',
  buttonHeightL: '32px',
  buttonHeightXL: '56px',
  containerMarginMobile: '20px',
  containerMarginDesktop: '30px',
  maxWidthXXS: '370px',
  maxWidthXS: '420px',
  maxWidthS: '520px',
  maxWidthM: '670px',
  maxWidthL: '800px',
  headerHeightMobile: '75px',
  headerHeightDesktop: '100px',
  minSubmissionsModalWidth: '700px',
  legacy_maxWidthL: '1080px',
} as const
