import { FC, useEffect } from 'react'
import { SanityImage } from './components/SanityImage'
import { MuxVideo } from './components/MuxVideo'
import { styled } from '@sans-souci/styles'
import { MediaProps } from './models/MediaProps'
import { ShopifyImage } from './components/ShopifyImage'
import { ShopifyVideo } from './components/ShopifyVideo'
import { ShopifyExternalVideo } from './components/ShopifyExternalVideo'

const ResponsiveWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  '.hideOnMobile': {
    display: 'none',
  },
  '@md': {
    '.hideOnDesktop': {
      display: 'none',
    },
    '.hideOnMobile': {
      display: 'grid',
    },
  },
  variants: {
    layout: {
      fill: {
        width: '100%',
        height: '100%',
      },
      responsive: {},
      'responsive-cover': {},
    },
  },
})

type MediaAdapterProps = Omit<
  MediaProps,
  'videoOverrideMobile' | 'mediaPayloadMobile'
>

export const MediaAdapter: FC<MediaAdapterProps> = ({
  layout = 'responsive',
  objectFit = 'cover',
  sizes,
  priority,
  mediaPayload,
  loop,
  autoplay,
  muted,
  ratio,
  controlsPosition,
  videoOverride,
  className,
}) => {
  useEffect(() => {
    if (typeof ratio !== 'string' && sizes) {
      console.warn('Do not pass sizes when ratio is object!')
    }
  }, [])

  switch (mediaPayload?.type) {
    case 'sanity-image':
      return (
        <SanityImage
          mediaPayload={mediaPayload}
          sizes={sizes}
          objectFit={objectFit}
          priority={priority}
          layout={layout}
          ratio={ratio}
          className={className}
        />
      )
    case 'mux-video':
      return (
        <MuxVideo
          mediaPayload={mediaPayload}
          layout={layout}
          priority={priority}
          ratio={ratio}
          objectFit={objectFit}
          loop={loop}
          sizes={sizes}
          muted={muted}
          controlsPosition={controlsPosition}
          autoplay={autoplay}
          videoOverride={videoOverride}
          className={className}
        />
      )
    case 'shopify-image':
      return (
        <ShopifyImage
          sizes={sizes}
          objectFit={objectFit}
          mediaPayload={mediaPayload}
          layout={layout}
          priority={priority}
          loop={loop}
          ratio={ratio}
          className={className}
        />
      )
    case 'shopify-media-video': {
      return (
        <ShopifyVideo
          mediaPayload={mediaPayload.video}
          layout={layout}
          priority={priority}
          loop={loop}
        />
      )
    }
    case 'shopify-media-external-video': {
      return (
        <ShopifyExternalVideo
          mediaPayload={mediaPayload.video}
          layout={layout}
          priority={priority}
          loop={loop}
        />
      )
    }

    default:
      return <div>Unsupported media source</div>
  }
}

export const Media: FC<MediaProps> = ({
  mediaPayload,
  mediaPayloadMobile,
  layout,
  videoOverride,
  videoOverrideMobile,
  ...restOfProps
}) => {
  if (!mediaPayload) {
    return null
  }
  return (
    <ResponsiveWrap layout={layout}>
      <MediaAdapter
        mediaPayload={mediaPayload}
        layout={layout}
        {...restOfProps}
        videoOverride={videoOverride}
        className={mediaPayloadMobile ? 'hideOnMobile' : ''}
      />
      {mediaPayloadMobile && (
        <MediaAdapter
          mediaPayload={mediaPayloadMobile}
          layout={layout}
          {...restOfProps}
          videoOverride={videoOverrideMobile}
          className={'hideOnDesktop'}
        />
      )}
    </ResponsiveWrap>
  )
}
