import { forwardRef, PropsWithChildren, FC } from 'react'
import { styled } from '@sans-souci/styles'

type IconProps = {
  className?: string
  isActive?: boolean
}

const Icon: FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10 0.96582C4.48929 0.96582 0 5.45511 0 10.9658C0 16.4765 4.48929 20.9658 10 20.9658C15.5107 20.9658 20 16.4765 20 10.9658C20 5.45511 15.5189 0.96582 10 0.96582ZM15.8814 11.3447H4.11862C3.91269 11.3447 3.7397 11.1718 3.7397 10.9658C3.7397 10.7599 3.91269 10.5869 4.11862 10.5869H15.8896C16.0956 10.5869 16.2685 10.7599 16.2685 10.9658C16.2685 11.1718 16.0956 11.3447 15.8896 11.3447H15.8814Z"
      fill="black"
      id="open-path"
    />

    <path
      d="M10 20.9658C4.48929 20.9658 0 16.4848 0 10.9658C0 5.44688 4.48929 0.96582 10 0.96582C15.5107 0.96582 20 5.45511 20 10.9658C20 16.4765 15.5107 20.9658 10 20.9658ZM10 1.72365C4.90115 1.72365 0.757825 5.86697 0.757825 10.9658C0.757825 16.0647 4.90939 20.208 10 20.208C15.0906 20.208 19.2504 16.0564 19.2504 10.9658C19.2504 5.87521 15.0988 1.72365 10 1.72365Z"
      fill="black"
      id="closed-path"
    />
    <path
      d="M15.8897 10.587H10.379V5.08448C10.379 4.87855 10.206 4.70557 10 4.70557C9.79411 4.70557 9.62113 4.87855 9.62113 5.08448V10.5952H4.11866C3.91273 10.5952 3.73975 10.7682 3.73975 10.9741C3.73975 11.18 3.91273 11.353 4.11866 11.353H9.62937V16.8637C9.62937 17.0697 9.80235 17.2426 10.0083 17.2426C10.2142 17.2426 10.3872 17.0697 10.3872 16.8637V11.353H15.8979C16.1038 11.353 16.2768 11.18 16.2768 10.9741C16.2768 10.7682 16.1038 10.5952 15.8979 10.5952L15.8897 10.587Z"
      id="closed-path-2"
    />
  </svg>
)

const StyledIcon = styled(Icon, {
  '>path': {
    fill: '$black',
  },
  variants: {
    isActive: {
      true: {
        '#open-path': {
          visibility: 'visible',
        },
        '#closed-path': {
          visibility: 'hidden',
        },
        '#closed-path-2': {
          visibility: 'hidden',
        },
      },
      false: {
        '#open-path': {
          visibility: 'hidden',
        },
        '#closed-path': {
          visibility: 'visible',
        },
        '#closed-path-2': {
          visibility: 'visible',
        },
      },
    },
  },
})

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid $black',
  width: '100%',
  cursor: 'pointer',
})

const Label = styled('h4', {
  $projectFont: 'heading03',
  padding: '$16 0',
})

type HeaderProps = PropsWithChildren<{
  isActive: boolean
  onClick?: () => void
}>

export const AccordionHeaderDefault = forwardRef<HTMLDivElement, HeaderProps>(
  ({ isActive, onClick, children }, ref) => {
    return (
      <Container onClick={onClick} ref={ref}>
        <Label>{children}</Label>
        <StyledIcon isActive={isActive} />
      </Container>
    )
  },
)
