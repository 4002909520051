import { styled } from '@sans-souci/styles'
import { SectionWrap } from '@sans-souci/components'
import { FC } from 'react'
import { LoginForm } from '../../forms/LoginForm'
import { useTranslation } from 'react-i18next'
import { RegisterForm } from '../../forms/RegisterForm'
import { RecoverPasswordForm } from '../../forms/RecoverPasswordForm'
import { ActivateAccountForm } from '../../forms/ActivateAccountForm'
import { ResetPasswordForm } from '../../forms/ResetPasswordForm'

const Root = styled('div', {
  paddingTop: '$headerHeightMobile',
  '@md': {
    paddingTop: '$headerHeightDesktop',
  },
})

const Container = styled('div', {
  borderBlock: '1px solid $black',
  display: 'grid',
  paddingBlock: '$64',
  gap: '$20',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: 0,
    gap: 0,
  },
})

const TextContainer = styled('div', {
  display: 'grid',
  gap: '$16',
  paddingBlock: '$40',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
  '@lg': {
    borderRight: '1px solid $black',
  },
})

const Heading = styled('h1', {
  $projectFont: 'heading01',
  fontSize: 60,
  '@lg': {
    fontSize: 72,
  },
  '@xl': {
    $projectFont: 'heading01',
  },
})

const FormsWrap = styled('div', {
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumn: '1fr',
})

const FormsContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  width: '100%',
  '@lg': {
    minHeight: '70vh',
  },
})

export const PreLogin: FC<{
  formType: 'activate' | 'login' | 'register' | 'forgotPassword' | 'reset'
}> = ({ formType }) => {
  const { t } = useTranslation('account')

  if (!formType) return null

  const heading = (() => {
    switch (formType) {
      case 'activate':
        return t('activateAccountPage.heading')
      case 'login':
        return t('loginPage.loginHeading')
      case 'register':
        return t('registerPage.registerHeading')
      case 'forgotPassword':
        return t('forgotPasswordPage.heading')
      case 'reset':
        return t('resetPasswordPage.heading')
      default:
        return null
    }
  })()

  return (
    <SectionWrap>
      <Root>
        <Container>
          <TextContainer>
            <Heading>{heading}</Heading>
          </TextContainer>
          <FormsWrap>
            <FormsContainer>
              {(() => {
                switch (formType) {
                  case 'activate':
                    return <ActivateAccountForm />
                  case 'login':
                    return <LoginForm />
                  case 'register':
                    return <RegisterForm />
                  case 'forgotPassword':
                    return <RecoverPasswordForm />
                  case 'reset':
                    return <ResetPasswordForm />
                  default:
                    return null
                }
              })()}
            </FormsContainer>
          </FormsWrap>
        </Container>
      </Root>
    </SectionWrap>
  )
}
