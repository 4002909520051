import React, { FC } from 'react'

type Props = {
  className?: string
}

export const ArrowUp: FC<Props> = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 0.707107L6 0L6.70711 0.707108L12 6L11.2929 6.70711L6 1.41421L0.707107 6.70711L0 6L5.29289 0.707107Z"
        fill="currentColor"
      />
    </svg>
  )
}
