import { FC } from 'react'
import { SelectField } from './SelectField/SelectField'
import { useController, UseControllerProps } from 'local-react-hook-form'
import { SelectFieldPropsType } from './SelectField/SelectFieldPropsType'

type FormSelectFieldProps = UseControllerProps<any> & SelectFieldPropsType

export const FormSelectField: FC<FormSelectFieldProps> = ({
  rules,
  control,
  name,
  helpText,
  children,
  shouldUnregister,
  defaultValue,
  ...restOfProps
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    shouldUnregister,
    defaultValue,
    rules,
  })

  return (
    <SelectField
      id={name}
      helpText={helpText || error?.message}
      invalid={invalid}
      {...field}
      {...restOfProps}
    >
      {children}
    </SelectField>
  )
}
