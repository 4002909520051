export const DeleteIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.38 6H7.04C6.45 6 5.89 6.21 5.43 6.59L2.69 9.2C2.24 9.6 2 10.13 2 10.7C2 11.27 2.25 11.8 2.69 12.2L5.45 14.82C5.89 15.19 6.45 15.4 7.04 15.4H16.38C17.67 15.4 18.73 14.45 18.73 13.28V8.12C18.73 6.95 17.68 6 16.38 6ZM17.33 13.28C17.33 13.73 16.89 14.11 16.38 14.11H7.04C6.81 14.11 6.57 14.03 6.41 13.89L3.66 11.28C3.49 11.13 3.39 10.92 3.39 10.7C3.39 10.48 3.48 10.28 3.66 10.12L6.4 7.52C6.58 7.37 6.81 7.29 7.05 7.29H16.38C16.9 7.29 17.33 7.67 17.33 8.12V13.28Z"
      fill="currentColor"
    />
    <path
      d="M14.78 10.7L16.49 8.96C16.74 8.71 16.74 8.3 16.49 8.05C16.25 7.81 15.83 7.81 15.58 8.05L13.87 9.78L12.16 8.05C11.92 7.81 11.5 7.81 11.25 8.05C11 8.3 11 8.71 11.25 8.96L12.96 10.7L11.25 12.44C11 12.69 11 13.1 11.25 13.35C11.49 13.59 11.91 13.59 12.16 13.35L13.87 11.62L15.59 13.35C15.83 13.59 16.25 13.59 16.5 13.35C16.75 13.1 16.75 12.69 16.5 12.44L14.79 10.7H14.78Z"
      fill="currentColor"
    />
  </svg>
)
