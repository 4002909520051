import { FC } from 'react'

export const ArrowRight: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="171"
    height="8"
    viewBox="0 0 171 8"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M170.854 4.35355C171.049 4.15829 171.049 3.84171 170.854 3.64645L167.672 0.464466C167.476 0.269204 167.16 0.269204 166.964 0.464466C166.769 0.659728 166.769 0.976311 166.964 1.17157L169.793 4L166.964 6.82843C166.769 7.02369 166.769 7.34027 166.964 7.53553C167.16 7.7308 167.476 7.7308 167.672 7.53553L170.854 4.35355ZM0.5 4.5H170.5V3.5H0.5V4.5Z"
      fill="currentColor"
    />
  </svg>
)
