const FONT_SANS_LIGHT = {
  fontFamily: '$sans',
  fontWeight: 300,
}
const FONT_SANS_BOLD = {
  fontFamily: '$sans',
  // fontWeight: 700,
  fontVariationSettings: `"opsz" 18.0,"wght" 700.0,"slnt" 0.0,"ital" 0.0`,
}
const FONT_MONO = {
  fontFamily: '$mono',
  fontWeight: 300,
}

export const fonts = {
  heading01: {
    fontSize: 100,
    textTransform: 'uppercase',
    ...FONT_SANS_BOLD,
    lineHeight: 0.9,
    '@md': {
      lineHeight: 0.75,
    },
  },
  heading02: {
    lineHeight: 1,
    textTransform: 'uppercase',
    ...FONT_SANS_BOLD,
    $lin: {
      fontSize: [25, 40],
    },
  },
  heading03: {
    lineHeight: 1.1,
    textTransform: 'uppercase',
    ...FONT_SANS_BOLD,
    fontSize: 16,
  },
  heading04: {
    lineHeight: 1.66,
    textTransform: 'uppercase',
    ...FONT_SANS_LIGHT,
    fontSize: 15,
  },
  nav01: {
    lineHeight: 1,
    textTransform: 'uppercase',
    ...FONT_SANS_BOLD,
    $lin: {
      fontSize: [35, 60],
    },
  },
  body01: {
    fontSize: 15,
    lineHeight: 1.45,
    ...FONT_SANS_LIGHT,
  },
  body02: {
    fontSize: 12,
    lineHeight: 1.66,
    ...FONT_SANS_LIGHT,
  },
  mono01: {
    fontSize: 13,
    lineHeight: 1.1,
    textTransform: 'uppercase',
    ...FONT_MONO,
  },
  dev: {
    fontSize: 16,
    fontFamily:
      'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;',
  },
}

export type FontType = keyof typeof fonts

export const getFontStyles = (fontType: FontType) => fonts[fontType]
