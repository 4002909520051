import { styled } from '@sans-souci/styles'
import { FC, PropsWithChildren } from 'react'

const Grid = styled('div', {
  display: 'grid',
  gridColumnGap: '$6',
  gridRowGap: '$24',
  gridTemplateColumns: 'repeat( auto-fill, minmax(300px, 1fr) )',
})

export const ProductGrid: FC<
  PropsWithChildren<{
    className?: string
  }>
> = ({ children, className }) => {
  return <Grid className={className}>{children}</Grid>
}
