import { FC } from 'react'
import { keyframes, styled } from '@sans-souci/styles'

const Wrap = styled('div', {
  width: '100%',
  maxWidth: '100%',
  display: 'grid',
  height: '1px',
  overflow: 'hidden',
  variants: {
    size: {
      small: {
        width: '80px',
      },
      medium: {
        width: '140px',
      },
      default: {
        width: '100%',
      },
    },
  },
})

const progressAnimation = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '45%': { transform: 'translateX(0%)' },
  '55%': { transform: 'translateX(0%)' },
  '100%': { transform: 'translateX(100%)' },
})

const ProgressBar = styled('div', {
  width: '100%',
  backgroundColor: 'currentcolor',
  animation: `${progressAnimation} 1000ms ease-in-out infinite`,
})

export const Loader: FC<{ size?: 'small' | 'medium' | 'default' }> = ({
  size,
}) => {
  return (
    <Wrap size={size}>
      <ProgressBar />
    </Wrap>
  )
}
