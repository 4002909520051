import { useEffect } from 'react'
import { useCustomerContext } from './customerContext'
import { useRouter } from 'next/router'
import {
  ROUTE_BASE_ACCOUNT,
  ROUTE_ACCOUNT_LOGIN,
  ROUTE_ACCOUNT_REGISTER,
} from '@sans-souci/configs'

export const useAuthRedirect = () => {
  const RETURN_PATH_QUERY_KEY = 'return_to'
  const { isLoggedIn, isVerified } = useCustomerContext()
  const { push, pathname, query } = useRouter()
  const returnPath = query?.[RETURN_PATH_QUERY_KEY] || undefined

  useEffect(() => {
    const isLoginPage = pathname === ROUTE_ACCOUNT_LOGIN
    const isRegisterPage = pathname === ROUTE_ACCOUNT_REGISTER
    const isAccountPage = pathname === ROUTE_BASE_ACCOUNT

    if (!isVerified) {
      return
    }

    /**
     * User logged In
     */

    // redirect to return path if available
    if (isLoggedIn && isLoginPage && returnPath) {
      push({ pathname: String(returnPath) })
      return
    }

    // otherwise redirect to account page
    if (isLoggedIn && !isAccountPage) {
      push({ pathname: ROUTE_BASE_ACCOUNT })
      return
    }

    /**
     * User not logged In
     */

    // redirect to login page
    if (!isLoggedIn && !isLoginPage && !isRegisterPage) {
      push({ pathname: ROUTE_ACCOUNT_LOGIN })
      return
    }
  }, [push, isLoggedIn, returnPath, pathname, isVerified])
}
