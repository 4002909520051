var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8f8144ef113b18904f71567c95e0285e702e3be6"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT

Sentry.init({
  enabled: SENTRY_ENV !== 'development',
  environment: SENTRY_ENV,
  dsn:
    SENTRY_DSN ||
    'https://7e3f8c19852344c3b008a62a8f95d41b@o4504871327236096.ingest.sentry.io/4504892908371968',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.tags?.sampleRate === 'low') {
      return 0.1
    }
    if (samplingContext.transactionContext.tags?.sampleRate === 'medium') {
      return 0.2
    }
    return 1
  },
})
