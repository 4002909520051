import {
  ShopifyGetProductExtendedQueryVariables,
  createShopifySdk,
  formatShopifyProductExtended,
} from '@sans-souci/shopify-sdk'
import { createShopifyClient } from './createShopifyClient'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'

export const getShopifyProduct = async (
  variables: ShopifyGetProductExtendedQueryVariables,
  locale: string,
) => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)

  const shopifyClient = createShopifyClient(localeId)

  return await createShopifySdk(shopifyClient)
    .getProductExtended(variables)
    .then((res) => {
      const product = res.data.product
      return product ? formatShopifyProductExtended(product) : null
    })
}
