import { FC } from 'react'

export const ArrowLeftShort: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.32843 7.53553L0.146446 4.35355C-0.0488151 4.15829 -0.0488151 3.84171 0.146446 3.64644L3.32843 0.464463C3.52369 0.269201 3.84027 0.269201 4.03553 0.464463C4.2308 0.659725 4.2308 0.976308 4.03553 1.17157L1.70711 3.5L18.5 3.5L18.5 4.5L1.70711 4.5L4.03553 6.82842C4.2308 7.02369 4.2308 7.34027 4.03553 7.53553C3.84027 7.73079 3.52369 7.73079 3.32843 7.53553Z"
      fill="currentColor"
    />
  </svg>
)
