import { SiteConfiguration } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import React, { FC } from 'react'
import {
  AnimationStatus,
  Button,
  Link,
  ModalOverlay,
  NavTrigger,
} from '@sans-souci/components'
import ReactModal from 'react-modal'
import { useCustomerContext } from '@sans-souci/account'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_ACCOUNT_LOGIN,
  ROUTE_ACCOUNT_REGISTER,
  ROUTE_LIBRARY,
} from '@sans-souci/configs'

type NavModalProps = {
  header: SiteConfiguration['header']
  onCloseRequest: () => void
  isOpen: boolean
  enterTime: number
  exitTime: number
  status: AnimationStatus
}

const ContentElement = styled('div', {
  display: 'grid',
  justifyItems: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
})
const Background = styled('div', {
  zIndex: -1,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(90deg, $blue 0%, $white 100%)',
  variants: {
    status: {
      entering: {
        transition: `all calc($$enteringTime*4)`,
      },
      entered: {
        transition: `all calc($$enteringTime*4)`,
      },
      exiting: {
        transition: `all calc($$exitingTime*4)`,
        // opacity: 0,
      },
      exited: {
        // opacity: 0,
      },
    },
  },
})
const ClipMask = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  height: '100%',
  // background: 'black',
  variants: {
    status: {
      entering: {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        transition: `all $$enteringTime cubic-bezier(.2,.4,.2,1)`,
      },
      entered: {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
      },
      exiting: {
        clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
        transition: `all $$exitingTime cubic-bezier(.2,.4,.2,1)`,
      },
      exited: {
        clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
      },
    },
  },
})
const NavTriggerWrap = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  display: 'grid',
  pointerEvents: 'none',
  height: '$headerHeightMobile',
  '@md': {
    height: '$headerHeightDesktop',
  },
})
const ContentElementRoot = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  height: '100%',
})
const MainWrap = styled('div', {
  display: 'grid',
  paddingTop: '$headerHeightMobile',
  paddingBottom: '$32',
  margin: '0 $containerMarginMobile',
  gridTemplateRows: '1fr auto',
  '@md': {
    height: '100vh',
    paddingTop: '$headerHeightDesktop',
    paddingBottom: '$40',
    margin: '0 $containerMarginDesktop',
  },
  variants: {
    status: {
      entering: {
        transition:
          'all calc($$enteringTime*2) cubic-bezier(.2,.4,.2,1), opacity calc($$enteringTime*3)',
        transitionDelay: 'calc($$enteringTime/4)',
      },
      entered: {
        transition:
          'all calc($$enteringTime*2) cubic-bezier(.2,.4,.2,1), opacity calc($$enteringTime*3)',
        transitionDelay: 'calc($$enteringTime/4)',
      },
      exiting: {
        // transition: 'all $$enteringTime',
        // opacity: 0,
      },
      exited: {
        // opacity: 0,
        // transform: 'scale(1.06)',
      },
    },
  },
})

const PrimaryNavigationWrap = styled('div', {
  display: 'grid',
  alignContent: 'start',
  justifyContent: 'start',
  gap: '$16',
  '@md': {
    gap: '$24',
  },
})
const SecondaryNavigationWrap = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$16',
  $projectFont: 'mono01',
})
const PrimaryLink = styled(Link, {
  $focusStyle: 'default',
  $projectFont: 'nav01',
})
const LinkTransitionWrap = styled('span', {
  // transitionTimingFunction: `transform cubic-bezier(.2,.4,.2,1)`,
  variants: {
    status: {
      entering: {
        transition:
          'transform calc($$enteringTime*1) cubic-bezier(.2,.4,.2,1), opacity calc($$enteringTime*1.5)',
      },
      entered: {
        transition:
          'transform calc($$enteringTime*1) cubic-bezier(.2,.4,.2,1), opacity calc($$enteringTime*1.5)',
      },
      exiting: {
        transition: 'all $$enteringTime',
        opacity: 0,
      },
      exited: {
        opacity: 0,
        transform: 'translateY(-30px)',
      },
    },
  },
})

const StyledButton = styled(Button, {
  textTransform: 'uppercase',
})

export const NavModal: FC<NavModalProps> = ({
  onCloseRequest,
  header,
  isOpen,
  enterTime,
  exitTime,
  status,
}) => {
  const { isLoggedIn, isVerified, isPartner, removeToken } =
    useCustomerContext()

  const { t } = useTranslation('header')
  if (!header) return null
  const { links, topLinks } = header
  const isLoggedInUser = isLoggedIn && isVerified

  function logout() {
    removeToken()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      overlayElement={(propsWithRef, contentElement) => (
        <ModalOverlay
          {...propsWithRef}
          enteringTime={enterTime}
          exitingTime={exitTime}
          status={status}
          overlayVariant={'dark'}
        >
          {contentElement}
        </ModalOverlay>
      )}
      contentElement={({ style, className, ...propsWithRef }, element) => (
        <ContentElement {...propsWithRef}>
          <NavTriggerWrap>
            <NavTrigger status={status} onClick={onCloseRequest} />
          </NavTriggerWrap>
          <ClipMask status={status}>
            <Background status={status} />
            <ContentElementRoot>{element}</ContentElementRoot>
          </ClipMask>
        </ContentElement>
      )}
      closeTimeoutMS={exitTime}
    >
      <MainWrap status={status}>
        <PrimaryNavigationWrap>
          {topLinks &&
            topLinks.map((link, i) => (
              <LinkTransitionWrap
                key={link.title}
                status={status}
                style={{
                  transitionDelay: `${i * 50}ms`,
                }}
              >
                <PrimaryLink {...link.payload}>{link.title}</PrimaryLink>
              </LinkTransitionWrap>
            ))}
        </PrimaryNavigationWrap>
        <SecondaryNavigationWrap>
          {links?.map((link) => (
            <Link {...link.payload} key={link.title}>
              {link.title}
            </Link>
          ))}
          {!isLoggedInUser && (
            <Link href={ROUTE_ACCOUNT_LOGIN}>{t('loginButtonLabel')}</Link>
          )}
          {!isLoggedInUser && (
            <Link href={ROUTE_ACCOUNT_REGISTER}>
              {t('registerButtonLabel')}
            </Link>
          )}
          {isLoggedInUser && isPartner && (
            <Link href={ROUTE_LIBRARY}>{t('libraryButtonLabel')}</Link>
          )}
          {isLoggedInUser && (
            <StyledButton onClick={logout}>
              {t('logoutButtonLabel')}
            </StyledButton>
          )}
        </SecondaryNavigationWrap>
      </MainWrap>
    </ReactModal>
  )
}
