import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@sans-souci/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createPortableTextProjection } from '../projections/createPortableTextProjection'

export const createSectionHalvesRichProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
        mediaPosition,
        media${MEDIA_PROJECTION},
      }
  `
}
