import { useState, useEffect, createContext, FC, ReactNode } from 'react'
import { useRouter } from 'next/router'

import { captureException } from '@sentry/nextjs'
import {
  errorDebugMessage,
  getLocaleRegionIdFromPath,
  logDebugMessage,
} from '@sans-souci/utils'
import {
  ShopifyMutationCartLinesAddArgs,
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyResolvedCart,
  ShopifyResolvedCartLine,
} from '@sans-souci/shopify-sdk'
import {
  createCartClient,
  equalAttributes,
} from '@sans-souci/services/shopify-service'

export type CartContextType = {
  cart: ShopifyResolvedCart | null
  miniCartState: {
    isOpen: boolean
  }
  processing: boolean
  quantity: number
  addCartLines: (
    lines: ShopifyMutationCartLinesAddArgs['lines'],
  ) => Promise<ShopifyResolvedCartLine[] | undefined> | undefined
  removeCartLines: (ids: string[]) => void
  updateCartLines: (lines: ShopifyMutationCartLinesUpdateArgs['lines']) => void
  updateCartNote: (note: string) => void
  openMiniCart: (title?: string) => void
  closeMiniCart: () => void
}

export const CartContext = createContext<CartContextType>({
  cart: null,
  miniCartState: {
    isOpen: false,
  },
  processing: false,
  quantity: 0,
  openMiniCart: () => undefined,
  closeMiniCart: () => undefined,
  addCartLines: () => undefined,
  removeCartLines: () => undefined,
  updateCartLines: () => undefined,
  updateCartNote: () => undefined,
})

const createLsCartIdService = (regionId: string) => {
  const LOCAL_STORAGE_CART_ID_KEY = `cartId_${regionId}`
  return {
    get: () => localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY),
    set: (id: string) => localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, id),
    remove: () => localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY),
  }
}

const toast = (msg: string) => {
  alert(msg)
}
export const CartProvider: FC<{
  children?: ReactNode
}> = ({ children }) => {
  const { locale } = useRouter()
  const [cart, setCart] = useState<ShopifyResolvedCart>(null)
  const [linesQuantity, setLinesQuantity] = useState<number>(0)

  const [miniCartState, setMiniCartState] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })

  const [processing, setProcessing] = useState(false)

  const regionId = getLocaleRegionIdFromPath(locale)[0]
  const lsCartId = createLsCartIdService(regionId)

  const cartClient = createCartClient({
    lang: locale,
    cartId: cart?.id || null,
  })

  const __fetchOrCreateCart = async () => {
    setProcessing(true)
    const LSCartId = lsCartId.get()

    try {
      const cartRes = LSCartId
        ? await cartClient.fetch(LSCartId)
        : await cartClient.create()
      __resolveCartOperation(cartRes)
    } catch (e) {
      // we catch cartClient errors of fetchOrCreate without passing res to resolver
      // when function hits the retry limit we show the toast
      toast(
        `We couldn't prepare the cart for You. Please refresh the browser and try again.`,
      )
      const err = new Error(
        `[fetchOrCreateCart] Reached retry limit. ${JSON.stringify(e)}`,
      )
      errorDebugMessage(err)
      captureException(err)
      return
    }
  }

  const __resolveCartOperation = (cartRes: ShopifyResolvedCart) => {
    if (cartRes) {
      // proper cart
      logDebugMessage('[refreshCart] FRESH CART RECEIVED. updating state...')
      setCart(cartRes)
      setLinesQuantity(cartRes?.totalQuantity || 0)
      setProcessing(false)
      lsCartId.set(cartRes.id)
    }
    if (!cartRes) {
      // empty cart
      logDebugMessage('[refreshCart] EMPTY CART RECEIVED. recreating...')
      setCart(null)
      setLinesQuantity(0)
      lsCartId.remove()
      __fetchOrCreateCart()
    }
    // if (cartOperationRes.status === 'ERROR') {
    //   // cartClient returned error
    //   const err = new Error(JSON.stringify(cartOperationRes.errors))
    //   errorDebugMessage(err)
    //   captureException(err)
    //   toast(
    //     `Sorry, we couldn't complete your request. Please refresh the browser and try again.`,
    //   )
    // }
  }

  const __revalidateCart = async (
    fn: () => Promise<ShopifyResolvedCartLine[] | void> | void,
  ) => {
    setProcessing(true)
    if (!lsCartId.get() || !cart) {
      await __fetchOrCreateCart()
      await fn()
    }
  }

  const __reloadCart = async () => {
    await __fetchOrCreateCart()
  }

  const addCartLines: CartContextType['addCartLines'] = async (newLines) => {
    logDebugMessage('[addCartLines] ADD LINES')
    await __revalidateCart(() => addCartLines(newLines))
    const res = await cartClient.addLines(newLines)

    if (res) {
      const updatedCart = res
      const addedLines = newLines.reduce(
        (acc: ShopifyResolvedCartLine[], line) => {
          const matchedLine = updatedCart?.lines?.find((l) => {
            return (
              l.merchandise.id === line.merchandiseId &&
              equalAttributes(l.attributes, line.attributes)
            )
          })
          return matchedLine ? [...acc, matchedLine] : acc
        },
        [],
      )

      __resolveCartOperation(res)

      if (addedLines.length === newLines.length) {
        return addedLines
      } else {
        const err = new Error(
          `[addCartLines] Added lines not found in incoming cart, lines: ${JSON.stringify(
            newLines,
          )}, cart: ${JSON.stringify(updatedCart)}`,
        )
        captureException(err)
        return undefined
      }
    }
    __resolveCartOperation(res)
    return undefined
  }

  const removeCartLines: CartContextType['removeCartLines'] = async (ids) => {
    logDebugMessage('[removeCartLines] REMOVE LINES')
    await __revalidateCart(() => removeCartLines(ids))
    const res = await cartClient.removeLines(ids)
    __resolveCartOperation(res)
  }

  const updateCartLines: CartContextType['updateCartLines'] = async (lines) => {
    logDebugMessage('[updateCartLines] UPDATE LINES')
    await __revalidateCart(() => updateCartLines(lines))
    const res = await cartClient.updateLines(lines)
    __resolveCartOperation(res)
  }

  const updateCartNote: CartContextType['updateCartNote'] = async function (
    note: string,
  ) {
    logDebugMessage('[updateCartNote] UPDATE NOTE')
    await __revalidateCart(() => updateCartNote(note))
    const res = await cartClient.updateNote(note)
    __resolveCartOperation(res)
  }

  const openMiniCart = () =>
    setMiniCartState({
      isOpen: true,
    })

  const closeMiniCart = () =>
    setMiniCartState({
      isOpen: false,
    })
  //refresh cart on focus
  useEffect(() => {
    __reloadCart()
    window.addEventListener('focus', __reloadCart)
    return () => window.removeEventListener('focus', __reloadCart)
  }, [locale])

  return (
    <CartContext.Provider
      value={{
        cart,
        processing,
        miniCartState,
        addCartLines,
        openMiniCart,
        closeMiniCart,
        removeCartLines,
        updateCartLines,
        quantity: linesQuantity,

        updateCartNote,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
