import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@sans-souci/models'
import { createDefaultPageProjection } from './projections/createDefaultPageProjection'
import { createSanityCollectionPageProjection } from './projections/createSanityCollectionPageProjection'
import { createSanityCollectionListingPageProjection } from './projections/createSanityCollectionListingPageProjection'
import { createProjectPageProjection } from './projections/createProjectPageProjection'
import { createProjectListingPageProjection } from './projections/createProjectListingPageProjection'
import { createLibraryListingPageProjection } from './projections/createLibraryListingPageProjection'
import { createSanityRichProductListingPageProjection } from './projections/createSanityRichProductListingPageProjection'
import { createSanityRichProductPageProjection } from './projections/createSanityRichProductPageProjection'

export const getPage = async (
  path: string,
  localeRegion: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegion)

  const pageType = await getClient(preview).fetch(
    groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == $path][0]{_type}._type`,
    {
      path,
    },
  )

  const PAGE_PROJECTION = (() => {
    switch (pageType) {
      case 'project':
        return createProjectPageProjection(regionId, localeId)
      case 'projectListingPage':
        return createProjectListingPageProjection(regionId, localeId)
      case 'sanityCollection':
        return createSanityCollectionPageProjection(regionId, localeId)
      case 'sanityRichProduct':
        return createSanityRichProductPageProjection(regionId, localeId)
      case 'libraryListingPage':
        return createLibraryListingPageProjection(regionId, localeId)
      case 'sanityCollectionListingPage':
        return createSanityCollectionListingPageProjection(regionId, localeId)
      case 'sanityRichProductListingPage':
        return createSanityRichProductListingPageProjection(regionId, localeId)
      default:
        return createDefaultPageProjection(regionId, localeId)
    }
  })()

  const query = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == $path][0]{
    "page": ${PAGE_PROJECTION}
  }.page`

  return await getClient(preview).fetch(query, {
    path,
  })
}
