import { FC } from 'react'

export const ArrowLeft: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="171"
    height="8"
    viewBox="0 0 171 8"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.32843 7.53542L0.146439 4.35344C-0.0488126 4.15818 -0.0488125 3.8416 0.146439 3.64634L3.32843 0.464356C3.52368 0.269093 3.84027 0.269093 4.03554 0.464356C4.23079 0.659618 4.23079 0.976201 4.03554 1.17146L1.70711 3.49989L170.5 3.4999L170.5 4.4999L1.70711 4.49989L4.03554 6.82832C4.23079 7.02358 4.23079 7.34016 4.03554 7.53542C3.84027 7.73069 3.52368 7.73069 3.32843 7.53542Z"
      fill="currentColor"
    />
  </svg>
)
