import { FC } from 'react'
import { styled } from '@sans-souci/styles'

const Root = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$20',
  height: '$20',
  backgroundColor: '$black',
  borderRadius: '$rMax',
  color: '$white',
})

const Number = styled('span', {
  $projectFont: 'mono01',
  lineHeight: 1,
  marginTop: '1px',
})

type CounterProps = {
  number?: number
}

export const Counter: FC<CounterProps> = ({ number }) => {
  if (!number) return null

  return (
    <Root>
      <Number>{number}</Number>
    </Root>
  )
}
