import { LocaleId, RegionId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSEOProjection } from './createSEOProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createSanityImageProjection } from './createMediaProjection'

export const createLibraryListingPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const IMAGE_PROJECTION = createSanityImageProjection()

  return groq`{
      ...,
      "pageBuilder": [
        {
          "_type": "sectionLibraryListing",
          "_key": "sectionLibraryListing",
          "libraryItems": *[ !(_id in path("drafts.**")) && _type == "libraryItem"] {
            _id,
            ${coalesceLocaleField('title', localeId)},
            icon${IMAGE_PROJECTION},
            "releaseDate": dateAdded,
            "file": file.asset->{
                _id,
                url,
                extension,
                originalFilename,
                size
            },
            "params": {
                "type": type -> {
                  ${coalesceLocaleField('title', localeId)},
                }.title,
               "tags": tags[] -> {
                  ${coalesceLocaleField('title', localeId)},
                }.title,  
            },
          }
        },
        ...pageBuilder[]${PAGE_BUILDER_PROJECTION},
      ],
      "background": "light",
      "headerColor": "dark",
      "breadcrumbs": [
        {
          "loginInfo": true
        }
      ],
      seo${createSEOProjection(localeId)},
    }`
}
