import { Dispatch, FC, SetStateAction } from 'react'
import { UpdateAddressForm } from '../../../forms/UpdateAddressForm'
import { useCustomerContext } from '../../../customerContext'
import { AccountCardWrapper } from '../components/AccountCardWrapper'
import { ShopifyMailingAddressFieldsFragment } from '@sans-souci/shopify-sdk'

export const EditAddress: FC<{
  title?: string
  setIsEditVisible: Dispatch<SetStateAction<boolean>>
  address: ShopifyMailingAddressFieldsFragment
  isDefault: boolean
}> = ({ title = '', setIsEditVisible, address, isDefault }) => {
  const { updateCustomer } = useCustomerContext()

  const handleSuccess = async () => {
    await updateCustomer()
    setIsEditVisible(false)
  }

  return (
    <AccountCardWrapper title={title}>
      <UpdateAddressForm
        defaultValues={address}
        onCancelClick={() => setIsEditVisible(false)}
        onSubmitSuccess={handleSuccess}
        isDefault={isDefault}
      />
    </AccountCardWrapper>
  )
}
