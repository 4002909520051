import { styled } from '@sans-souci/styles'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './Button/Button'
import { AnimationStatus } from './useAnimation'

const IconWrap = styled('div', {
  justifySelf: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  $focusStyle: 'default',
})
const Root = styled(Button, {
  justifySelf: 'end',
  gridColumn: '3',
  padding: '$12 $32',
  zIndex: 1,
  transition: 'opacity 200ms',
  pointerEvents: 'all',
  '@md': {
    gridColumn: 1,
    gridRow: 1,
    justifySelf: 'start',
  },
  '&:hover': {
    opacity: 0.7,
  },
})
const Svg = styled('svg', {
  overflow: 'visible',
  '.line1': {
    transformOrigin: '1px 6.5px',
  },
  '.line3': {
    transformOrigin: '1px 6.5px',
  },
  variants: {
    status: {
      entering: {
        fill: 'black',
        '.line1': {
          transition: 'all calc($$enteringTime/2)',
          transform: 'rotate(13.5deg)',
        },
        '.line2': {
          transition: 'all calc($$enteringTime/2)',
          opacity: 0,
        },
        '.line3': {
          transition: 'all calc($$enteringTime/2)',
          transform: 'rotate(-13.5deg)',
        },
      },
      entered: {
        fill: 'black',
        '.line1': {
          transform: 'rotate(13.5deg)',
        },
        '.line2': {
          opacity: 0,
        },
        '.line3': {
          transform: 'rotate(-13.5deg)',
        },
      },
      exiting: {
        '.line1': {
          transition: 'all calc($$exitingTime/2)',
        },
        '.line2': {
          transition: 'all calc($$exitingTime/2)',
        },
        '.line3': {
          transition: 'all calc($$exitingTime/2)',
        },
      },
      exited: {},
      hidden: {},
    },
  },
})
type Props = {
  status: AnimationStatus
  onClick?: () => void
}
export const NavTrigger: FC<Props> = ({ status, onClick }) => {
  const { t } = useTranslation('header')

  return (
    <Root aria-label={t('menuOpenerAriaLabel')} onClick={onClick}>
      <IconWrap>
        <Svg
          width="53"
          height="13"
          viewBox="0 0 53 13"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          status={status}
        >
          <path d="M53 1H0V0H53V1Z" className={'line1'} />
          <path d="M53 7H0V6H53V7Z" className={'line2'} />
          <path d="M53 13H0V12H53V13Z" className={'line3'} />
        </Svg>
      </IconWrap>
    </Root>
  )
}
