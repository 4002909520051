import {
  createShopifySdk,
  formatShopifyCollection,
  ShopifyGetCollectionQueryVariables,
} from '@sans-souci/shopify-sdk'
import { createShopifyClient } from './createShopifyClient'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'

export const COLLECTION_INITIAL_PRODUCTS_COUNT = 16
export const COLLECTION_LOAD_MORE_PRODUCTS_COUNT = 16

export const getShopifyCollection = async (
  variables: Omit<ShopifyGetCollectionQueryVariables, 'count'> & {
    count?: number
  },
  locale: string,
) => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)

  const shopifyClient = createShopifyClient(localeId)
  return await createShopifySdk(shopifyClient)
    .getCollection({
      ...variables,
      count: variables.count ?? COLLECTION_INITIAL_PRODUCTS_COUNT,
    })
    .then((res) =>
      res.data.collection ? formatShopifyCollection(res.data.collection) : null,
    )
}
