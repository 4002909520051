import { Path } from 'local-react-hook-form'
import { ShopifyCustomerUserErrorsFieldsFragment } from '@sans-souci/shopify-sdk'

export function formatResponseErrors<T>(
  errors: ShopifyCustomerUserErrorsFieldsFragment[],
) {
  let generalError = ''
  const fieldErrors: { fieldName: Path<T>; message: string }[] = []

  errors.forEach((e) => {
    const fieldName = e?.field?.[1] as Path<T>
    if (fieldName) {
      fieldErrors.push({ fieldName, message: e?.message })
    }
    if (!fieldName && e.message) {
      generalError = e.message
    }
  })

  return {
    generalError,
    fieldErrors,
  }
}
