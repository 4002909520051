import { LocaleId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

type VideoPreferQuality = 'HIGH' | 'MEDIUM'

const __createSanityImagePayloadProjection = (
  imageTypeFieldName?: string,
) => groq`{
  "type": "sanity-image",
  "image":{
    ...${imageTypeFieldName ? `${imageTypeFieldName}` : ''},
    ...${imageTypeFieldName ? `${imageTypeFieldName}.` : ''}asset -> {
      "width": metadata.dimensions.width,
      "height": metadata.dimensions.height,
      "alt": altText,
      "unoptimized": extension == "svg",
      ...,
    },
  }
}`

const __createMuxStaticRenditionsProjection = (
  preferQuality: VideoPreferQuality = 'HIGH',
) => groq`
  select(
  ${
    preferQuality === 'HIGH'
      ? 'count(data.static_renditions.files[name=="high.mp4"]) > 0  => data.static_renditions.files[name=="high.mp4"][0],'
      : ''
  }
  count(data.static_renditions.files[name=="medium.mp4"]) > 0  => data.static_renditions.files[name=="medium.mp4"][0],
  count(data.static_renditions.files[name=="low.mp4"]) > 0  => data.static_renditions.files[name=="low.mp4"][0]
)
`
const __createMuxVideoPayloadProjection = (
  localeId: LocaleId,
  videoPreferQuality?: VideoPreferQuality,
  muxVideoTypeFieldName?: string,
) => groq`{
    "type": "mux-video",
    "video": {
      ${coalesceLocaleField('alt', localeId)},
      ...${muxVideoTypeFieldName ? `${muxVideoTypeFieldName}.` : ''}asset ->{
        ...(${__createMuxStaticRenditionsProjection(videoPreferQuality)}{
          width,
          height,
          "mp4":"https://stream.mux.com/" + ^.data.playback_ids[0].id + "/" + name
        }),
        "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
        "id": data.id,
      }
    }
},`

export const createSanityImageProjection = () => {
  return groq`{
    "mediaPayload":${__createSanityImagePayloadProjection()}
  }`
}
export const createMuxVideoProjection = (
  localeId: string,
  videoPreferQuality?: VideoPreferQuality,
) => groq`{
      "mediaPayload":${__createMuxVideoPayloadProjection(
        localeId,
        videoPreferQuality,
      )}
    }`

const __createMediaPayloadProjection = (
  localeId: LocaleId,
  videoPreferQuality?: VideoPreferQuality,
) => {
  return groq`{
        _type =='imagePayload' =>${__createSanityImagePayloadProjection(
          'image',
        )},
        _type =='videoPayload' =>${__createMuxVideoPayloadProjection(
          localeId,
          videoPreferQuality,
          'video',
        )}
      }
  `
}

export const createMediaProjection = (
  localeId: LocaleId,
  videoPreferQuality?: VideoPreferQuality,
) => groq`{
      "mediaPayload":mediaPayload[0]${__createMediaPayloadProjection(
        localeId,
        videoPreferQuality,
      )},
      overrideMobileMedia => {
        "mediaPayloadMobile":mediaPayloadMobile[0]${__createMediaPayloadProjection(
          localeId,
          videoPreferQuality,
        )},
      }
    }
   `
