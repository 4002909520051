import { LocaleId, RegionId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSEOProjection } from './createSEOProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from './createMediaProjection'

export const createProjectListingPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
      ...,
      "pageBuilder": [
         {
          "_type": "sectionProjectListingPage",
          "_key": "sectionProjectListingPage",
          "projects": *[ !(_id in path("drafts.**")) && defined(path.current) && _type == "project"]|order(orderRank)  {
            _id,
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
            ${coalesceLocaleField('subtitle', localeId)},
            "path": path.current,
            releaseDate,
            "params": {
               "location": location -> {
                  ${coalesceLocaleField('title', localeId)},
                }.title,
                "type": type -> {
                  ${coalesceLocaleField('title', localeId)},
                }.title,
            },
            media${MEDIA_PROJECTION},
            hoverMedia${MEDIA_PROJECTION},
          }
        },
        ...${PAGE_BUILDER_PROJECTION}.pageBuilder,
      ],

      "background": "dark",
      "headerColor": "light",
      "breadcrumbs": [
        {
          "title": "Projects",
        }
      ],
      seo${createSEOProjection(localeId)},
    }`
}
