import { LocaleId } from '@sans-souci/configs'
import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createSanityImageProjection } from './createMediaProjection'

export const createSEOProjection = (localeId: LocaleId) => {
  const SANITY_IMAGE_PROJECTION = createSanityImageProjection()
  return groq`{
        ${coalesceLocaleField('titleTemplate', localeId)},
        ${coalesceLocaleField('metaTitle', localeId)},
        ${coalesceLocaleField('metaDescription', localeId)},
        ${coalesceLocaleField('ogTitle', localeId)},
        ${coalesceLocaleField('ogDescription', localeId)},
        ${coalesceLocaleField('ogAltImage', localeId)},
        ogImage${SANITY_IMAGE_PROJECTION}
      }`
}
