import { styled } from '@sans-souci/styles'
import { forwardRef } from 'react'
import { SelectFieldPropsType } from './SelectFieldPropsType'
import { MdErrorOutline } from 'react-icons/md'

const FieldContainer = styled('div', {
  color: 'currentColor',
  maxWidth: '100%',
  display: 'grid',
  gap: '$2',
})

const SelectContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  position: 'relative',
  $projectFont: 'dev',

  '&::before': {
    pointerEvents: 'none',
    width: '10px',
    height: '10px',
    borderBottom: '1px solid $black',
    borderRight: '1px solid $black',
    position: 'absolute',
    content: '',
    right: '$24',
    transform: 'rotate(45deg) translateY(-5px)',
  },
})

const Select = styled('select', {
  appearance: 'none',
  minWidth: '$120', // value only used to override initial 'fit-content'
  height: '$buttonHeightXL',
  margin: 0,
  padding: '0 $48 0 $16',
  backgroundColor: 'transparent',
  border: '1px solid $lightgray',
  borderRadius: '$r2',
  textOverflow: 'ellipsis',
  $projectFont: 'dev',
  color: 'currentColor',
  cursor: 'pointer',
  focusStyle: 'default',
  variants: {
    isInvalid: {
      true: {
        borderColor: '$red500',
        color: '$red500',
        '&:focus-visible': {
          borderColor: '$red500',
          boxShadow: 'none',
        },
      },
    },
    isDisabled: {
      true: {
        opacity: '0.7',
      },
    },
  },
})

const Label = styled('label', {
  zIndex: 2,
  margin: 0,
  border: 0,
  top: 0,
  left: 0,
  transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  color: '$gray',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '2px 8px',
  transform: 'translate(27px, -9px) scale(0.80)',
  variants: {
    isInvalid: {
      true: {
        color: '$red500',
      },
    },
  },
})

const HelpText = styled('div', {
  display: 'grid',
  gap: '$2',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  $projectFont: 'dev',
  color: '$red500',
})

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldPropsType>(
  (props, ref) => {
    const {
      autoComplete,
      disabled,
      id,
      children,
      invalid,
      helpText,
      label,
      value,
      style,
      className,
      ...rest
    } = props

    const helpId = helpText ? `${id}__hint` : undefined

    return (
      <FieldContainer className={className}>
        <SelectContainer>
          {label && (
            <Label htmlFor={id} isInvalid={invalid}>
              {label}
            </Label>
          )}

          <Select
            {...rest}
            aria-describedby={helpId}
            aria-errormessage={invalid && helpId ? helpId : undefined}
            aria-invalid={invalid}
            disabled={disabled}
            id={id}
            ref={ref}
            autoComplete={autoComplete}
            isInvalid={invalid}
            isDisabled={disabled}
            value={value}
          >
            {children}
          </Select>
        </SelectContainer>
        {helpText ? (
          <HelpText id={helpId}>
            <MdErrorOutline />
            {helpText}
          </HelpText>
        ) : null}
      </FieldContainer>
    )
  },
)
