import { Dispatch, FC, SetStateAction } from 'react'
import { AddAddressForm } from '../../../forms/AddAddressForm'
import { useCustomerContext } from '../../../customerContext'
import { usePageContext } from '../pageContext'
import { useTranslation } from 'react-i18next'
import { AccountCardWrapper } from '../components/AccountCardWrapper'

export const AddAddress: FC<{ onClick: Dispatch<SetStateAction<boolean>> }> = ({
  onClick,
}) => {
  const { setActiveView } = usePageContext()
  const { updateCustomer } = useCustomerContext()
  const { t } = useTranslation('account')

  const handleSuccess = async () => {
    await updateCustomer()
    setActiveView('address-list')
  }

  return (
    <AccountCardWrapper title={t('accountPage.addAddressView.heading')}>
      <AddAddressForm
        onCancelClick={() => onClick(false)}
        onSubmitSuccess={() => {
          onClick(false)
          handleSuccess()
        }}
      />
    </AccountCardWrapper>
  )
}
