import React from 'react'

import { styled } from '@sans-souci/styles'
import { ContentProps } from './Modal'

const ContentStyled = styled('div', {
  position: 'relative',
  border: 'none',
  maxWidth: '100%',
  variants: {
    position: {
      center: {
        margin: 'auto',
      },
      bottom: {
        margin: 'auto auto 0',
      },
      right: {
        margin: 'auto 0 auto auto',
      },
      left: {
        margin: 'auto auto auto 0',
      },
      top: {
        margin: '0 auto auto auto',
      },
    },
    size: {
      intrinsic: {
        overflowY: 'auto',
      },
      stretch: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      },
      'stretch-x': {
        width: '100%',
      },
      'stretch-y': {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    },
    variant: {
      default: {
        background: '$white',
      },
      'white-rounded': {
        borderRadius: '$r2',
        background: '$white',
      },
      rounded: {
        borderRadius: '$r2',
      },
      nav: {
        background: 'linear-gradient(90deg, #2581C4 0%, #FFFFFF 100%)',
      },
      gallery: {},
    },
    transition: {
      'slide-from-right': {},
      'slide-from-top': {},
      'slide-from-bottom': {},
      'slide-from-left': {},
      fade: {},
      nav: {},
    },
    status: {
      entering: {},
      entered: {},
      exiting: {},
      exited: {},
    },
  },
  compoundVariants: [
    // slide-from-right
    {
      transition: 'slide-from-right',
      status: 'entering',
      css: {
        transition: 'transform $$enteringTime',
      },
    },
    {
      transition: 'slide-from-right',
      status: 'exiting',
      css: {
        transition: 'transform $$exitingTime',
        transform: 'translateX(100%)',
      },
    },
    {
      transition: 'slide-from-right',
      status: 'exited',
      css: {
        transform: 'translateX(100%)',
      },
    },
    // slide-from-left
    {
      transition: 'slide-from-left',
      status: 'entering',
      css: {
        transition: 'transform $$enteringTime',
      },
    },
    {
      transition: 'slide-from-left',
      status: 'exiting',
      css: {
        transition: 'transform $$exitingTime',
        transform: 'translateX(-100%)',
      },
    },
    {
      transition: 'slide-from-left',
      status: 'exited',
      css: {
        transform: 'translateX(-100%)',
      },
    },
    // slide-from-bottom
    {
      transition: 'slide-from-bottom',
      status: 'entering',
      css: {
        transition: 'transform $$enteringTime',
      },
    },
    {
      transition: 'slide-from-bottom',
      status: 'exiting',
      css: {
        transition: 'transform $$exitingTime',
        transform: 'translateY(100px)',
      },
    },
    {
      transition: 'slide-from-bottom',
      status: 'exited',
      css: {
        transform: 'translateY(100px)',
      },
    },
    // slide-from-top
    {
      transition: 'slide-from-top',
      status: 'entering',
      css: {
        transition: 'all $$enteringTime',
      },
    },
    {
      transition: 'slide-from-top',
      status: 'exiting',
      css: {
        transition: 'all $$exitingTime',
        transform: 'translateY(-100px)',
        opacity: 0,
      },
    },
    {
      transition: 'slide-from-top',
      status: 'exited',
      css: {
        opacity: 0,
        transform: 'translateY(-100px)',
      },
    },
    // fade
    {
      transition: 'fade',
      status: 'entering',
      css: {
        transition: 'opacity $$enteringTime',
      },
    },
    {
      transition: 'fade',
      status: 'exited',
      css: {
        opacity: '0',
      },
    },
    {
      transition: 'fade',
      status: 'exiting',
      css: {
        opacity: '0',
        transition: 'opacity $$exitingTime',
      },
    },
  ],
})

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  (
    {
      transition,
      variant,
      position,
      size,
      onRequestClose,
      status,
      children,
      ...restProps
    },
    ref,
  ) => {
    const stylingProps = {
      variant,
      position,
      size,
      transition,
      status,
    }
    return (
      <ContentStyled {...restProps} ref={ref} style={{}} {...stylingProps}>
        {children}
      </ContentStyled>
    )
  },
)

Content.displayName = 'Content'
