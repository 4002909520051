import { formatShopifyProductBasic } from './formatShopifyProductBasic'
import { ShopifyCollectionFieldsFragment } from '../dist/generatedSchemaSdk'

export const formatShopifyCollection = (
  collection: ShopifyCollectionFieldsFragment,
) => ({
  ...collection,
  products: {
    ...collection.products,
    nodes: collection.products.nodes.map(formatShopifyProductBasic),
  },
})

export type ShopifyResolvedCollection = ReturnType<
  typeof formatShopifyCollection
>
