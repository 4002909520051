import { styled } from '@sans-souci/styles'
import { FC } from 'react'
import { ROUTE_BASE_PRODUCTS } from '@sans-souci/configs'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'
import { ProductPrice } from '../Price/ProductPrice'
import { ShopifyResolvedProductBasic } from '@sans-souci/shopify-sdk'

const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  alignContent: 'start',
})

const BottomWrap = styled('div', {
  display: 'grid',
  gridGap: '$6',
  padding: '0 $16',
  $projectFont: 'heading03',
})
const Title = styled('div', {})
const PriceWrap = styled('div', {
  color: '$gray300',
})

const ImageWrap = styled(Link, {
  position: 'relative',
  display: 'grid',
  background: '$gray100',
})

type ProductCardProps = {
  product: ShopifyResolvedProductBasic
  priority?: boolean
}
export const ProductCard: FC<ProductCardProps> = ({
  product,
  priority = false,
}) => {
  const { handle, featuredImage, title, priceRange, compareAtPriceRange } =
    product
  const href = `${ROUTE_BASE_PRODUCTS}/${handle}`

  return (
    <Root>
      <ImageWrap href={href}>
        {featuredImage && (
          <Media
            mediaPayload={featuredImage}
            ratio={'portrait2'}
            priority={priority}
            sizes={'(min-width: 1000px) 25vw,(min-width: 430px) 50vw, 100vw'}
          />
        )}
      </ImageWrap>
      <BottomWrap as={Link} href={href} tabIndex={-1}>
        <Title>{title}</Title>
        <PriceWrap>
          <ProductPrice
            priceRange={priceRange}
            compareAtPriceRange={compareAtPriceRange}
          />
        </PriceWrap>
      </BottomWrap>
    </Root>
  )
}
