import { forwardRef, PropsWithChildren } from 'react'
import { styled } from '@sans-souci/styles'
import * as React from 'react'

const Wrap = styled('section', {
  borderTop: '1px solid $black',
  borderBottom: '1px solid $black',
  '& + &': {
    borderTop: 'none',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
  '&:first-of-type': {
    borderTop: 'none',
  },
  variants: {
    theme: {
      light: {},
      dark: {
        borderColor: '$beige',
      },
    },
    type: {
      default: {},
      withBorders: {},
      fullBleed: {},
    },
  },
})

export const SectionWrap = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    type?: 'default' | 'withBorders' | 'fullBleed'
    id?: string
    theme?: 'light' | 'dark'
  }>
>(({ children, type = 'default', id, theme = 'light' }, ref) => {
  return (
    <Wrap id={id} ref={ref} type={type} theme={theme}>
      {children}
    </Wrap>
  )
})
