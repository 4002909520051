import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@sans-souci/models'
import { createCollectionPageProjection } from './projections/createCollectionPageProjection'

export const getCollectionPage = async (
  handle: string,
  localeRegion: string,
  preview = false,
): Promise<Omit<
  NonNullable<PageResponse<PageBuilderSection[]>>,
  'seo'
> | null> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegion)

  const COLLECTION_PAGE_PROJECTION = createCollectionPageProjection(
    regionId,
    localeId,
  )

  const query = (() => {
    if (preview) {
      return groq`*[_type == "collection" && defined(store.slug.current) && store.slug.current == $handle && _type == "collection"][0]${COLLECTION_PAGE_PROJECTION}`
    }

    return groq`*[!(_id in path('drafts.**')) && defined(store.slug.current) && store.slug.current == $handle && _type == "collection"][0]${COLLECTION_PAGE_PROJECTION}`
  })()

  return await getClient(preview).fetch(query, {
    handle,
  })
}
